import React from "react"

import "./StoreLink.scss"

let StoreLink = (props) => {
    const { REACT_APP_SERVER_HOST } = process.env
    return (
        <div className="store_link">
            <a rel="noreferrer" target="_blank" href={props.link.url} className="store_link">
                <img className="store_link_img" src={`${REACT_APP_SERVER_HOST}${props.link.img}`} alt=""/>
                <p>Buy</p>
            </a>
        </div>
    )
}

export default StoreLink