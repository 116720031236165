import React, { Component } from "react"
import axios from "axios"

import Navbar from "../../ReuseableComponents/Navbar/Navbar"
import GalleriesBlock from "./Components/GalleriesBlock/GalleriesBlock"
import VideosBlock from "./Components/VideosBlock/VideosBlock"
import MusicBlock from "./Components/MusicBlock/MusicBlock"
import WallpapersBlock from "./Components/WallpapersBlock/WallpapersBlock"
import "./Media.scss"

class Media extends Component {
    constructor(props) {
        super(props)

        this.state = {
            galleryCount: 0,
            galleries: [],
            youtubePlaylist: "",
            playlistId: "",
            playlistError: false,
            galleryState: "collapse",
            footerGalleryName: "",
            footerGalleryStyle: {"opacity": "0"},
            galleryStyle: {"maxHeight": "180px"}
        }
    }

    async componentDidMount() {
        document.title = "Media - Terraria"

        let mediaRes = await axios.get("/api/get/media-info")

        let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|playlist\?list=|&list=)([^#&?]*).*/
        let match = mediaRes.data[2].url.match(regExp)

        if(match && match[2]) {
            this.setState({
                galleryCount: mediaRes.data[0],
                galleries: mediaRes.data[1],
                youtubePlaylist: mediaRes.data[2].url,
                playlistId: match[2]
            })
        }
        else {
            this.setState({
                galleryCount: mediaRes.data[0],
                galleries: mediaRes.data[1],
                youtubePlaylist: mediaRes.data[2].url,
                playlistError: true
            })
        }
    }
    
    render() {
        return (
            <div className="main_wrapper">
                <div className="media_panel">
                    <a href="/">
                        <div className="logo"></div>
                    </a>
                    <Navbar />
                    <GalleriesBlock galleryCount={this.state.galleryCount} galleries={this.state.galleries} />
                    <VideosBlock playlistError={this.state.playlistError} playlistId={this.state.playlistId} />
                    <MusicBlock />
                    <WallpapersBlock />
                </div>
            </div>
        )
    }
}

export default Media