import React, { Component } from "react"

import AdminViewGalleries from "./Components/AdminViewGalleries/AdminVewGalleries"
import AdminEditGallery from "./Components/AdminEditGallery/AdminEditGallery"
import "./AdminMedia.scss"

class AdminMedia extends Component {
    constructor(props) {
        super(props)

        this.state = {
            panelType: "view",
            editGallery: {}
        }
    }

    changePanelType = (gallery) => {
        if (this.state.panelType === "view") {
            this.setState({
                panelType: "edit",
                editGallery: gallery
            })
        }
        else {
            this.setState({
                panelType: "view"
            })
        }
    }

    render() {
        return (
            <div className="admin_media_panel">
                {
                    this.state.panelType === "view"
                    ?
                        <AdminViewGalleries changePanelType={this.changePanelType} />
                    :
                        <AdminEditGallery gallery={this.state.editGallery} changePanelType={this.changePanelType} />
                }
            </div>
        )
    }
}

export default AdminMedia