import React, { Component } from "react"
import axios from "axios"

import AdminAllTilesTile from "./Components/AdminAllTilesTile/AdminAllTilesTile"
import "./AdminAllTilesContainer.scss"

class AdminAllTilesContainerPreview extends Component {
    constructor(props) {
        super(props)

        this.state = {
            tiles: []
        }
    }
    async componentDidMount() {
        let tilesRes = await axios.get(`/api/admin/get/tiles/${this.props.container.id}`)

        this.setState({
            tiles: tilesRes.data
        })
    }

    render() {
        let style = this.state.tiles.length ? { "display": "block" } : { "display": "none" }
        let tiles = this.state.tiles.map((tile, i) => {
            return (
                <AdminAllTilesTile key={i} tile={tile} />
            )
        })
        return (
            <div className="admin_all_tiles_container" style={style}>
                {
                    this.props.container.type === "one img one by two"
                    ?
                        <div className="admin_one_img_one_by_two">
                            {tiles}
                        </div>
                    :
                    this.props.container.type === "two img one by two"
                    ?
                        <div className="admin_two_img_one_by_two">
                            {tiles}
                        </div>
                    :
                    this.props.container.type === "two img two by one"
                    ?
                        <div className="admin_two_img_two_by_one">
                            {tiles}
                        </div>
                    :
                    this.props.container.type === "one img two by one"
                    ?
                        <div className="admin_one_img_two_by_one">
                            {tiles}
                        </div>
                    :
                        <div className="admin_one_img_two_by_two">
                            {tiles}
                        </div>
                }
            </div>
        )
    }
}

export default AdminAllTilesContainerPreview