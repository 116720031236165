import axios from "axios"
import React, { Component } from "react"

import "./AdminEditHomeDropDownMenu.scss"

class AdminEditHomeDropDownMenu extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: "",
            section: 0,
            order: 0,
            changeOrder: false,
            swapDropDownMenuId: 0,
            manualOrderReset: false
        }
    }

    componentDidMount() {
        this.setState({
            name: this.props.dropDownMenu.name,
            section: this.props.dropDownMenu.section,
            order: this.props.dropDownMenu.drop_down_order
        })
    }
    
    componentDidUpdate(prevProps) {
        if(this.props.dropDownMenu.id !== prevProps.dropDownMenu.id) {
            this.setState({
                name: this.props.dropDownMenu.name,
                section: this.props.dropDownMenu.section,
                order: this.props.dropDownMenu.drop_down_order
            })
        }
    }

    updateInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    changeDropDownOrder = (e) => {
        let dropDownMenu = this.props.dropDownMenus.find((dropDownMenu) => dropDownMenu.drop_down_order === Number(e.target.value))

        this.setState({
            order: Number(e.target.value),
            changeOrder: true,
            swapDropDownMenuId: dropDownMenu.id
        })
    }

    updateDropDownMenu = async () => {
        try {
            let updateBtn = document.getElementById("admin_store_edit_home_drop_down_menu_update_btn")
            updateBtn.innerHTML = ""
            updateBtn.classList.add("spinner")

            if(!this.state.name.length) {
                alert("You must have a name")
            }
            else {
                let updateRes = await axios.put("/api/admin/update/drop-down-menu", { 
                    id: this.props.dropDownMenu.id, 
                    name: this.state.name, 
                    section: this.state.section, 
                    changeOrder: this.state.changeOrder, 
                    swapDropDownMenuId: this.state.swapDropDownMenuId 
                })

                alert(updateRes.data)

                updateBtn.innerHTML = "Update"
                updateBtn.classList.remove("spinner")

                this.setState({
                    name: "",
                    section: 0,
                    order: 0,
                    changeOrder: false,
                    swapDropDownMenuId: 0
                }, async () => {
                    await this.props.getSectionsDropDownMenus()
                    this.props.changeToView()
                })
            }
        }
        catch(err) {
            let updateBtn = document.getElementById("admin_store_edit_home_drop_down_menu_update_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            updateBtn.innerHTML = "Update"
            updateBtn.classList.remove("spinner")
        }
    }

    toggleOrderReset = () => {
        this.setState({
            manualOrderReset: !this.state.manualOrderReset
        })
    }

    manuallyUpdateDropDownOrder = async () => {
        try {
            let updateBtn = document.getElementById("manually_update_drop_down_order_btn")
            updateBtn.innerHTML = ""
            updateBtn.classList.add("spinner")

            if( this.state.order > 2147483647 || this.state.order <= 0) {
                alert("Order must be above 0 and less than 2147483647")

                updateBtn.innerHTML = "Update Order"
                updateBtn.classList.remove("spinner")
            }
            else {
                let manualUpdateRes = await axios.put("/api/admin/update/drop_down/order", { id: this.props.dropDownMenu.id, order: this.state.order })

                alert(manualUpdateRes.data)

                updateBtn.innerHTML = "Update Order"
                updateBtn.classList.remove("spinner")

                await this.props.getSectionsDropDownMenus()
            }
        }
        catch(err) {
            let updateBtn = document.getElementById("manually_update_drop_down_order_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            updateBtn.innerHTML = "Update Order"
            updateBtn.classList.remove("spinner")
        }
    }

    render() {
        let sections = this.props.sections.map((section, i) => {
            return (
                <option key={i} value={section.id}>{section.name}</option>
            )
        })
        let orders = this.props.dropDownMenus.map((dropDownMenu, i) => {
            return (
                <option key={i} value={dropDownMenu.drop_down_order}>{dropDownMenu.drop_down_order}</option>
            )
        })
        return (
            <div className="admin_store_edit_home_drop_down_menu">
                <div className="admin_store_form_container">
                    <div className="admin_store_input_container">
                        <p>Name:</p>
                        <input type="text" 
                        name="name" 
                        placeholder="Name" 
                        value={this.state.name} 
                        onChange={this.updateInput}/>
                    </div>
                    <div className="admin_store_input_container">
                        <p>Store Seciton: (Only published store sections appear here)</p>
                        <select name="section" value={this.state.section} onChange={this.updateInput}>
                            {sections}
                        </select>
                    </div>
                    <div className="admin_store_input_container">
                        <p>Order:</p>
                        <select name="order" value={this.state.order} onChange={this.changeDropDownOrder}>
                            {orders}
                        </select>
                    </div>
                    <div className="admin_store_input_container">
                            <p>Drop Down Menu order not working correctly? Click the button below to manually set this drop down menu's order</p>
                            {
                                this.state.manualOrderReset
                                ?
                                    <div className="admin_store_order_change_container">
                                        <button className="admin_store_order_reset_cancel_btn" onClick={this.toggleOrderReset}>X</button>
                                        <input type="number" 
                                        name="order" 
                                        placeholder="0" 
                                        value={this.state.order} 
                                        onChange={this.updateInput} />
                                        <button id="manually_update_drop_down_order_btn" 
                                        className="admin_store_spinner_container" 
                                        onClick={this.manuallyUpdateDropDownOrder}>Update Order</button>
                                    </div>
                                :
                                    <div className="admin_store_order_change_container">
                                        <button onClick={this.toggleOrderReset}>Set Order</button>
                                    </div>
                            }
                    </div>
                </div>
                <div className="admin_store_btn_container">
                    <button onClick={this.props.changeToView}>Cancel</button>
                    <button id="admin_store_edit_home_drop_down_menu_update_btn" 
                    disabled={!this.state.name} 
                    className="admin_spinner_container"
                    onClick={this.updateDropDownMenu}>Update</button>
                </div>
            </div>
        )
    }
}

export default AdminEditHomeDropDownMenu