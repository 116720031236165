import React, { Component } from "react"
import axios from "axios"

import "./Footer.scss"

class Footer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            pcServer: "",
            mobileServer: "",
        }
    }

    async componentDidMount() {
        try {
            let dedicatedServersRes = await axios.get("/api/get/dedicated-servers-names")

            this.setState({
                pcServer: dedicatedServersRes.data[0],
                mobileServer: dedicatedServersRes.data[1],
            })
        }
        catch(err) {
        }
    }

    pcDownload = () => {
        window.location.href = `/api/download/pc-dedicated-server/${this.state.pcServer}`
    }

    mobileDownload = () => {
        window.location.href = `/api/download/mobile-dedicated-server/${this.state.mobileServer}`
    }

    render() {
        return (
            <div className="page_footer" id="site_footer">
                <div id="inner_footer_container">
                    ©2021 Re-Logic. All rights reserved &nbsp;|&nbsp;
                    <a href="/terms">Legal</a>
                    |&nbsp;
                    <p onClick={this.pcDownload}>PC Dedicated Server</p>
                    |&nbsp;
                    <p onClick={this.mobileDownload}>Mobile Dedicated Server</p>
                    |&nbsp;
                    <a href="https://re-logic.com">About Us</a>
                </div>
            </div>
        )
    }
}

export default Footer