import React, { useState } from "react"

import GalleryCard from "../GalleryCard/GalleryCard"
import "./GalleriesBlock.scss"

let GalleriesBlock = (props) => {
    const [galleryState, setGalleryState] = useState("collapsed")
    const [galleryStyle, setGalleryStyle] = useState({"maxHeight": "180px"})
    const [footerGalleryName, setFooterGalleryName] = useState("")
    const [footerGalleryStyle, setFooterGalleryStyle] = useState({"opacity": "0"})
    const { REACT_APP_SERVER_HOST } = process.env

    let toggleGalleryContainer = () => {
        if(galleryState === "collapsed") {
            let pixels = `${Math.ceil(props.galleryCount / 4) * 180}px`
            setGalleryState("Expanded")
            setGalleryStyle({"maxHeight": pixels})
        }
        else {
            setGalleryState("collapsed")
            setGalleryStyle({"maxHeight": "180px"})
        }
    }

    let mouseEnter = (name) => {
        setFooterGalleryName(name)
        setFooterGalleryStyle({"opacity": "1"})
    }

    let mouseLeave = () => {
        setFooterGalleryStyle({"opacity": "0"})
    }

    let galleries = props.galleries.map((gallery, i) => {
        return (
            <GalleryCard key={i} gallery={gallery} mouseEnter={mouseEnter} mouseLeave={mouseLeave} />
        )
    })

    return (
        <div className="galleries_block">
            <div className="panel_dropshadow">
                <div id="media_galleries" className="wall">
                    <div className="wall_grass_top"></div>
                    <header className="wall_header"></header>
                    <div className="wall_background">
                        <div className="wall_fade_in"></div>
                        <div className="wall_content" style={galleryStyle}>
                            <div className="gallery">
                                {galleries}
                            </div>
                        </div>
                        <div className="wall_footer">
                            <div className="wall_footer_content">
                                <h3 className="footer_fading_name" id="gallery_name" style={footerGalleryStyle}>{footerGalleryName}</h3>
                                {
                                    galleryState === "collapsed"
                                    ?
                                        <div className="gallery_expand_collapse_button" onClick={toggleGalleryContainer}>
                                            <img src={`${REACT_APP_SERVER_HOST}/Media/expand_icon.png`} alt="expand" width="14" height="14"/>
                                            <p>Show More</p>
                                        </div>
                                    :
                                        <div className="gallery_expand_collapse_button" onClick={toggleGalleryContainer}>
                                            <img src={`${REACT_APP_SERVER_HOST}/Media/collapse_icon.png`} alt="collapse" width="14" height="14"/>
                                            <p>Show Less</p>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GalleriesBlock