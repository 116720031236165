import React from "react"

let CustomToolbar = () => {
    return (
        <div id="toolbar">
            <span>
                <button className="ql-code-block"></button>
            </span>
            <span className="ql-formats">
                <button className="ql-bold"></button>
                <button className="ql-italic"></button>
                <button className="ql-underline"></button>
                <button className="ql-strike"></button>
                <button className="ql-script" value="sub"></button>
                <button className="ql-script" value="super"></button>
                <button className="ql-clean"></button>
            </span>
            <span className="ql-formats">
                <button className="ql-list" value="ordered"></button>
                <button className="ql-list" value="bullet"></button>
                <button className="ql-indent" value="-1"></button>
                <button className="ql-indent" value="+1"></button>
                <button className="ql-blockquote"></button>
            </span>
            <span className="ql-formats">
                <button className="ql-align" value=""></button>
                <button className="ql-align" value="center"></button>
                <button className="ql-align" value="right"></button>
                <button className="ql-align" value="justify"></button>
            </span>
            <span className="ql-formats">
                <button className="ql-link"></button>
                <button className="ql-image"></button>
                <button className="ql-video"></button>
            </span>
            <span className="ql-formats">
                <select className="ql-color"></select>
                <select className="ql-background"></select>
            </span>
            <span className="ql-formats">
                <select className="ql-size" defaultValue="14px">
                    <option value="10px">10px</option>
                    <option value="12px">12px</option>
                    <option value="14px">14px</option>
                    <option value="16px">16px</option>
                    <option value="18px">18px</option>
                    <option value="24px">24px</option>
                    <option value="32px">32px</option>
                </select>
            </span>
            <span className="ql-formats">
                <select className="ql-header">
                    <option value="1">Header 1</option>
                    <option value="2">Header 2</option>
                    <option value="3">Header 3</option>
                    <option value="4">Header 4</option>
                </select>
            </span>
            <span className="ql-formats">
                <select className="ql-font" defaultValue="open-sans">
                    <option value="open-sans">Open Sans</option>
                    <option value="arial">Arial</option>
                    <option value="sans-serif">Sans Serif</option>
                    <option value="comic-sans">Comic Sans</option>
                    <option value="georgia">Georgia</option>
                    <option value="helvetica">Helvetica</option>
                    <option value="lucida">Lucida</option>
                </select>
            </span>
        </div>

    )
}

export default CustomToolbar