import axios from "axios";
import React, { Component } from "react"

import "./AdminAddHomeDropDownMenu.scss";

class AdminAddHomeDropDownMenus extends Component  {
    constructor(props) {
        super(props)

        this.state = {
            name: "",
            section: 0
        }
    }

    updateInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    addDropDownMenu = async () => {
        try {
            let addBtn = document.getElementById("admin_store_add_home_drop_down_menu_add_btn")
            addBtn.innerHTML = ""
            addBtn.classList.add("spinner")

            if(!this.state.name.length || this.state.section === 0) {
                alert("You must have a name and a store section selected")

                addBtn.innerHTML = "Add"
                addBtn.classList.remove("spinner")
            }
            else {
                let dropDownMenuRes = await axios.post("/api/admin/add/drop-down-menu", { section: this.state.section, name: this.state.name, order: this.props.dropDownLength + 1 })

                alert(dropDownMenuRes.data)

                addBtn.innerHTML = "Add"
                addBtn.classList.remove("spinner")

                this.setState({
                    name: "",
                    section: 0
                }, async () => {
                    await this.props.getSectionsDropDownMenus()
                    this.props.changeToView()
                })
            }
        }
        catch(err) {
            let addBtn = document.getElementById("admin_store_add_home_drop_down_menu_add_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            addBtn.innerHTML = "Add"
            addBtn.classList.remove("spinner")
        }
    }

    render() {
        let sections = this.props.sections.map((section, i) => {
            return (
                <option key={i} value={section.id}>{section.name}</option>
            )
        })
        return (
            <div className="admin_store_add_home_drop_down_menu">
                <div className="admin_store_form_container">
                    <div className="admin_store_input_container">
                        <p>Name:</p>
                        <input type="text" 
                        name="name" 
                        placeholder="Name" 
                        value={this.state.name} 
                        onChange={this.updateInput}/>
                    </div>
                    <div className="admin_store_input_container">
                        <p>Store Section: (Only published store sections appear here)</p>
                        <select name="section" value={this.state.section} onChange={this.updateInput}>
                            <option value={0}>Select Section</option>
                            {sections}
                        </select>
                    </div>
                </div>
                <div className="admin_store_btn_container">
                    <button onClick={this.props.changeToView}>Cancel</button>
                    <button id="admin_store_add_home_drop_down_menu_add_btn" 
                    className="admin_spinner_container"
                    disabled={!this.state.name.length || this.state.section === 0} 
                    onClick={this.addDropDownMenu}>Add</button>
                </div>
            </div>
        )
    }
}

export default AdminAddHomeDropDownMenus