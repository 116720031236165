import React, { useState, useEffect } from "react"
import axios from "axios"

import "./AdminEditGallery.scss"

let AdminEditGallery = (props) => {
    const [albumUrl, setAlbumUrl] = useState("")

    useEffect(() =>{
        let func = async () => {
            let urlRes = await axios.get(`/api/admin/get/gallery-url/${props.gallery.id}`)
            setAlbumUrl(urlRes.data)
        }
        func()
    }, [props.gallery.id])

    let syncAlbum = async () => {
        try {
            let syncAlbumBtn = document.getElementById("admin_media_sync_album_btn")
            syncAlbumBtn.innerHTML = ""
            syncAlbumBtn.classList.add("spinner")
            
            if(albumUrl.length) {
                let regExp = /^.*(imgur.com)\/a\/([^#&$]*).*/
                let match = albumUrl.match(regExp)
                
                if(match && match[2]) {
                    let albumRes = await axios.put("/api/admin/update/grab/image-gallery", { url: albumUrl })

                    let title = albumRes.data[0]
                    let images = albumRes.data[1]

                    let thumbnails = images.map((image, i) => {
                        let preLink = image.link.split(".com/")
                        let id = preLink[1].split(".")[0]
                        let type = preLink[1].split(".")[1]
                        return {
                            id: id,
                            type: image.type,
                            link: `${preLink[0]}.com/${id}b.${type}`
                        }
                    })

                    let promiseImages = images.map(async (image, i) => {
                        return await new Promise((resolve, reject) => {
                            try {
                                let xhr = new XMLHttpRequest()
                                xhr.open("GET", image.link)
                                xhr.responseType = "blob"
                                xhr.onerror = () => {reject("Network error.")}
                                xhr.onload = () => {
                                    if(xhr.status === 200) {resolve(xhr.response)}
                                    else {reject(`Loading error: ${xhr.statusText}`)}
                                }
                                xhr.send()
                            }
                            catch(err) {reject(err.message)}
                        })
                    })

                    let promiseThumbnails = thumbnails.map(async (image, i) => {
                        return await new Promise((resolve, reject) => {
                            try {
                                let xhr = new XMLHttpRequest()
                                xhr.open("GET", image.link)
                                xhr.responseType = "blob"
                                xhr.onerror = () => {reject("Network error.")}
                                xhr.onload = () => {
                                    if(xhr.status === 200) {resolve(xhr.response)}
                                    else {reject(`Loading error: ${xhr.statusText}`)}
                                }
                                xhr.send()
                            }
                            catch(err) {reject(err.message)}
                        })
                    })

                    let blobArr

                    await Promise.all(promiseImages).then((values) => {
                        blobArr = [...values]
                    })

                    let thumbnailBlobArr

                    await Promise.all(promiseThumbnails).then((values) => {
                        thumbnailBlobArr = [...values]
                    })

                    let formData = new FormData()

                    let titleFile = new File([`${title}`], "title", { type: "text/plain" })
                    let urlFile = new File([`${albumUrl}`], "url", { type: "text/plain" })
                    let idFile = new File([`${props.gallery.id}`], "id", { type: "text/plain" })
                    let safeNameFile = new File([props.gallery.safe_name], "safeName", { type: "text/plain" })

                    formData.append("title", titleFile)
                    formData.append("url", urlFile)
                    formData.append("id", idFile)
                    formData.append("safeName", safeNameFile)

                    for(let i = 0; i < blobArr.length; i++) {
                        let blobFile = new File([blobArr[i]], `${i}-${Date.now()}`, { type: blobArr[i].type })
                        let thumbnailFile = new File([thumbnailBlobArr[i]], `${i}-thumbnail-${Date.now()}`, { type: thumbnailBlobArr[i].type })
                        formData.append(`img-${i}`, blobFile)
                        formData.append(`img-${i}-thumbnail`, thumbnailFile)
                    }

                    await axios.delete(`/api/admin/delete/gallery/old-images/${props.gallery.safe_name}`)
                    let updateRes = await axios.put("/api/admin/update/image-gallery", formData)

                    alert(updateRes.data)

                    syncAlbumBtn.innerHTML = "Sync Imgur Album"
                    syncAlbumBtn.classList.remove("spinner")
                }
                else {
                    alert("Invalid imgur album url")
    
                    syncAlbumBtn.innerHTML = "Sync Imgur Album"
                    syncAlbumBtn.classList.remove("spinner")
                }
            }
            else {
                alert("Must enter in an imgur album link")
            }
        }
        catch(err) {
            let syncAlbumBtn = document.getElementById("admin_media_sync_album_btn")

            if(err.response) {
                if(err.response.status === 429) {
                    alert(err.response.data.data.error)
                }
                else {
                    if(err.response.data) {
                        alert(err.response.data)
                    }
                }
            }

            syncAlbumBtn.innerHTML = "Sync Imgur Album"
            syncAlbumBtn.classList.remove("spinner")
        }
    }
    
    return (
        <div className="admin_media_edit_gallery_panel">
            <h2>
                <b>Imgur Ablum: {props.gallery.name}</b>
            </h2>
            <div>
                <input type="text" 
                name="albumUrl" 
                placeholder="https://imgur.com/a/AfbG4" 
                value={albumUrl} 
                onChange={(e) => setAlbumUrl(e.target.value)}/>
                <button id="admin_media_sync_album_btn" 
                disabled={!albumUrl.length} style={{ "width": "140px" }} 
                onClick={syncAlbum}>Sync Imgur Album</button>
            </div>
            <button style={{ "marginTop": "10px" }} onClick={props.changePanelType}>Cancel</button>
        </div>
    )
}

export default AdminEditGallery