import React from "react"

import "./AdminAllTilesTile.scss"

let AdminAllTilesTile = (props) => {
    const { REACT_APP_SERVER_HOST } = process.env
    return (
        <img className="admin_all_tiles_tile" src={`${REACT_APP_SERVER_HOST}${props.tile.img}`} alt=""/>
    )
}

export default AdminAllTilesTile