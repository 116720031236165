import React, { Component } from "react"
import axios from "axios"

import ViewPartners from "./Components/ViewPartners/ViewPartners"
import AddPartner from "./Components/AddPartner/AddPartner"
import EditPartner from "./Components/EditPartner/EditPartner"
import "./StorePartners.scss"

class StorePartners extends Component {
    constructor(props) {
        super(props)

        this.state = {
            panelType: "view",
            partners: [],
            editPartner: {},
            offset: 0,
            pages: 1,
            pageNumber: 1,
            allPartners: []
        }
    }

    async componentDidMount() {
        let partnersRes = await axios.get("/api/admin/get/initial/store-partners")
        let allPartnersRes = await axios.get("/api/admin/get/all/store-partners")

        let pages = partnersRes.data[0] === 0 ? 1 : Math.ceil(partnersRes.data[0] / 10)

        this.setState({
            pages: pages,
            offset: 0,
            pageNumber: 1,
            partners: partnersRes.data[1],
            allPartners: allPartnersRes.data
        })
    }

    changeToView = () => {
        this.setState({
            panelType: "view"
        })
    }

    changeToAdd = () => {
        this.setState({
            panelType: "add"
        })
    }

    changeToEdit = (partner) => {
        this.setState({
            panelType: "edit",
            editPartner: partner
        })
    }

    getPartnersAgain = async () => {
        let partnersRes = await axios.get("/api/admin/get/initial/store-partners")
        let allPartnersRes = await axios.get("/api/admin/get/all/store-partners")

        let pages = partnersRes.data[0] === 0 ? 1 : Math.ceil(partnersRes.data[0] / 10)

        this.setState({
            pages: pages,
            offset: 0,
            pageNumber: 1,
            partners: partnersRes.data[1],
            allPartners: allPartnersRes.data
        })
    }

    previousPage = async () => {
        try {
            let offset = this.state.offset - 10

            let partnersRes = await axios.get(`/api/admin/get/store-partners/${offset}`)

            this.setState({
                partners: partnersRes.data,
                offset: offset,
                pageNumber: this.state.pageNumber - 1
            })
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    nextPage = async () => {
        try {
            let offset = this.state.offset + 10

            let partnersRes = await axios.get(`/api/admin/get/store-partners/${offset}`)

            this.setState({
                partners: partnersRes.data,
                offset: offset,
                pageNumber: this.state.pageNumber + 1
            })
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    render() {
        return (
            <div className="admin_store_store_partners">
                {
                    this.state.panelType === "view"
                    ?
                        <ViewPartners changeToAdd={this.changeToAdd} 
                        getPartnersAgain={this.getPartnersAgain} 
                        partners={this.state.partners} 
                        pages={this.state.pages} 
                        pageNumber={this.state.pageNumber} 
                        changeToEdit={this.changeToEdit} 
                        previousPage={this.previousPage} 
                        nextPage={this.nextPage} />
                    :
                    this.state.panelType === "add"
                    ?
                        <AddPartner changeToView={this.changeToView} partnersLength={this.state.allPartners.length} getPartnersAgain={this.getPartnersAgain} />
                    :
                        <EditPartner changeToView={this.changeToView} partner={this.state.editPartner} getPartnersAgain={this.getPartnersAgain} partners={this.state.allPartners} />
                }
            </div>
        )
    }
}

export default StorePartners