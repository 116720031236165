import React, { Component } from "react"
import axios from "axios"
import { Link } from "react-router-dom"

import Navbar from "../../ReuseableComponents/Navbar/Navbar"
import GalleryImage from "./Components/GalleryImage/GalleryImage"
import GalleryImageModel from "./Components/GalleryImageModel/GalleryImageModel"
import "./Gallery.scss"

class Gallery extends Component {
    constructor(props) {
        super(props)

        this.state = {
            galleryName: "",
            galleryImages: [],
            pageNumber: 1,
            offset: 0,
            pages: 0,
            modelImg: {},
            modelView: false,
            currentImgIndex: 0
        }
    }

    async componentDidMount() {
        try {
            let imagesRes = await axios.get(`/api/get/initial/gallery-images/${this.props.match.params.gallery}`)
    
            this.setState({
                galleryName: imagesRes.data[0].name,
                galleryImages: imagesRes.data[2],
                pages: Math.ceil(imagesRes.data[1] / 25)
            })
        }
        catch(err) {
            if(err.response) {
                if(err.response.status) {
                    if(err.response.status === 404) {
                        this.props.history.push("/")
                    }
                }
            }
        }
    }

    firstPage = async () => {
        if(this.state.pageNumber === 1) {
            return
        }
        else {
            let imagesRes = await axios.get(`/api/get/gallery-images/${this.props.match.params.gallery}/${0}`)
    
            this.setState({
                galleryImages: imagesRes.data,
                pageNumber: 1,
                offset: 0,
            })
        }
    }

    previousPage = async () => {
        if(this.state.pageNumber === 1) {
            return
        }
        else {
            let imagesRes = await axios.get(`/api/get/gallery-images/${this.props.match.params.gallery}/${this.state.offset - 25}`)

            this.setState({
                galleryImages: imagesRes.data,
                pageNumber: this.state.pageNumber - 1,
                offset: this.state.offset - 25
            })
        }
    }

    nextPage = async () => {
        if(this.state.pageNumber === this.state.pages) {
            return
        }
        else {
            let imagesRes = await axios.get(`/api/get/gallery-images/${this.props.match.params.gallery}/${this.state.offset + 25}`)

            this.setState({
                galleryImages: imagesRes.data,
                pageNumber: this.state.pageNumber + 1,
                offset: this.state.offset + 25
            })
        }
    }

    lastPage = async () => {
        if(this.state.pageNumber === this.state.pages) {
            return
        }
        else {
            let imageRes = await axios.get(`/api/get/gallery-images/${this.props.match.params.gallery}/${(this.state.pages - 1) * 25}`)

            this.setState({
                galleryImages: imageRes.data,
                pageNumber: this.state.pages,
                offset: (this.state.pages - 1) * 25
            })
        }
    }

    openCloseModel = async (index) => {
       if (this.state.modelView) {
           this.setState({
               modelImg: "",
               modelView: false
            }, async () => await this.fadeOut())
        }
        else {
            this.setState({
                modelImg: this.state.galleryImages[index],
                modelView: true,
                currentImgIndex: index
            }, async () => await this.fadeIn())
        }
    }

    fadeOut = async () => {
        let timeOut = (ms) => {
            return new Promise(resolve => setTimeout(resolve, ms))
        }

        let model = document.getElementById("gallery_img_model")
        model.style.opacity = "0"
        await timeOut(200)
    }

    fadeIn = async () => {
        let timeOut = (ms) => {
            return new Promise(resolve => setTimeout(resolve, ms))
        }

        let model = document.getElementById("gallery_img_model")
        model.style.opacity = "1"
        await timeOut(200)
    }

    nextImg = (e) => {
        if(this.state.currentImgIndex === this.state.galleryImages.length - 1) {
            this.setState({
                modelImg: this.state.galleryImages[0],
                currentImgIndex: 0
            })
        }
        else {
            this.setState({
                modelImg: this.state.galleryImages[this.state.currentImgIndex + 1],
                currentImgIndex: this.state.currentImgIndex + 1
            })
        }
        e.stopPropagation()
    }

    previousImg = (e) => {
        if(this.state.currentImgIndex === 0) {
            this.setState({
                modelImg: this.state.galleryImages[this.state.galleryImages.length - 1],
                currentImgIndex: this.state.galleryImages.length - 1
            })
        }
        else {
            this.setState({
                modelImg: this.state.galleryImages[this.state.currentImgIndex - 1],
                currentImgIndex: this.state.currentImgIndex - 1
            })
        }
        e.stopPropagation()
    }

    nextImgKey = () => {
        if(this.state.currentImgIndex === this.state.galleryImages.length - 1) {
            this.setState({
                modelImg: this.state.galleryImages[0],
                currentImgIndex: 0
            })
        }
        else {
            this.setState({
                modelImg: this.state.galleryImages[this.state.currentImgIndex + 1],
                currentImgIndex: this.state.currentImgIndex + 1
            })
        }
    }

    previousImgKey = () => {
        if(this.state.currentImgIndex === 0) {
            this.setState({
                modelImg: this.state.galleryImages[this.state.galleryImages.length - 1],
                currentImgIndex: this.state.galleryImages.length - 1
            })
        }
        else {
            this.setState({
                modelImg: this.state.galleryImages[this.state.currentImgIndex - 1],
                currentImgIndex: this.state.currentImgIndex - 1
            })
        }
    }

    keyDown = (e) => {
        if(e.keyCode === 37 || e.keyCode === 38 || e.keyCode === 65 || e.keyCode === 87) {
            this.nextImgKey()
        }
        if(e.keyCode === 39 || e.keyCode === 40 || e.keyCode === 68 || e.keyCode === 83) {
            this.previousImgKey()
        }
        if(e.keyCode === 27) {
            this.openCloseModel()
        }
        e.preventDefault()
    }

    render() {
        const { REACT_APP_SERVER_HOST } = process.env
        let firstImg = this.state.pageNumber > 1 ? `${REACT_APP_SERVER_HOST}/pagination/first.png` : `${REACT_APP_SERVER_HOST}/pagination/first_disabled.png`
        let previousImg = this.state.pageNumber > 1 ? `${REACT_APP_SERVER_HOST}/pagination/previous.png` : `${REACT_APP_SERVER_HOST}/pagination/previous_disabled.png`
        let nextImg = this.state.pageNumber < this.state.pages ? `${REACT_APP_SERVER_HOST}/pagination/next.png` : `${REACT_APP_SERVER_HOST}/pagination/next_disabled.png`
        let lastImg = this.state.pageNumber < this.state.pages ? `${REACT_APP_SERVER_HOST}/pagination/last.png` : `${REACT_APP_SERVER_HOST}/pagination/last_disabled.png`
        let firstPreviousClass = this.state.pageNumber > 1 ? "img_btn img_btn_active" : "img_btn"
        let nextLastClass = this.state.pageNumber < this.state.pages ? "img_btn img_btn_active" : "img_btn"
        let galleryImages = this.state.galleryImages.map((galleryImage, i) => {
            return (
                <GalleryImage key={i} galleryImage={galleryImage} openCloseModel={this.openCloseModel} index={i} />
            )
        })
        return(
            <div className="main_wrapper">
                <div className="single_gallery_panel">
                    <a href="/">
                        <div className="logo"></div>
                    </a>
                    <Navbar />
                    <div className="panel_dropshadow">
                        <div className="wall">
                            <div className="wall_grass_top"></div>
                            <header className="wall_header">
                                <h3>
                                    <b>
                                        <Link className="name_text" to="/media">Media</Link> - {this.state.galleryName}
                                    </b>
                                </h3>
                            </header>
                            <div className="wall_background">
                                <div className="wall_fade_in"></div>
                                <div className="wall_content">
                                    {galleryImages}
                                </div>
                                <div className="wall_fade_out"></div>
                                <div className="wall_footer">
                                    <div className="wall_footer_content">
                                        <div className="previous_page_btns_container">
                                            <img className={firstPreviousClass} src={firstImg} alt="first_page" onClick={this.firstPage}/>
                                            <img className="img_btn img_btn_divider" src={`${REACT_APP_SERVER_HOST}/pagination/divider.png`} alt="divider"/>
                                            <img className={firstPreviousClass} src={previousImg} alt="previous_page" onClick={this.previousPage}/>
                                            <img className="img_btn img_btn_divider" src={`${REACT_APP_SERVER_HOST}/pagination/divider.png`} alt="divider"/>
                                        </div>
                                        <span className="page_info">
                                            <b>Page {this.state.pageNumber} out of {this.state.pages}</b>
                                        </span>
                                        <div className="next_page_btns_container">
                                            <img className="img_bt img_btn_dividern" src={`${REACT_APP_SERVER_HOST}/pagination/divider.png`} alt="divider"/>
                                            <img className={nextLastClass} src={nextImg} alt="next_page" onClick={this.nextPage}/>
                                            <img className="img_btn img_btn_divider" src={`${REACT_APP_SERVER_HOST}/pagination/divider.png`} alt="divider"/>
                                            <img className={nextLastClass} src={lastImg} alt="last_page" onClick={this.lastPage}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <GalleryImageModel modelView={this.state.modelView} 
                    modelImg={this.state.modelImg} 
                    openCloseModel={this.openCloseModel} 
                    currentImageIndex={this.state.currentImgIndex} 
                    imgArrayLength={this.state.galleryImages.length} 
                    nextImg={this.nextImg} 
                    previousImg={this.previousImg} 
                    keyDown={this.keyDown} />
                    <br/>
                    <br/>
                </div>
            </div>
        )
    }
}

export default Gallery