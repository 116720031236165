import React, { Component } from "react"

import AdminPost from "../AdminPost/AdminPost"
import "./AdminViewPosts.scss"

class AdminViewPosts extends Component {
    render() {
        let posts = this.props.posts.map((post, i) => {
            return (
                <AdminPost key={i} post={post} changeToEdit={this.props.changeToEdit} getPostsAgain={this.props.getPostsAgain} doneEditingPost={this.props.doneEditingPost} user={this.props.user} />
            )
        })
        return(
            <div className="admin_view_posts_panel">
                <div className="admin_view_posts_panel_content">
                    <div className="admin_view_posts_header">
                        <h4>
                            News posts - page {this.props.pageNumber}
                        </h4>
                        <button className="admin_new_post_btn" onClick={this.props.changeToAdd}>New</button>
                    </div>
                    <div className="admin_view_posts_posts_container">
                        {posts}
                    </div>
                    <div className="admin_view_posts_page_btn_container">
                        <button disabled={this.props.pageNumber === 1} 
                        onClick={this.props.previousPage}>Previous</button>
                        <button disabled={this.props.pages === this.props.pageNumber} 
                        onClick={this.props.nextPage}>Next</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminViewPosts