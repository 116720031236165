import React from "react"

import AdminTile from "./Component/AdminTile/AdminTile"
import "./AdminViewTiles.scss"

let AdminViewTiles = (props) => {
    let tilesRemainingTypeOne = props.tiles.length === 1 ? 0 : 1
    let tilesRemainingTypeTwo = props.tiles.length === 2 ? 0 : props.tiles.length === 1 ? 1 : 2

    let tiles = props.tiles.map((tile, i) => {
        return (
            <AdminTile key={i} tile={tile} getTilesCategoriesAgain={props.getTilesCategoriesAgain} changeToEdit={props.changeToEdit} />
        )
    })

    let changeToAdd = () => {
        if(props.container.type === "one img one by two" || props.container.type === "one img two by one" || props.container === "one img two by two") {
            if(tilesRemainingTypeOne > 0) {
                props.changeToAdd()
            }
            else {
                alert("No more tiles can be added to this container")
            }
        }
        else {
            if(tilesRemainingTypeTwo > 0) {
                props.changeToAdd()
            }
            else {
                alert("No more tiles can be added to this container")
            }
        }
    }

    return (
        <div className="admin_view_tiles">
            {
                props.container.type === "one img one by two" || 
                props.container.type === "one img two by one" || 
                props.container.type === "one img two by two"
                ?
                    <div className="admin_store_page_info_container">
                        <h4>
                            {`Tiles Remaining - ${tilesRemainingTypeOne}`}
                        </h4>
                        <button onClick={changeToAdd}>New</button>
                    </div>
                :
                    <div className="admin_store_page_info_container">
                        <h4>
                            {`Tiles Remaining - ${tilesRemainingTypeTwo}`}
                        </h4>
                        <button onClick={changeToAdd}>New</button>
                    </div>
            }
            <div className="admin_view_tiles_tiles_container">
                {tiles}
            </div>
            <div className="admin_home_tiles_back_to_containers_container">
                <button onClick={props.changeToContainers}>Containers</button>
            </div>
        </div>
    )
}

export default AdminViewTiles