import React, { Component } from "react"

import "./AdminTilePreview.scss"

class AdminTilePreview extends Component {
    constructor(props) {
        super(props)

        this.tileRef = React.createRef()
    }

    componentDidMount() {
        const { REACT_APP_SERVER_HOST } = process.env

        this.tileRef.current.style.backgroundImage = `url("${REACT_APP_SERVER_HOST}${this.props.img}")`
    }

    componentDidUpdate(prevProps) {
        if(this.props.img !== prevProps.img) {
            const { REACT_APP_SERVER_HOST } = process.env

            this.tileRef.current.style.backgroundImage = `url("${REACT_APP_SERVER_HOST}${this.props.img}")`
        }
    }

    stopRun = (e) => {
        e.stopPropagation()
    }

    render() {
        const { REACT_APP_SERVER_HOST } = process.env
        let style = this.props.show ? { "dispaly": "flex" } : { "display" : "none" }
        return (
            <div className="admin_tile_preview" style={style} onClick={this.props.openClosePreview}>
                <div>
                    {
                        this.props.container === "one img one by two"
                        ?
                            <div className="admin_tile_preview_one_img_one_by_two admin_tile_preview_img_container">
                                <img ref={this.tileRef} src={`${REACT_APP_SERVER_HOST}/Home/yellow_edge.png`} alt="" onClick={this.stopRun}/>
                            </div>
                        :
                        this.props.container === "two img one by two"
                        ?
                            <div className="admin_tile_preview_two_img_one_by_two admin_tile_preview_img_container">
                                <img ref={this.tileRef} src={`${REACT_APP_SERVER_HOST}/Home/yellow_edge.png`} alt="" onClick={this.stopRun}/>
                            </div>
                        :
                        this.props.container === "two img two by one"
                        ?
                            <div className="admin_tile_preview_two_img_two_by_one admin_tile_preview_img_container">
                                <img ref={this.tileRef} src={`${REACT_APP_SERVER_HOST}/Home/yellow_edge.png`} alt="" onClick={this.stopRun}/>
                            </div>
                        :
                        this.props.container === "one img two by one"
                        ?
                            <div className="admin_tile_preview_one_img_two_by_one admin_tile_preview_img_container">
                                <img ref={this.tileRef} src={`${REACT_APP_SERVER_HOST}/Home/yellow_edge.png`} alt="" onClick={this.stopRun}/>
                            </div>
                        :
                            <div className="admin_tile_preview_one_img_two_by_two admin_tile_preview_img_container">
                                <img ref={this.tileRef} src={`${REACT_APP_SERVER_HOST}/Home/yellow_edge.png`} alt="" onClick={this.stopRun}/>
                            </div>
                    }
                </div>
            </div>
        )
    }
}

export default AdminTilePreview