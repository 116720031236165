import React, { Component } from "react"
import axios from "axios"

import "./DropDownMenu.scss"

class DropDownMenu extends Component {
    constructor(props) {
        super(props)

        this.state = {
            categories: []
        }
    }

    async componentDidMount() {
        let categoriesRes = await axios.get(`/api/get/store/drop-down-categories/${this.props.dropDownMenu.section}`)

        this.setState({
            categories: categoriesRes.data
        })
    }

    selectCategory = async (e) => {
        await this.props.tileClicked(this.props.dropDownMenu.section, e.target.value)
        this.props.scrollToSection(this.props.dropDownMenu.section)
    }

    render() {
        let btnClass = this.props.activeDropDown === this.props.dropDownMenu.id ? "drop_down_btn drop_down_btn_active" : "drop_down_btn"
        let contentClass = this.props.activeDropDown === this.props.dropDownMenu.id ? "drop_down_content drop_down_content_show" : "drop_down_content"
        let arrowContainerClass = this.props.activeDropDown === this.props.dropDownMenu.id ? "drop_down_arrow_container drop_down_arrow_container_active" : "drop_down_arrow_container"
        let arrowClass = this.props.activeDropDown === this.props.dropDownMenu.id ? "drop_down_arrow_up" : "drop_down_arrow_down"
        let categories = this.state.categories.map((category, i) => {
            return (
                <button key={i} value={category.id} onClick={this.selectCategory}>{category.name}</button>
            )
        })
        return (
            <div className="drop_down_menu" onClick={(e) => e.stopPropagation()}>
                <button className={btnClass} onClick={() => this.props.changeActiveDropDown(this.props.dropDownMenu.id)}>{this.props.dropDownMenu.name}</button>
                <div className={contentClass}>
                    {categories}
                </div>
                <div className={arrowContainerClass} onClick={() => this.props.changeActiveDropDown(this.props.dropDownMenu.id)}>
                    <div className={arrowClass}></div>
                </div>
            </div>
        )
    }
}

export default DropDownMenu