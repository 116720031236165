import React from "react"
import axios from "axios"

import "./AdminSectionCategory.scss"

let AdminSectionCategory = (props) => {
    let EditCategory = () => {
        props.changeToEdit(props.category)
    }

    let deleteCategory = async () => {
        try {
            let deleteBtn = document.getElementById(`admin_section_category_delete_btn_${props.category.id}`)
            deleteBtn.innerHTML = ""
            deleteBtn.classList.add("spinner")

            if(window.confirm("Are you sure you want to delete this Category? All products associated with this category will be deleted.")) {
                let categoryRes = await axios.delete(`/api/admin/delete/store-section-category/${props.category.id}`)
    
                alert(categoryRes.data)
    
                deleteBtn.innerHTML = "Delete"
                deleteBtn.classList.remove("spinner")
    
                props.getCategoriesAgain()
            }
            else {
                deleteBtn.innerHTML = "Delete"
                deleteBtn.classList.remove("spinner")
            }
        }
        catch(err) {
            let deleteBtn = document.getElementById("admin_section_category_delete_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            deleteBtn.innerHTML = "Delete"
            deleteBtn.classList.remove("spinner")
        }
    }

    let changeToProducts = () => {
        props.changeToProducts(props.category)
    }

    return (
        <div className="admin_section_category admin_store_list_item">
            <p>{props.category.name}</p>
            <div className="admin_section_category_btn_container admin_store_list_item_btn_container">
                <button onClick={changeToProducts}>Products</button>
                <button onClick={EditCategory}>Edit</button>
                <button id={`admin_section_category_delete_btn_${props.category.id}`} onClick={deleteCategory}>Delete</button>
            </div>
        </div>
    )
}

export default AdminSectionCategory