import React, { Component } from "react"
import axios from "axios"

import "./AdminEditLink.scss"

class AdminEditLink extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: "",
            url: "",
            img: "",
            imgFileName: "No file selected.",
            imgDeleteName: "",
            oldFileName: "",
            order: 0,
            changeOrder: false,
            swapLinkId: 0,
            manualOrderReset: false
        }
    }

    componentDidMount() {
        let oldFileName = this.props.link.img.split("LinkImages/")[1]
        this.setState({
            name: this.props.link.name,
            url: this.props.link.url,
            img: this.props.link.img,
            oldFileName: oldFileName,
            order: this.props.link.link_order
        })
    }

    componentDidUpdate(prevProps) {
        if(this.props.link.id !== prevProps.link.id) {
            let oldFileName = this.props.link.img.split("LinkImages/")[1]
            this.setState({
                name: this.props.link.name,
                url: this.props.link.url,
                img: this.props.link.img,
                oldFileName: oldFileName,
                order: this.props.link.link_order
            })
        }
    }

    updateInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    changeLinkOrder = (e) => {
        let link = this.props.links.find((link) => link.link_order === Number(e.target.value))

        this.setState({
            order: Number(e.target.value),
            changeOrder: true,
            swapLinkId: link.id
        })
    }

    uploadLinkImage = async (e) => {
        try {
            const files = e.target.files
    
            if(files.length > 1) {
                alert("You can only upload one image")
            }
            else {
                if(files[0].type === "image/png" || files[0].type === "image/bmp" || files[0].type === "image/jpeg" || files[0].type === "image/jpg" || files[0].type === "image/gif" || files[0].type === "image/x-icon") {
                    let blob = files[0].slice(0, files[0].size, files[0].type)
                    let newFile = new File([blob], files[0].name.replace(/\s/g, "-"), {type: files[0].type})
                    const formData = new FormData()
                    formData.append("linkImage", newFile)
    
                    if(this.state.imgFileName !== "No file selected.") {
                        let deleteFile = new File(["yes"], "delete", { type: "text/plain" })
                        let imgNameFile = new File([`${this.state.imgDeleteName}`], "imgName", { type: "text/plain" })
    
                        formData.append("delete", deleteFile)
                        formData.append("imgName", imgNameFile)
                    }
    
                    let linkImgRes = await axios.post("/api/admin/add/link-img", formData)
    
                    e.target.value = null
    
                    this.setState({
                        img: linkImgRes.data[0],
                        imgFileName: linkImgRes.data[1],
                        imgDeleteName: linkImgRes.data[2]
                    })
                }
                else {
                    alert("Image file must be a png, bmp, jpeg or jpg image")
                }
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    updateLink = async () => {
        try {
            let updateBtn = document.getElementById("admin_edit_link_update_btn")
            updateBtn.innerHTML = ""
            updateBtn.classList.add("spinner")

            let regExp = /^(ftp|http|https):\/\/[^ "]+$/

            if(this.state.name.length && this.state.url.match(regExp)) {
                let linkRes = await axios.put("/api/admin/update/link", { 
                    id: this.props.link.id, 
                    name: this.state.name, 
                    url: this.state.url, 
                    img: this.state.img, 
                    changeOrder: this.state.changeOrder, 
                    swapLinkId: this.state.swapLinkId 
                })

                if(this.state.imgFileName !== "No file selected.") {
                    await axios.delete(`/api/admin/delete/old-link-img/${this.state.oldFileName}`)
                }

                alert(linkRes.data)

                updateBtn.innerHTML = "Update"
                updateBtn.classList.remove("spinner")

                this.setState({
                    name: "",
                    url: ""
                }, async () => {
                    await this.props.getLinksAgain()
                    this.props.changeToView()
                })
                
            }
            else {
                alert("Link must have a name and a valid url")

                updateBtn.innerHTML = "Update"
                updateBtn.classList.remove("spinner")
            }
        }
        catch(err) {
            let updateBtn = document.getElementById("admin_edit_link_update_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            updateBtn.innerHTML = "Update"
            updateBtn.classList.remove("spinner")
        }
    }

    toggleOrderReset = () => {
        this.setState({
            manualOrderReset: !this.state.manualOrderReset
        })
    }

    manuallyUpdateLinkOrder = async () => {
        try {
            let updateBtn = document.getElementById("manually_update_link_order_btn")
            updateBtn.innerHTML = ""
            updateBtn.classList.add("spinner")

            if( this.state.order > 2147483647 || this.state.order <= 0) {
                alert("Order must be above 0 and less than 2147483647")

                updateBtn.innerHTML = "Update Order"
                updateBtn.classList.remove("spinner")
            }
            else {
                let manualUpdateRes = await axios.put("/api/admin/update/link/order", { id: this.props.link.id, order: this.state.order })

                alert(manualUpdateRes.data)

                updateBtn.innerHTML = "Update Order"
                updateBtn.classList.remove("spinner")

                await this.props.getLinksAgain()
            }
        }
        catch(err) {
            let updateBtn = document.getElementById("manually_update_link_order_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            updateBtn.innerHTML = "Update Order"
            updateBtn.classList.remove("spinner")
        }
    }

    render() {
        const { REACT_APP_SERVER_HOST } = process.env
        let orders = this.props.links.map((link, i) => {
            return (
                <option key={i} value={link.link_order}>{link.link_order}</option>
            )
        })
        return (
            <div className="admin_edit_link">
                <h4>
                    <strong>Edit {this.props.link.name}:</strong>
                </h4>
                <div className="admin_store_form_preview_container">
                    <div className="admin_store_form_container">
                        <div className="admin_store_input_container">
                            <p>Image (50 x 50):</p>
                            <div className="admin_store_file_upload_container">
                                <input type="file" 
                                accept="image/*" 
                                onChange={this.uploadLinkImage}/>
                                <p>{this.state.imgFileName}</p>
                            </div>
                        </div>
                        <div className="admin_store_input_container">
                            <p>Name:</p>
                            <input type="text" 
                            name="name" 
                            placeholder="Name" 
                            value={this.state.name} 
                            onChange={this.updateInput}/>
                        </div>
                        <div className="admin_store_input_container">
                            <p>URL:</p>
                            <input type="text" 
                            name="url" 
                            placeholder="http://example.com/product" 
                            value={this.state.url} 
                            onChange={this.updateInput}/>
                        </div>
                        <div className="admin_store_input_container">
                            <p>Order:</p>
                            <select name="order" value={this.state.order} onChange={this.changeLinkOrder}>
                                {orders}
                            </select>
                        </div>
                        <div className="admin_store_input_container">
                            <p>Link order not working correctly? Click the button below to manually set this link's order</p>
                            {
                                this.state.manualOrderReset
                                ?
                                    <div className="admin_store_order_change_container">
                                        <button className="admin_store_order_reset_cancel_btn" onClick={this.toggleOrderReset}>X</button>
                                        <input type="number" 
                                        name="order" 
                                        placeholder="0" 
                                        value={this.state.order} 
                                        onChange={this.updateInput} />
                                        <button id="manually_update_link_order_btn" 
                                        className="admin_store_spinner_container" 
                                        onClick={this.manuallyUpdateLinkOrder}>Update Order</button>
                                    </div>
                                :
                                    <div className="admin_store_order_change_container">
                                        <button onClick={this.toggleOrderReset}>Set Order</button>
                                    </div>
                            }
                        </div>
                    </div>
                    <div className="admin_store_preview_container">
                        <img src={`${REACT_APP_SERVER_HOST}${this.state.img}`} alt=""/>
                    </div>
                </div>
                <div className="admin_store_btn_container">
                    <button onClick={this.props.changeToView}>Cancel</button>
                    <button id="admin_edit_link_update_btn" className="admin_spinner_container" onClick={this.updateLink}>Update</button>
                </div>
            </div>
        )
    }
}

export default AdminEditLink