import React, { Component } from "react"
import axios from "axios"

import "./EditPartner.scss"

class EditPartner extends Component {
    constructor(props) {
        super(props)

        this.state = {
            img: "",
            imgFileName: "No file selected.",
            imgDeleteName: "",
            imgBackgroundColor: "",
            name: "",
            description: "",
            link: "",
            order: 0,
            changeOrder: false,
            swapPartnerId: 0,
            regErr: false,
            oldFileName: "",
            manualOrderReset: false
        }
    }

    componentDidMount() {
        let oldFileName = this.props.partner.img.split("StorePartners/")[1]
        this.setState({
            img: this.props.partner.img,
            imgBackgroundColor: this.props.partner.img_background,
            name: this.props.partner.name,
            description: this.props.partner.description,
            link: this.props.partner.link,
            order: this.props.partner.partner_order,
            oldFileName: oldFileName
        })
    }

    componentDidUpdate(prevProps) {
        if(this.props.partner.id !== prevProps.partner.id) {
            let oldFileName = this.props.partner.img.split("StorePartners/")[1]
            this.setState({
                img: this.props.partner.img,
                imgBackgroundColor: this.props.partner.img_background,
                name: this.props.partner.name,
                link: this.props.partner.link,
                order: this.props.partner.partner_order,
                oldFileName: oldFileName
            })
        }
    }

    updateInput = (e) => {
        if(e.target.name === "link") {
            let regExp = /^(ftp|http|https):\/\/[^ "]+$/
            let err = false

            if(e.target.value.match(regExp)) {
                err = false
            }
            else if(!e.target.value) {
                err = false
            }
            else {
                err = true
            }

            this.setState({
                link: e.target.value,
                regErr: err
            })
        }
        else {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    uploadPartnerImg = async (e) => {
        try {
            const files = e.target.files

            if(files.length > 1) {
                alert("You can only upload one image")
            }
            else {
                if(files[0].type === "image/png" || files[0].type === "image/bmp" || files[0].type === "image/jpeg" || files[0].type === "image/jpg" || files[0].type === "image/gif" || files[0].type === "image/x-icon") {
                    let blob = files[0].slice(0, files[0].size, files[0].type)
                    let newFile = new File([blob], files[0].name.replace(/\s/g, "-"), {type: files[0].type})
                    const formData = new FormData()
                    formData.append("partnerImage", newFile)

                    if(this.state.imgFileName !== "No file selected.") {
                        let deleteFile = new File(["yes"], "delete", { type: "text/plain" })
                        let imgNameFile = new File([`${this.state.imgDeleteName}`], "imgName", { type: "text/plain" })

                        formData.append("delete", deleteFile)
                        formData.append("imgName", imgNameFile)
                    }

                    let partnerImgRes = await axios.post("/api/admin/add/store-partner-img", formData)

                    e.target.value = null

                    this.setState({
                        img: partnerImgRes.data[0],
                        imgFileName: partnerImgRes.data[1],
                        imgDeleteName: partnerImgRes.data[2],
                        imgPreview: partnerImgRes.data[0]
                    })
                }
                else {
                    alert("Image file must be a png, bmp, jpeg, jpg or ico image")
                }
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    changePartnerOrder = (e) => {
        let partner = this.props.partners.find((partner) => partner.partner_order === Number(e.target.value))

        this.setState({
            order: Number(e.target.value),
            changeOrder: true,
            swapPartnerId: partner.id
        })
    }

    updatePartner = async () => {
        try {
            let updateBtn = document.getElementById("admin_store_edit_partner_update_btn")
            updateBtn.innerHTML = ""
            updateBtn.classList.add("spinner")

            let regExp = /^(ftp|http|https):\/\/[^ "]+$/

            if(!this.state.img.length || !this.state.name.length || !this.state.link.length) {
                alert("You must upload an image, add a name and add a link")

                updateBtn.innerHTML = "Update"
                updateBtn.classList.remove("spinner")
            }
            else {
                if(this.state.link.match(regExp)) {
                    let partnerRes = await axios.put("/api/admin/update/partner", { 
                        id: this.props.partner.id,
                        img: this.state.img, 
                        imgBackgroundColor: this.state.imgBackgroundColor,
                        name: this.state.name,
                        description: this.state.description, 
                        link: this.state.link, 
                        order: this.state.order,
                        changeOrder: this.state.changeOrder,
                        swapPartnerId: this.state.swapPartnerId 
                    })
    
                    if(this.state.imgFileName !== "No file selected.") {
                        await axios.delete(`/api/admin/delete/old-partner-img/${this.state.oldFileName}`)
                    }
    
                    alert(partnerRes.data)
    
                    updateBtn.innerHTML = "Update"
                    updateBtn.classList.remove("spinner")
    
                    this.setState({
                        img: "",
                        imgFileName: "No file selected.",
                        imgDeleteName: "",
                        imgBackgroundColor: "",
                        name: "",
                        link: "",
                        order: 0,
                        changeOrder: false,
                        swapPartnerId: 0,
                        regErr: false
                    }, async () => {
                        await this.props.getPartnersAgain()
                        this.props.changeToView()
                    })
                }
                else {
                    alert("Invalid link")

                    updateBtn.innerHTML = "Update"
                    updateBtn.classList.remove("spinner")
                }
            }
        }
        catch(err) {
            let updateBtn = document.getElementById("admin_store_edit_partner_update_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            updateBtn.innerHTML = "Update"
            updateBtn.classList.remove("spinner")
        }
    }

    toggleOrderReset = () => {
        this.setState({
            manualOrderReset: !this.state.manualOrderReset
        })
    }

    manuallyUpdatePartnerOrder = async () => {
        try {
            let updateBtn = document.getElementById("manually_update_partner_order_btn")
            updateBtn.innerHTML = ""
            updateBtn.classList.add("spinner")

            if( this.state.order > 2147483647 || this.state.order <= 0) {
                alert("Order must be above 0 and less than 2147483647")

                updateBtn.innerHTML = "Update Order"
                updateBtn.classList.remove("spinner")
            }
            else {
                let manualUpdateRes = await axios.put("/api/admin/update/partner/order", { id: this.props.partner.id, order: this.state.order })

                alert(manualUpdateRes.data)

                updateBtn.innerHTML = "Update Order"
                updateBtn.classList.remove("spinner")

                await this.props.getPartnersAgain()
            }
        }
        catch(err) {
            let updateBtn = document.getElementById("manually_update_partner_order_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            updateBtn.innerHTML = "Update Order"
            updateBtn.classList.remove("spinner")
        }
    }

    render() {
        const { REACT_APP_SERVER_HOST } = process.env
        let imgBackgroundClass = this.state.imgBackgroundColor === "light" ? "admin_store_edit_partner_preview_img_container light_background" : "admin_store_edit_partner_preview_img_container dark_background"
        let linkClassName = this.state.regErr ? "admin_store_edit_partner_link_input_err" : "admin_store_edit_partner_link_input"
        let orders = this.props.partners.map((partner, i) => {
            return (
                <option key={i} value={partner.partner_order}>{partner.partner_order}</option>
            )
        })
        return (
            <div className="admin_store_edit_partner">
                <div className="admin_store_edit_partner_form_preview_container">
                    <div className="admin_store_form_container">
                        <div className="admin_store_input_container">
                            <p>Image (100 x 100):</p>
                            <div className="admin_store_file_upload_container">
                                <input type="file" 
                                className="admin_store_file_upload_input" 
                                accept="image/*" 
                                onChange={this.uploadPartnerImg}/>
                                <p>{this.state.imgFileName}</p>
                            </div>
                        </div>
                        <div className="admin_store_input_container">
                            <p>Image Background Color:</p>
                            <select name="imgBackgroundColor" value={this.state.imgBackgroundColor} onChange={this.updateInput}>
                                <option value="light">Light</option>
                                <option value="dark">Dark</option>
                            </select>
                        </div>
                        <div className="admin_store_input_container">
                            <p>Name:</p>
                            <input type="text" 
                            name="name" 
                            placeholder="Name" 
                            value={this.state.name} 
                            onChange={this.updateInput}/>
                        </div>
                        <div className="admin_store_input_container">
                            <p>Link:</p>
                            <input type="text"
                            className={linkClassName}  
                            name="link" 
                            placeholder="https://example.com" 
                            value={this.state.link} 
                            onChange={this.updateInput}/>
                        </div>
                        <div className="admin_store_input_container">
                            <p>Order:</p>
                            <select name="order" value={this.state.order} onChange={this.changePartnerOrder}>
                                {orders}
                            </select>
                        </div>
                        <div className="admin_store_input_container">
                            <p>Partner order not working correctly? Click the button below to manually set this partner's order</p>
                            {
                                this.state.manualOrderReset
                                ?
                                    <div className="admin_store_order_change_container">
                                        <button className="admin_store_order_reset_cancel_btn" onClick={this.toggleOrderReset}>X</button>
                                        <input type="number" 
                                        name="order" 
                                        placeholder="0" 
                                        value={this.state.order} 
                                        onChange={this.updateInput} />
                                        <button id="manually_update_partner_order_btn" 
                                        className="admin_store_spinner_container" 
                                        onClick={this.manuallyUpdatePartnerOrder}>Update Order</button>
                                    </div>
                                :
                                    <div className="admin_store_order_change_container">
                                        <button onClick={this.toggleOrderReset}>Set Order</button>
                                    </div>
                            }
                        </div>
                    </div>
                    <div className="admin_store_edit_partner_preview_container">
                        <div className="admin_store_edit_partner_preview">
                            <div className={imgBackgroundClass}>
                                <img src={`${REACT_APP_SERVER_HOST}${this.state.img}`} alt=""/>
                            </div>
                            <h4>
                                <strong>{this.state.name}</strong>
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="admin_store_btn_container">
                    <button onClick={this.props.changeToView}>Cancel</button>
                    <button id="admin_store_edit_partner_update_btn" 
                    disabled={!this.state.img.length || !this.state.name.length} 
                    onClick={this.updatePartner}>Update</button>
                </div>
            </div>
        )
    }
}

export default EditPartner