import React from "react"

import "./StoreCategory.scss"

let StoreCategory = (props) => {
    let changeCategory = () => {
        props.changeCategory(props.category.id)
    }

    let className = props.currentCategory === props.category.id ? "active_category store_category" : "store_category"

    return (
        <div className={className} onClick={changeCategory}>
            <p>{props.category.name}</p>
        </div>
    )
}

export default StoreCategory