import React, { Component } from "react"
import axios from "axios"

import "./AdminEditProduct.scss"

class AdminEditProduct extends Component {
    constructor(props) {
        super(props)

        this.state = {
            img: "",
            name: "",
            description: "",
            imgFileName: "No file selected.",
            deleteImgName: "",
            oldImgName: "",
            order: 0,
            changeOrder: false,
            swapProductId: 0,
            manualOrderReset: false
        }
    }

    componentDidMount() {
        let oldImgName = this.props.product.img.split("ProductImages/")[1]
        let description = this.props.product.description
        if(description === null) {
            description = ""
        }
        this.setState({
            img: this.props.product.img,
            name: this.props.product.name,
            description: description,
            oldImgName: oldImgName,
            deleteImgName: oldImgName,
            order: this.props.product.product_order
        })
    }

    componentDidUpdate(prevProps) {
        if(this.props.product.id !== prevProps.product.id) {
            let oldImgName = this.props.product.img.split("ProductImages/")[1]
            let description = this.props.product.description
            if(description === null) {
                description = ""
            }
            this.setState({
                img: this.props.product.img,
                name: this.props.product.name,
                description: description,
                oldImgName: oldImgName,
                deleteImgName: oldImgName,
                order: this.props.product.product_order
            })
        }
    }

    updateInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    uploadProductImg = async (e) => {
        try {
            const files = e.target.files

            if(files.length > 1) {
                alert("You can only upload one image")
            }
            else {
                if(files[0].type === "image/png" || files[0].type === "image/bmp" || files[0].type === "image/jpeg" || files[0].type === "image/jpg" || files[0].type === "image/gif" || files[0].type === "image/x-icon") {
                    let blob = files[0].slice(0, files[0].size, files[0].type)
                    let newFile = new File([blob], files[0].name.replace(/\s/g, "-"), {type: files[0].type})
                    const formData = new FormData()
                    formData.append("productImage", newFile)
    
                    if(this.state.imgFileName !== "No file selected.") {
                        let deleteFile = new File(["yes"], "delete", { type: "text/plain" })
                        let imgNameFile = new File([`${this.state.deleteImgName}`], "imgName", { type: "text/plain" })
    
                        formData.append("delete", deleteFile)
                        formData.append("imgName", imgNameFile)
                    }

                    let productImgRes = await axios.post("/api/admin/add/product-img", formData)
    
                    e.target.value = null
    
                    this.setState({
                        img: productImgRes.data[0],
                        imgFileName: productImgRes.data[1],
                        deleteImgName: productImgRes.data[2]
                    })
                }
                else {
                    alert("Image file must be a png, bmp, jpeg or jpg image")
                }
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    changeProductOrder = (e) => {
        let product = this.props.products.find((product) => product.product_order === Number(e.target.value))

        this.setState({
            order: Number(e.target.value),
            changeOrder: true,
            swapProductId: product.id
        })
    }

    updateProduct = async () => {
        try {
            let updateBtn = document.getElementById("admin_edit_product_update_btn")
            updateBtn.innerHTML = ""
            updateBtn.classList.add("spinner")

            if(!this.state.name.length) {
                alert("Product must have a name.")

                updateBtn.innerHTML = "Update"
                updateBtn.classList.remove("spinner")
            }
            else {

                let productRes = await axios.put("/api/admin/update/product", { id: this.props.product.id, 
                    img: this.state.img, 
                    name: this.state.name, 
                    description: this.state.description, 
                    changeOrder: this.state.changeOrder,
                    swapProductId: this.state.swapProductId
                })

                if(this.state.deleteImgName !== this.state.oldImgName) {
                    await axios.delete(`/api/admin/delete/product-image/${this.state.oldImgName}`)
                }

                alert(productRes.data)

                updateBtn.innerHTML = "Update"
                updateBtn.classList.remove("spinner")

                this.setState({
                    img: "",
                    name: "",
                    description: "",
                    imgFileName: "No file selected.",
                    deleteImgName: "",
                    oldImgName: "",
                    order: 0,
                    changeOrder: false,
                    swapProductId: 0,
                    manualOrderReset: false
                }, async () => {
                    await this.props.getProductsAgain()
                    this.props.changeToView()
                })

            }
        }
        catch(err) {
            let updateBtn = document.getElementById("admin_edit_product_update_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            updateBtn.innerHTML = "Update"
            updateBtn.classList.remove("spinner")
        }
    }

    toggleOrderReset = () => {
        this.setState({
            manualOrderReset: !this.state.manualOrderReset
        })
    }

    manuallyUpdateProductOrder = async () => {
        try {
            let updateBtn = document.getElementById("manually_update_product_order_btn")
            updateBtn.innerHTML = ""
            updateBtn.classList.add("spinner")

            if( this.state.order > 2147483647 || this.state.order <= 0) {
                alert("Order must be above 0 and less than 2147483647")

                updateBtn.innerHTML = "Update Order"
                updateBtn.classList.remove("spinner")
            }
            else {
                let manualUpdateRes = await axios.put("/api/admin/update/product/order", { id: this.props.product.id, order: this.state.order })

                alert(manualUpdateRes.data)

                updateBtn.innerHTML = "Update Order"
                updateBtn.classList.remove("spinner")

                await this.props.getProductsAgain()
            }
        }
        catch(err) {
            let updateBtn = document.getElementById("manually_update_product_order_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            updateBtn.innerHTML = "Update Order"
            updateBtn.classList.remove("spinner")
        }
    }

    render() {
        const { REACT_APP_SERVER_HOST } = process.env
        let productsOrder = this.props.products.map((product, i) => {
            return (
                <option key={i} value={product.product_order}>{product.product_order}</option>
            )
        })
        
        return (
            <div className="admin_edit_product">
                <h4>
                    <strong>Edit {this.props.product.name}:</strong>
                </h4>
                <div className="admin_store_form_preview_container">
                    <div className="admin_store_form_container">
                        <div className="admin_store_input_container">
                            <p>Image (250 x 280):</p>
                            <div className="admin_store_upload_container">
                                <input type="file" 
                                className="admin_store_file_upload_container" 
                                accept="image/*" 
                                onChange={this.uploadProductImg}/>
                                <p>{this.state.imgFileName}</p>
                            </div>
                        </div>
                        <div className="admin_store_input_container">
                            <p>Name:</p>
                            <input type="text" 
                            name="name" 
                            placeholder="Name" 
                            value={this.state.name}
                            onChange={this.updateInput}/>
                        </div>
                        <div className="admin_store_input_container">
                            <p>Order:</p>
                            <select name="order" value={this.state.order} onChange={this.changeProductOrder}>
                                {productsOrder}
                            </select>
                        </div>
                        <div className="admin_store_input_container">
                            <p>Product order not working correctly? Click the button below to manually set this product's order</p>
                            {
                                this.state.manualOrderReset
                                ?
                                    <div className="admin_store_order_change_container">
                                        <button className="admin_store_order_reset_cancel_btn" onClick={this.toggleOrderReset}>X</button>
                                        <input type="number" 
                                        name="order" 
                                        placeholder="0" 
                                        value={this.state.order} 
                                        onChange={this.updateInput} />
                                        <button id="manually_update_product_order_btn" 
                                        className="admin_store_spinner_container" 
                                        onClick={this.manuallyUpdateProductOrder}>Update Order</button>
                                    </div>
                                :
                                    <div className="admin_store_order_change_container">
                                        <button onClick={this.toggleOrderReset}>Set Order</button>
                                    </div>
                            }
                        </div>
                    </div>
                    <div className="admin_edit_product_preview_container">
                        <div className="admin_edit_product_preview">
                            <div className="admin_edit_product_preview_content">
                                <div className="admin_edit_product_preview_img_container">
                                    <img src={`${REACT_APP_SERVER_HOST}/Home/yellow_edge.png`} alt="" className="fading_img" style={{ backgroundImage: `url("${REACT_APP_SERVER_HOST}${this.state.img}")` }}/>
                                </div>
                                <div className="admin_edit_product_preview_info_container">
                                    <h4>
                                        <strong>{this.state.name}</strong>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="admin_edit_product_btn_container">
                    <button onClick={this.props.changeToView}>Cancel</button>
                    <button id="admin_edit_product_update_btn" disabled={!this.state.name.length} onClick={this.updateProduct}>Update</button>
                </div>
            </div>
        )
    }
}

export default AdminEditProduct