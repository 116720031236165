import React, { Component } from "react"
import axios from "axios"

import "./AdminUser.scss"

class AdminUser extends Component {
    constructor(props) {
        super(props)

        const {
            REACT_APP_SERVER_HOST
        } = process.env

        this.state = {
            editingRole: false,
            role: this.props.user.role,
            editingPassword: false,
            userPassword: "",
            userPasswordVisibility: "hide",
            userPasswordIcon: `${REACT_APP_SERVER_HOST}/PasswordVisibility/hide_password.png`
        }
    }

    toggleEditing = () => {
        this.setState({
            editingRole: !this.state.editingRole
        })
    }

    editRole = (e) => {
        try {
            this.setState({
                role: e.target.value
            })
        }
        catch(err) {
            alert("Error editing role")
        }
    }

    changeRole = async () => {
        try {
            let changeRoleBtn = document.getElementById("change_role_btn")
            changeRoleBtn.innerHTML = ""
            changeRoleBtn.classList.add("spinner")

            let editRoleRes = await axios.put("/api/admin/update/user/role", { id: this.props.user.id, role: this.state.role})
            alert(editRoleRes.data[0])

            changeRoleBtn.innerHTML = "Change Role"
            changeRoleBtn.classList.remove("spinner")

            this.setState({
                editingRole: false
            }, () => {
                this.props.getUsersAgain(editRoleRes.data[1], this.props.user.id, this.props.user.email)
            })
        }
        catch(err) {
            let changeRoleBtn = document.getElementById("change_role_btn")
            changeRoleBtn.innerHTML = "Change Role"
            changeRoleBtn.classList.remove("spinner")

            if(err.response) {
                if (err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    confirmDeleteUser = () => {
        try {
            let deleteUserBtn = document.getElementById(`delete_user_${this.props.user.id}_btn`)
            deleteUserBtn.innerHTML = ""
            deleteUserBtn.classList.add("spinner")

            if(window.confirm("Are you sure you want to delete this user?")) {
                this.deleteUser()
            }
            else {
                return
            }
        }
        catch(err) {
            alert("Error deleting user please try again later")
        }
    }

    deleteUser = async () => {
        try {
            let deleteUserBtn = document.getElementById(`delete_user_${this.props.user.id}_btn`)

            deleteUserBtn.innerHTML = ""
            deleteUserBtn.classList.add("spinner")

            let deleteUserRes = await axios.delete(`/api/admin/delete/user/${this.props.user.id}`)

            alert(deleteUserRes.data)

            deleteUserBtn.innerHTML = "Delete User"
            deleteUserBtn.classList.remove("spinner")
            
            this.props.getUsersAgain("no refresh", this.props.user.id, this.props.user.email)
        }
        catch(err) {
            let deleteUserBtn = document.getElementById(`delete_user_${this.props.user.id}_btn`)

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            deleteUserBtn.innerHTML = "Delete User"
            deleteUserBtn.classList.remove("spinner")
        }
    }

    unlockUserAccount = async () => {
        try {
            let unlockBtn = document.getElementById("unlock_btn")
            unlockBtn.innerHTML = ""
            unlockBtn.classList.add("spinner")

            let unlockUserRes = await axios.put("/api/admin/unlock/user", {id: this.props.user.id, email: this.props.user.email, screenName: this.props.user.screenname})

            alert(unlockUserRes.data)
            unlockBtn.classList.remove("spinner")
            unlockBtn.innerHTML="Unlock"
        }
        catch(err) {
            let unlockBtn = document.getElementById("unlock_btn")
            unlockBtn.classList.remove("spinner")
            unlockBtn.innerHTML="Unlock"

            if(err.response.data) {
                alert(err.response.data)
            }
        }
    }

    toggleEditingPassword = () => {
        this.setState({
            editingPassword: !this.state.editingPassword,
            userPassword: ""
        })
    }

    updateInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    showHideUserPassword = () => {
        const {
            REACT_APP_SERVER_HOST
        } = process.env

        let passwordInput = document.getElementById(`change_user_password_${this.props.user.id}`)

        if(this.state.userPasswordVisibility === "hide") {
            passwordInput.type = "text"

            this.setState({
                userPasswordVisibility: "show",
                userPasswordIcon: `${REACT_APP_SERVER_HOST}/PasswordVisibility/show_password.png`
            })
        }
        else {
            passwordInput.type = "password"

            this.setState({
                userPasswordVisibility: "hide",
                userPasswordIcon: `${REACT_APP_SERVER_HOST}/PasswordVisibility/hide_password.png`
            })
        }
    }

    changeUserPassword = async () => {
        try {
            if(this.state.userPassword) {
                if(window.confirm("Are you sure you want to update this user's password?")) {
                    let changeUserPasswordBtn = document.getElementById(`change_user_${this.props.user.id}_password_btn`)
            
                    changeUserPasswordBtn.innerHTML = ""
                    changeUserPasswordBtn.classList.add("spinner")
            
                    let updatePasswordRes = await axios.put("/api/admin/update/other-user-password", { id: this.props.user.id, password: this.state.userPassword})
    
                    alert(updatePasswordRes.data)
    
                    changeUserPasswordBtn.innerHTML = "Confirm"
                    changeUserPasswordBtn.classList.remove("spinner")
    
                    this.toggleEditingPassword()
                }
                else {
                    this.toggleEditingPassword()
                }
            }
            else {
                alert("You must Type in a password to change a user's password")
            }
        }
        catch(err) {
            let changeUserPasswordBtn = document.getElementById(`change_user_${this.props.user.id}_password_btn`)

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            changeUserPasswordBtn.innerHTML = "Confirm"
            changeUserPasswordBtn.classList.remove("spinner")
        }
    }

    render() {
        return (
            <div className="admin_user_container">
                <h4>
                    <b>{this.props.user.screenname}</b>
                </h4>
                <div className="admin_user_role_container">
                    {
                        this.state.editingRole
                        ?
                        <div>
                            <select value={this.state.role} onChange={this.editRole}>
                                <option value="super admin">Super Admin</option>
                                <option value="admin">Admin</option>
                                <option value="new user">New User</option>
                            </select>
                            <div className="admin_user_editing_role_change_cancel_container">
                                <button className="admin_user_btn" id="change_role_btn" onClick={this.changeRole}>Change Role</button>
                                <button className="admin_user_btn" style={{"marginRight": "0"}} onClick={this.toggleEditing}>Cancel</button>
                            </div>
                        </div>
                        :
                        this.state.editingPassword
                        ?
                            <div className="admin_user_editing_user_password_container">
                                <div className="password_input">
                                    <input type="password" 
                                    id={`change_user_password_${this.props.user.id}`}
                                    name="userPassword" 
                                    value={this.state.userPassword} 
                                    onChange={this.updateInput} />
                                    <img src={this.state.userPasswordIcon} alt="password_icon" onClick={this.showHideUserPassword}/>
                                </div>
                                <button className="admin_user_btn admin_change_password_btn" 
                                id={`change_user_${this.props.user.id}_password_btn`} 
                                onClick={this.changeUserPassword} 
                                disabled={!this.state.userPassword} >Confirm</button>
                                <button className="admin_user_btn" 
                                onClick={this.toggleEditingPassword}>Cancel</button>
                            </div>
                        :
                            <div>
                                <h4 style={{"marginTop": "0", "marginBottom": "0"}}>
                                    <b>{this.props.user.role}</b>
                                </h4>
                                <div>
                                    <button className="admin_user_btn" onClick={this.toggleEditing}>Edit Role</button>
                                    <button className="admin_user_btn" 
                                    style={{"width": "130px"}} 
                                    onClick={this.toggleEditingPassword}>Change Password</button>
                                    <button className="admin_user_btn admin_delete_user_btn" 
                                    style={{"marginRight": "0"}} 
                                    id={`delete_user_${this.props.user.id}_btn`} 
                                    onClick={this.confirmDeleteUser}>Delete User</button>
                                </div>
                                {
                                    this.props.user.lockedat
                                    ?
                                        <div className="admin_user_locked_container">
                                            <p style={{"marginBottom": "0", "marginRight": "0"}}>Account locked</p>
                                            <p style={{"marginBottom": "0", "width": "5px", "fontSize": "25px"}}>:</p>
                                            <button onClick={this.unlockUserAccount} id="unlock_btn">Unlock</button>
                                        </div>
                                    :
                                        null
                                }
                            </div>
                    }
                </div>
            </div>
        )
    }
}

export default AdminUser