import React, { Component } from "react"
import axios from "axios"

import StoreLink from "./Components/StoreLink/StoreLink"
import "./StoreProduct.scss"

class StoreProduct extends Component {
    constructor(props) {
        super(props)

        this.state = {
            threeLinks: [],
            links: [],
            showMore: false,
            display: false
        }
    }

    async componentDidMount() {
        let threeLinksRes = await axios.get(`/api/get/store/three-links/${this.props.product.id}`)
        let linksRes = await axios.get(`/api/get/store/links/${this.props.product.id}`)

        this.setState({
            threeLinks: threeLinksRes.data,
            links: linksRes.data,
            display: true
        })
    }

    async componentDidUpdate(prevProps) {
        if(this.props.product.id !== prevProps.product.id) {
            this.setState({
                display: false
            }, async () => {
                let threeLinksRes = await axios.get(`/api/get/store/three-links/${this.props.product.id}`)
                let linksRes = await axios.get(`/api/get/store/links/${this.props.product.id}`)
    
                this.setState({
                    threeLinks: threeLinksRes.data,
                    links: linksRes.data,
                    display: true
                })
            })
        }
    }

    toggleShowMore = () => {
        this.setState({
            showMore: !this.state.showMore
        })
    }

    render() {
        const { REACT_APP_SERVER_HOST } = process.env
        let threeLinks = this.state.threeLinks.map((link, i) => {
            return (
                <StoreLink key={i} link={link} />
            )
        })
        let links = this.state.links.map((link, i) => {
            return (
                <StoreLink key={i} link={link} />
            )
        })
        let heightClass = this.state.showMore ? "store_product store_product_show_more" : "store_product store_product_show_less"
        let displayStyle = this.state.display ? { "display": "flex" }: { "display": "none" }
        return (
            <div className={heightClass} style={displayStyle}>
                <div className="product_content">
                    <div className="product_img_container">
                        <img src={`${REACT_APP_SERVER_HOST}/Home/yellow_edge.png`} alt="" style={ {"backgroundImage": `url("${REACT_APP_SERVER_HOST}${this.props.product.img}")`} }/>
                    </div>
                    <div className="product_info_container">
                        <h4>
                            <strong>{this.props.product.name}</strong>
                        </h4>
                        {
                            this.state.showMore
                            ?
                                <div className="product_links_container">
                                    {links}
                                </div>
                            :
                                <div className="product_three_links_container">
                                    {threeLinks}
                                </div>
                        }
                    </div>
                    <div className="product_footer">
                        <div className="more_links_container">
                            {
                                this.state.links.length > 3
                                ?
                                    this.state.showMore
                                    ?
                                        <p onClick={this.toggleShowMore}>&uarr;</p>
                                    :
                                        <p onClick={this.toggleShowMore}>&darr;</p>
                                :
                                    <p></p>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default StoreProduct