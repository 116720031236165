import React, { Component } from "react"
import axios from "axios"

import AdminAllTilesContainerPreview from "./Components/AdminAllTilesContainer/AdminAllTilesContainer"
import "./AdminAllTilesPreview.scss"

class AdminAllTilesPreview extends Component {
    constructor(props) {
        super(props)

        this.state = {
            containers: []
        }
    }

    async componentDidMount() {
        let containersRes = await axios.get("/api/admin/get/all/home-containers")
        
        this.setState({
            containers: containersRes.data
        })
    }

    stopRun = (e) => {
        e.stopPropagation()
    }

    render() {
        let containers = this.state.containers.map((container, i) => {
            return (
                <AdminAllTilesContainerPreview key={i} container={container} />
            )
        })
        let style = this.props.show ? { "display": "flex" } : { "display": "none" }
        return (
            <div className="admin_all_tiles_preview" style={style} onClick={this.props.openClosePreview}>
                <div className="wall" onClick={this.stopRun}>
                    <div className="wall_grass_top"></div>
                    <header className="wall_header"></header>
                    <div className="wall_background_custom">
                        <div className="wall_fade_in"></div>
                        <div className="wall_content">
                            <div className="admin_all_tiles_preview_containers_container">
                                {containers}
                            </div>
                        </div>
                        <div className="wall_fade_out"></div>
                        <div className="wall_footer"></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminAllTilesPreview