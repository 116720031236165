import React, { Component } from "react"
import axios from "axios"

import "./AdminEditFeaturedCategory.scss"

class AdminEditFeaturedCategory extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: "",
            order: 0,
            changeOrder: false,
            swapCategoryId: 0,
            manualOrderReset: false
        }
    }

    componentDidMount() {
        this.setState({
            name: this.props.category.name,
            order: this.props.category.category_order
        })
    }

    componentDidUpdate(prevProps) {
        if(this.props.category.id !== prevProps.category.id) {
            this.setState({
                name: this.props.category.name,
                order: this.props.category.category_order
            })
        }
    }

    updateInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    changeCategoryOrder = (e) => {
        let category = this.props.categories.find((category) => category.category_order === Number(e.target.value))

        this.setState({
            order: Number(e.target.value),
            changeOrder: true,
            swapCategoryId: category.id
        })
    }

    updateCategory = async () => {
        try {
            let updateBtn = document.getElementById("admin_edit_featured_category_update_btn")
            updateBtn.innerHTML = ""
            updateBtn.classList.add("spinner")

            let categoryRes = await axios.put("/api/admin/update/featured-category", { 
                id: this.props.category.id, 
                name: this.state.name, 
                changeOrder: this.state.changeOrder, 
                swapCategoryId: this.state.swapCategoryId 
            })

            alert(categoryRes.data)

            updateBtn.innerHTML = "Update"
            updateBtn.classList.remove("spinner")

            this.setState({
                name: "",
                order: 0,
                changeOrder: false,
                swapCategoryId: 0
            }, async () => {
                await this.props.getFeaturedCategoriesAgain()
                this.props.changeToView()
            })
        }
        catch(err) {
            let updateBtn = document.getElementById("admin_edit_featured_category_update_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            updateBtn.innerHTML = "Update"
            updateBtn.classList.remove("spinner")
        }
    }

    toggleOrderReset = () => {
        this.setState({
            manualOrderReset: !this.state.manualOrderReset
        })
    }

    manuallyUpdateCategoryOrder = async () => {
        try {
            let updateBtn = document.getElementById("manually_update_featured_category_order_btn")
            updateBtn.innerHTML = ""
            updateBtn.classList.add("spinner")

            if( this.state.order > 2147483647 || this.state.order <= 0) {
                alert("Order must be above 0 and less than 2147483647")

                updateBtn.innerHTML = "Update Order"
                updateBtn.classList.remove("spinner")
            }
            else {
                let manualUpdateRes = await axios.put("/api/admin/update/featured-category/order", { id: this.props.category.id, order: this.state.order })

                alert(manualUpdateRes.data)

                updateBtn.innerHTML = "Update Order"
                updateBtn.classList.remove("spinner")

                await this.props.getFeaturedCategoriesAgain()
            }
        }
        catch(err) {
            let updateBtn = document.getElementById("manually_update_featured_category_order_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            updateBtn.innerHTML = "Update Order"
            updateBtn.classList.remove("spinner")
        }
    }

    render() {
        let categoryOrders = this.props.categories.map((category, i) => {
            return (
                <option key={i} value={category.category_order}>{category.category_order}</option>
            )
        })

        return (
            <div className="admin_edit_featured_category">
                <div className="admin_store_form_container">
                    <div className="admin_store_input_container">
                        <p>Name:</p>
                        <input type="text" 
                        name="name" 
                        placeholder="Name" 
                        value={this.state.name} 
                        onChange={this.updateInput} />
                    </div>
                    <div className="admin_store_input_container">
                        <p>Order:</p>
                        <select name="order" value={this.state.order} onChange={this.changeCategoryOrder}>
                            {categoryOrders}
                        </select>
                    </div>
                    <div className="admin_store_input_container">
                        <p>Featured category order not working correctly? Click the button below to manually set this featured category's order</p>
                        {
                            this.state.manualOrderReset
                            ?
                                <div className="admin_store_order_change_container">
                                    <button className="admin_store_order_reset_cancel_btn" onClick={this.toggleOrderReset}>X</button>
                                    <input type="number" 
                                    name="order" 
                                    placeholder="0" 
                                    value={this.state.order} 
                                    onChange={this.updateInput} />
                                    <button id="manually_update_featured_category_order_btn" 
                                    className="admin_store_spinner_container" 
                                    onClick={this.manuallyUpdateCategoryOrder}>Update Order</button>
                                </div>
                            :
                                <div className="admin_store_order_change_container">
                                    <button onClick={this.toggleOrderReset}>Set Order</button>
                                </div>
                        }
                    </div>
                </div>
                <div className="admin_store_btn_container">
                    <button onClick={this.props.changeToView}>Cancel</button>
                    <button id="admin_edit_featured_category_update_btn" 
                    className="admin_store_spinner_container" 
                    disabled={!this.state.name || !this.state.order} 
                    onClick={this.updateCategory}>Update</button>
                </div>
            </div>
        )
    }
}

export default AdminEditFeaturedCategory