import React, { Component } from "react"
import axios from "axios"

import "./AdminChangePassword.scss"

class AdminChangePassword extends Component {
    constructor(props) {
        super(props)

        const { REACT_APP_SERVER_HOST } = process.env

        this.state = {
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
            currentPasswordIcon: `${REACT_APP_SERVER_HOST}/PasswordVisibility/hide_password.png`,
            newPasswordIcon: `${REACT_APP_SERVER_HOST}/PasswordVisibility/hide_password.png`,
            confirmPasswordIcon: `${REACT_APP_SERVER_HOST}/PasswordVisibility/hide_password.png`,
            currentPasswordVisibility: "hide",
            newPasswordVisibility: "hide",
            confirmPasswordVisibility: "hide"
        }
    }

    showHideCurrentPassword = () => {
        const {
            REACT_APP_SERVER_HOST
        } = process.env

        let passwordInput = document.getElementById("change_password_current_password")

        if(this.state.currentPasswordVisibility === "hide") {
            passwordInput.type = "text"

            this.setState({
                currentPasswordVisibility: "show",
                currentPasswordIcon: `${REACT_APP_SERVER_HOST}/PasswordVisibility/show_password.png`
            })
        }
        else {
            passwordInput.type = "password"

            this.setState({
                currentPasswordVisibility: "hide",
                currentPasswordIcon: `${REACT_APP_SERVER_HOST}/PasswordVisibility/hide_password.png`
            })
        }
    }

    showHideNewPassword = () => {
        const {
            REACT_APP_SERVER_HOST
        } = process.env

        let passwordInput = document.getElementById("change_password_new_password")

        if(this.state.newPasswordVisibility === "hide") {
            passwordInput.type = "text"

            this.setState({
                newPasswordVisibility: "show",
                newPasswordIcon: `${REACT_APP_SERVER_HOST}/PasswordVisibility/show_password.png`
            })
        }
        else {
            passwordInput.type = "password"

            this.setState({
                newPasswordVisibility: "hide",
                newPasswordIcon: `${REACT_APP_SERVER_HOST}/PasswordVisibility/hide_password.png`
            })
        }
    }

    showHideConfirmPassword = () => {
        const {
            REACT_APP_SERVER_HOST
        } = process.env

        let passwordInput = document.getElementById("change_password_confirm_password")
 
        if(this.state.confirmPasswordVisibility === "hide") {
            passwordInput.type = "text"

            this.setState({
                confirmPasswordVisibility: "show",
                confirmPasswordIcon: `${REACT_APP_SERVER_HOST}/PasswordVisibility/show_password.png`
            })
        }
        else {
            passwordInput.type = "password"

            this.setState({
                confirmPasswordVisibility: "hide",
                confirmPasswordIcon: `${REACT_APP_SERVER_HOST}/PasswordVisibility/hide_password.png`
            })
        }
    }

    updateInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    changePassword = async () => {
        try {
            let changePasswordBtn = document.getElementById("admin_change_password_btn")
            changePasswordBtn.innerHTML = ""
            changePasswordBtn.classList.add("spinner")

            if(!this.state.currentPassword || !this.state.newPassword || !this.state.confirmPassword) {
                alert("Please fill out all password fields")

                changePasswordBtn.innerHTML = "Change password"
                changePasswordBtn.classList.remove("spinner")
            }
            else {
                if(this.state.newPassword === this.state.confirmPassword) {
                    let changePasswordRes = await axios.put("/auth-serv/admin/compare/change/password", { id: this.props.user.id, email: this.props.user.email, currentPassword: this.state.currentPassword, newPassword: this.state.newPassword})

                    alert(changePasswordRes.data)

                    changePasswordBtn.innerHTML = "Change password"
                    changePasswordBtn.classList.remove("spinner")

                    this.props.push()

                }
                else {
                    alert("New password and confirm password do not match")

                    changePasswordBtn.innerHTML = "Change password"
                    changePasswordBtn.classList.remove("spinner")
                }
            }
        }
        catch(err) {
            let changePasswordBtn = document.getElementById("admin_change_password_btn")
            changePasswordBtn.innerHTML = "Change password"
            changePasswordBtn.classList.remove("spinner")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    render() {
        return (
            <div className="admin_change_password_panel">
                <h3>
                    <b>Change Password</b>
                </h3>
                <div className="change_password_form_container">
                    <div className="change_password_input_container">
                        <p>Current password:</p>
                        <input type="password" 
                        name="currentPassword" 
                        id="change_password_current_password" 
                        value={this.state.currentPassword} 
                        onChange={this.updateInput}/>
                        <img src={this.state.currentPasswordIcon} alt="passwordIcon" onClick={this.showHideCurrentPassword}/>
                    </div>
                    <div className="change_password_input_container">
                        <p>New password:</p>
                        <input type="password" 
                        name="newPassword" 
                        id="change_password_new_password" 
                        value={this.state.newPassword} 
                        onChange={this.updateInput}/>
                        <img src={this.state.newPasswordIcon} alt="passwordIcon" onClick={this.showHideNewPassword}/>
                    </div>
                    <div className="change_password_input_container">
                        <p>Confirm password:</p>
                        <input type="password" 
                        name="confirmPassword" 
                        id="change_password_confirm_password" 
                        value={this.state.confirmPassword} 
                        onChange={this.updateInput}/>
                        <img src={this.state.confirmPasswordIcon} alt="passwordIcon" onClick={this.showHideConfirmPassword}/>
                    </div>
                    <div className="change_password_btn_container">
                        <button disabled={!this.state.currentPassword || !this.state.newPassword || !this.state.confirmPassword} 
                        id="admin_change_password_btn" 
                        onClick={this.changePassword}>Change password</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminChangePassword