import React from "react"

import AdminSectionCategory from "./Components/AdminSectionCategory/AdminSectionCategory"
import "./AdminViewStoreSectionCategories.scss"

let AdminViewStoreSectionCategories = (props) => {
    let categories = props.categories.map((category, i) => {
        return (
            <AdminSectionCategory key={i} category={category} changeToEdit={props.changeToEdit} getCategoriesAgain={props.getCategoriesAgain} changeToProducts={props.changeToProducts} />
        )
    })
    return (
        <div className="admin_view_store_section_categories">
            <div className="admin_view_store_section_categories_page_info_container">
                <h4>
                    Categories - page {props.pageNumber}
                </h4>
                <button className="admin_view_store_section_categories_new_btn" onClick={props.changeToAdd}>New</button>
            </div>
            <div className="admin_view_store_section_categories_categories_container">
                {categories}
            </div>
            <div className="admin_view_store_section_categories_btn_container">
                <div className="admin_view_store_section_categories_prev_next_container">
                    <button disabled={props.pageNumber === 1} onClick={props.previousPage}>Previous</button>
                    <button disabled={props.pageNumber === props.pages} onClick={props.nextPage}>Next</button>
                </div>
                <button className="admin_view_store_section_categories_sections_btn" onClick={props.changeToView}>Sections</button>
            </div>
        </div>
    )
}

export default AdminViewStoreSectionCategories