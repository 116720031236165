import React, { Component } from "react"
import axios from "axios"

import AdminViewStoreSectionCategories from "./Components/AdminViewStoreSectionCategories/AdminViewStoreSectionCategories"
import AdminAddStoreSectionCategories from "./Components/AdminAddStoreSectionCategories/AdminAddStoreSectionCategories"
import AdminEditStoreSectionCategory from "./Components/AdminEditStoreSectionCategories/AdminEditStoreSectionCategory"
import "./AdminStoreSectionCategories.scss"

class AdminStoreSectionCategories extends Component {
    constructor(props) {
        super(props)

        this.state = {
            panelType: "view",
            categories: [],
            editCategory: {},
            offset: 0,
            pageNumber: 1,
            pages: 0,
            allCategories: []
        }
    }

    async componentDidMount() {
        let categoriesRes = await axios.get(`/api/admin/get/initial/store-section-categories/${this.props.section.id}`)
        let allCategoriesRes = await axios.get(`/api/admin/get/all/store-section-categories/${this.props.section.id}`)

        let pages = categoriesRes.data[0] === 0 ? 1 : Math.ceil(categoriesRes.data[0] / 10)

        this.setState({
            pages: pages,
            categories: categoriesRes.data[1],
            pageNumber: 1,
            offset: 0,
            allCategories: allCategoriesRes.data
        })
    }

    getCategoriesAgain = async () => {
        let categoriesRes = await axios.get(`/api/admin/get/initial/store-section-categories/${this.props.section.id}`)
        let allCategoriesRes = await axios.get(`/api/admin/get/all/store-section-categories/${this.props.section.id}`)

        let pages = categoriesRes.data[0] === 0 ? 1 : Math.ceil(categoriesRes.data[0] / 10)

        this.setState({
            pages: pages,
            categories: categoriesRes.data[1],
            pageNumber: 1,
            offset: 0,
            allCategories: allCategoriesRes.data
        })
    }

    changeToView = () => {
        this.setState({
            panelType: "view"
        })
    }

    changeToAdd = () => {
        this.setState({
            panelType: "add"
        })
    }

    changeToEdit = (category) => {
        this.setState({
            panelType: "edit",
            editCategory: category
        })
    }

    previousPage = async () => {
        try {
            if(this.state.pageNumber === 1) {
                return
            }
            else {
                if(this.state.offset === 0) {
                    return
                }
                else {
                    let offset = this.state.offset - 10
        
                    let categoriesRes = await axios.get(`api/admin/get/store-section-categoires/${offset}/${this.props.section.id}`)
        
                    let pageNumber = this.state.pageNumber - 1
        
                    this.setState({
                        categories: categoriesRes.data,
                        offset: offset,
                        pageNumber: pageNumber
                    })
                }
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    nextPage = async () => {
        try {
            if(this.state.pageNumber === this.state.pages) {
                return
            }
            else {
                if(this.state.offset === (this.state.pages - 1) * 10) {
                    return
                }
                else {
                    let offset = this.state.offset + 10
        
                    let categoriesRes = await axios.get(`/api/admin/get/store-section-categoires/${offset}/${this.props.section.id}`)
        
                    let pageNumber = this.state.pageNumber + 1
        
                    this.setState({
                        categories: categoriesRes.data,
                        offset: offset,
                        pageNumber: pageNumber
                    })
                }
            }
        }
        catch(err) {
            if(err.reponse) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    render() {
        return (
            <div className="admin_store_section_categories">
                <h4>
                    <strong>{this.props.section.name} Categories:</strong>
                </h4>
                {
                    this.state.panelType === "view"
                    ?
                        <AdminViewStoreSectionCategories categories={this.state.categories} 
                        changeToAdd={this.changeToAdd} 
                        pageNumber={this.state.pageNumber} 
                        pages={this.state.pages} 
                        changeToView={this.props.changeToView} 
                        getCategoriesAgain={this.getCategoriesAgain} 
                        changeToEdit={this.changeToEdit} 
                        changeToProducts={this.props.changeToProducts} 
                        nextPage={this.nextPage} 
                        previousPage={this.previousPage} />
                    :
                    this.state.panelType === "add"
                    ?
                        <AdminAddStoreSectionCategories section={this.props.section} 
                        changeToView={this.changeToView} 
                        getCategoriesAgain={this.getCategoriesAgain} 
                        categoriesLength={this.state.allCategories.length} />
                    :
                        <AdminEditStoreSectionCategory section={this.props.section} 
                        category={this.state.editCategory} 
                        changeToView={this.changeToView} 
                        getCategoriesAgain={this.getCategoriesAgain} 
                        categories={this.state.allCategories} />
                }
            </div>
        )
    }
}

export default AdminStoreSectionCategories