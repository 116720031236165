import React from "react"
import axios from "axios"

import "./AdminGallery.scss"


let AdminGallery = (props) => {
    let changeToEdit = () => {
        props.changePanelType(props.gallery)
    }

    let deleteGalleries = async () => {
        try {
            let deleteGalleryBtn = document.getElementById(`admin_delete_gallery_btn_${props.gallery.id}`)
            deleteGalleryBtn.innerHTML = ""
            deleteGalleryBtn.classList.add("spinner")

            if(window.confirm("Are you sure you want to delete this gallery?")) {
                await axios.delete(`/api/admin/delete/gallery/old-images/${props.gallery.safe_name}`)
                let deleteGalleryRes = await axios.delete(`/api/admin/delete/gallery/${props.gallery.id}`)

                alert(deleteGalleryRes.data)

                deleteGalleryBtn.innerHTML = "Delete"
                deleteGalleryBtn.classList.remove("spinner")

                props.getGalleriesAgain()
            }
            else {
                deleteGalleryBtn.innerHTML = "Delete"
                deleteGalleryBtn.classList.remove("spinner")
            }
        }
        catch(err) {
            let deleteGalleryBtn = document.getElementById(`admin_delete_gallery_btn_${props.gallery.id}`)

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            deleteGalleryBtn.innerHTML = "Delete"
            deleteGalleryBtn.classList.remove("spinner")
        }
    }

    return (
        <div className="admin_media_gallery_container">
            <p>{props.gallery.name}</p>
            <div>
                <button onClick={changeToEdit}>Edit</button>
                <button id={`admin_delete_gallery_btn_${props.gallery.id}`} className="admin_delete_galleries_btn" onClick={deleteGalleries}>Delete</button>
            </div>
        </div>
    )
}

export default AdminGallery