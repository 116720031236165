import React, { Component } from "react"
import axios from "axios"

import AdminUser from "../AdminUser/AdminUser"
import "./AdminUsers.scss"

class AdminUsers extends Component {
    constructor(props) {
        super(props)

        const {
            REACT_APP_SERVER_HOST
        } = process.env

        this.state = {
            users: [],
            addUserEmail: "",
            emailValidation: true,
            addUserTempPassword: "",
            passwordVisibility: "hide",
            passwordIcon: `${REACT_APP_SERVER_HOST}/PasswordVisibility/hide_password.png`
        }

    }

    async componentDidMount() {
        try {
            let usersRes = await axios.get("/api/admin/get/users")
    
            let users = usersRes.data[0].filter((elem => elem.id !== usersRes.data[1]))

            this.setState({
                users: users
            })
            
        }
        catch(err) {
            if(err.response.status === 500) {
                alert(err.response.data)
            }
        }
    }

    showHidePassword = () => {
        const {
            REACT_APP_SERVER_HOST
        } = process.env

        let passwordInput = document.getElementById("add_user_password")

        if(this.state.passwordVisibility === "hide") {
            passwordInput.type = "text"

            this.setState({
                passwordVisibility: "show",
                passwordIcon: `${REACT_APP_SERVER_HOST}/PasswordVisibility/show_password.png`
            })
        }
        else {
            passwordInput.type = "password"

            this.setState({
                passwordVisibility: "hide",
                passwordIcon: `${REACT_APP_SERVER_HOST}/PasswordVisibility/hide_password.png`
            })
        }
    }

    updateInput = (e) => {
        if(e.target.name === "addUserEmail") {
            let emailFormat = /\S+@\S+\.\S+/

            if(e.target.value === "") {
                this.setState({
                    [e.target.name]: e.target.value,
                    emailValidation: true
                })
            }
            else {
                if(e.target.value.match(emailFormat)) {
                    this.setState({
                        [e.target.name]: e.target.value,
                        emailValidation: true
                    })
                }
                else {
                    this.setState({
                        [e.target.name]: e.target.value,
                        emailValidation: false
                    })
                }
            }
        }
        else {
            this.setState({
                [e.target.name]: e.target.value
            })
        }

    }

    addUser = async () => {
        try {
            let addUserBtn = document.getElementById("add_user_btn")
            addUserBtn.innerHTML = ""
            addUserBtn.classList.add("spinner")

            if(this.state.addUserEmail.length > 255 || this.state.addUserTempPassword > 255) {
                alert("Email and Password must be under 255 characters")

                addUserBtn.innerHTML = "Add User"
                addUserBtn.classList.remove("spinner")

                return
            }

            let addUserRes = await axios.post("/auth-serv/admin/add/user", {email: this.state.addUserEmail, password: this.state.addUserTempPassword})

            addUserBtn.innerHTML = "Add User"
            addUserBtn.classList.remove("spinner")
            
            alert(addUserRes.data)
        }
        catch(err) {
            let addUserBtn = document.getElementById("add_user_btn")
            
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
            
            addUserBtn.innerHTML = "Add User"
            addUserBtn.classList.remove("spinner")
        }
    }

    getUsersAgain = async (refreshStatus, id, email) => {
        try {
            let usersRes = await axios.get("/api/admin/get/users")

            let users = usersRes.data[0].filter((elem) => elem.id !== usersRes.data[1])
            this.setState({
                users: users
            })
        }
        catch(err) {
            alert(err.response.data)
        }
    }

    render() {
        let emailInputClass = this.state.emailValidation === true ? "email_input" : "email_input_error"
        let users = null
        if(this.state.users.length) {
            users = this.state.users.map((user, i) => {
                return (
                    <AdminUser key={i} user={user} getUsersAgain={this.getUsersAgain} />
                )
            })
        }
        return (
            <div className="admin_users_container">
                <div className="add_user_container">
                    {
                        this.state.emailValidation === true
                        ?
                            null
                        :
                            <div className="email_warning">
                                <p>Invalid email</p>
                            </div>
                    }
                    <h3 style={{"marginTop": "0"}}>
                        <b>Add new user</b>
                    </h3>
                    <div className="add_user_input">
                        <h4>
                            <b>Email:</b>
                        </h4>
                        <input type="text" 
                        className={emailInputClass}  
                        name="addUserEmail" 
                        placeholder="user@example.com" 
                        value={this.state.addUserEmail} 
                        onChange={this.updateInput}/>
                        <h4>
                            <b>Temporary password:</b>
                        </h4>
                        <div className="password_input">
                            <input type="password" 
                            id="add_user_password" 
                            name="addUserTempPassword" 
                            placeholder="Temporary password" 
                            value={this.state.addUserTempPassword} 
                            onChange={this.updateInput}/>
                            <img src={this.state.passwordIcon} alt="password_icon" onClick={this.showHidePassword}/>
                        </div>
                        <div className="admin_add_user_btn_container">
                            <button disabled={!this.state.emailValidation || !this.state.addUserTempPassword.length > 0} 
                            id="add_user_btn" 
                            onClick={this.addUser}>Add User</button>
                        </div>
                    </div>
                </div>
                    {
                        this.state.users.length
                        ?
                            <div className="current_users_container">
                                <h3>
                                    <b>Users</b>
                                </h3>
                                {users}
                            </div>
                        :
                            null
                    }
            </div>
        )
    }
}

export default AdminUsers