import React, { Component } from "react"
import axios from "axios"
import { Helmet } from "react-helmet"

import Navbar from "../../ReuseableComponents/Navbar/Navbar"
import FeaturedPost from "./FeaturedPost/FeaturedPost"
import "./Home.scss"

class Home extends Component {
    constructor(props) {
        super(props)

        this.state = {
            hovered: false,
            youtube: false,
            youtubeUrl: "",
            youtubeVideoId: "",
            youtubeTitle: "",
            youtubeDescription: "",
            posts: []
        }
    }

    async componentDidMount() {
        if(this.props.location.hash === "#/store") {
            this.props.history.push("/store")
        }
        else {
            try {
                const { REACT_APP_SERVER_HOST, REACT_APP_SITE_HOST } = process.env
                let homeInfo = await axios.get("/api/get/home/info")
    
                let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
                let match = homeInfo.data[0].url.match(regExp)
    
                let posts = homeInfo.data[1]
    
                let fakePost = {
                    featured_image: `${REACT_APP_SERVER_HOST}/Home/default.png`,
                    featured_title: "Not enough featured news",
                    featured_description: "Not enough featured news",
                    created_at: Date.now(),
                    link: `${REACT_APP_SITE_HOST}/`,
                    updated_at: Date.now(),
                    fake: true
                }
    
                if(posts.length === 0) {
                    posts.push(fakePost)
                    posts.push(fakePost)
                    posts.push(fakePost)
                }
                else if(posts.length === 1) {
                    posts.push(fakePost)
                    posts.push(fakePost)
                }
                else if(posts.length === 2) {
                    posts.push(fakePost)
                }
                else {
                }
    
                if(match && match[2].length >= 11) {
                    document.title = "Terraria"
    
                    this.setState({
                        youtubeUrl: homeInfo.data[0].url,
                        youtubeVideoId: match[2],
                        youtubeTitle: homeInfo.data[0].title,
                        youtubeDescription: homeInfo.data[0].description,
                        posts: homeInfo.data[1]
                    })
                }
                else {
                    document.title = "Terraria"
    
                    alert("Error embeding youtube video")
    
                    this.setState({
                        youtubeUrl: homeInfo.data[0].url,
                        youtubeTitle: homeInfo.data[0].title,
                        youtubeDescription: homeInfo.data[0].description
                    })
                }
            }
            catch(err) {
                if(err.response) {
                    if(err.response.data) {
                        alert(err.response.data)
                    }
                }
            }
        }
    }

    onMouseEnterHandler = () => {
        //Set hovered to true when hovering over image this controls the opacity of the div that contains the image/video
        this.setState({
            hovered: true
        })
    }

    onMouseLeaveHandler = () => {
        //Set hovered to false when user stops hovering over image this controls the opacity of the div that contains the image/video
        this.setState({
            hovered: false
        })
    }

    onPlayClick = () => {
        // Set youtube to true which controls the display property of the iframes element
        this.setState({
            youtube: true
        })
    }

    windowResize = () => {

    }

    render() {
        let iframesOpacity = this.state.hovered ? "1": "0.5"
        let youtubeDisplay = this.state.youtube ? "inline-block": "none"
        let youtubeDescriptionArr = this.state.youtubeDescription.replace(/\r\n|\r|\n/, "/newline").split("/newline")
        let youtubeDescription = youtubeDescriptionArr.map((text, i) => {
            return (
                <p key={i}>{text}</p>
            )
        })

        let featuredPosts = this.state.posts.length
        ?
            this.state.posts.map((post, i) => {
                return (
                    <FeaturedPost key={i} post={post} />
                )
            })
        :
            null
        return (
            <div className="main_wrapper">
                <Helmet>
                    <meta name="description" content="Dig, fight, explore, build! Nothing is impossible in this action-packed adventure game. The world is your canvas and the ground itself is your paint." />
                </Helmet>
                <div className="home">
                    <a href="/">
                        <div className="logo"></div>
                    </a>
                    <Navbar />
                    <div className="panel_dropshadow">
                        <div className="wall_grass_top" style={{"height": "8px"}}></div>
                        <div className="trailer_panel">
                            <div className="embedded_youtube trailer fading_image" 
                            onMouseEnter={this.onMouseEnterHandler} 
                            onMouseLeave={this.onMouseLeaveHandler}
                            onClick={this.onPlayClick}
                            style={{"opacity": iframesOpacity}}>
                                <iframe src={`https://www.youtube.com/embed/${this.state.youtubeVideoId}?feature=player_detailpage&autoplay=0&rel=0`} 
                                title="video" 
                                allowFullScreen 
                                frameBorder="0" 
                                className="embedded_youtube trailer home_iframe"
                                style={{"border": "none", "display": youtubeDisplay}}></iframe>
                            </div>
                            <div className="main_content_right">
                                <h3 id="dig_fight_build">
                                    <strong>{this.state.youtubeTitle}</strong>
                                </h3>
                                {youtubeDescription}
                                <a href="https://twitter.com/Terraria_Logic" className="social_media twitter">
                                    <span>Twitter</span>
                                </a>
                                <a href="https://www.facebook.com/TerrariaOfficial" className="social_media facebook">
                                    <span>Facebook</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="news_container">
                        {featuredPosts}
                    </div>
                    <br/>
                    <br/>
                </div>
            </div>
        )
    }
}

export default Home