import React from "react"
import axios from "axios"

import "./AdminFeaturedCategory.scss"

let AdminFeaturedCategory = (props) => {
    let deleteCategory = async () => {
        try {
            let deleteBtn = document.getElementById(`admin_featured_category_delete_btn_${props.category.id}`)
            deleteBtn.innerHTML = ""
            deleteBtn.classList.add("spinner")

            if(window.confirm("Are you sure you want to delete this category? This will NOT affect products in their original store section")) {
                let categoryRes = await axios.delete(`/api/admin/delete/featured-category/${props.category.id}`)

                alert(categoryRes.data)

                deleteBtn.innerHTML = "Delete"
                deleteBtn.classList.remove("spinner")

                await props.getFeaturedCategoriesAgain()
            }
        }
        catch(err) {
            let deleteBtn = document.getElementById(`admin_featured_category_delete_btn_${props.category.id}`)

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            deleteBtn.innerHTML = "Delete"
            deleteBtn.classList.remove("spinner")
        }
    }

    return (
        <div className="admin_store_list_item">
            <p>{props.category.name}</p>
            <div className="admin_store_list_item_btn_container">
                <button className="admin_featured_category_products_btn" onClick={() => props.changeToProducts(props.category)}>Products</button>
                <button onClick={() => props.changeToEdit(props.category)}>Edit</button>
                <button id={`admin_featured_category_delete_btn_${props.category.id}`} 
                className="admin_store_spinner_container" 
                onClick={deleteCategory}>Delete</button>
            </div>
        </div>
    )
}

export default AdminFeaturedCategory