import React, { Component } from "react"
import axios from "axios"

import AdminViewLinks from "./Components/AdminViewLinks/AdminViewLinks"
import AdminAddLink from "./Components/AdminAddLink/AdminAddLink"
import AdminEditLink from "./Components/AdminEditLink/AdminEditLink"
import "./AdminProductLinks.scss"

class AdminProductLinks extends Component {
    constructor(props) {
        super(props)

        this.state = {
            panelType: "view",
            links: [],
            editLink: {},
            offset: 0,
            pageNumber: 1,
            pages: 1,
            allLinks: []
        }
    }

    async componentDidMount() {
        let linksRes = await axios.get(`/api/admin/get/links/${this.props.product.id}`)
        let allLinksRes = await axios.get(`/api/admin/get/all/links/${this.props.product.id}`)

        let pages = linksRes.data[0] === 0 ? 1 : Math.ceil(linksRes.data[0] / 10)

        this.setState({
            pages: pages,
            links: linksRes.data[1],
            pageNumber: 1,
            offset: 0,
            allLinks: allLinksRes.data
        })
    }

    getLinksAgain = async () => {
        let linksRes = await axios.get(`/api/admin/get/links/${this.props.product.id}`)
        let allLinksRes = await axios.get(`/api/admin/get/all/links/${this.props.product.id}`)

        let pages = linksRes.data[0] === 0 ? 1 : Math.ceil(linksRes.data[0] / 10)

        this.setState({
            pages: pages,
            links: linksRes.data[1],
            pageNumber: 1,
            offset: 0,
            allLinks: allLinksRes.data
        })
    }

    changeToView = () => {
        this.setState({
            panelType: "view"
        })
    }

    changeToAdd = () => {
        this.setState({
            panelType: "add"
        })
    }

    changeToEdit = (link) => {
        this.setState({
            panelType: "edit",
            editLink: link
        })
    }

    previousPage = async () => {
        try {
            if(this.state.pageNumber === 1) {
                return
            }
            else {
                if(this.state.offset === 0) {
                    return
                }
                else {
                    let offset = this.state.offset - 10
        
                    let linksRes = await axios.get(`/api/admin/get/links/${offset}/${this.props.product.id}`)
        
                    let pageNumber = this.state.pageNumber - 1
        
                    this.setState({
                        links: linksRes.data,
                        offset: offset,
                        pageNumber: pageNumber
                    })
                }
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    nextPage = async () => {
        try {
            if(this.state.pageNumber === this.state.pages) {
                return
            }
            else {
                if(this.state.offset === (this.state.pages - 1) * 10) {
                    return
                }
                else {
                    let offset = this.state.offset + 10
        
                    let linksRes = await axios.get(`/api/admin/get/links/${offset}/${this.props.product.id}`)
        
                    let pageNumber = this.state.pageNumber + 1
        
                    this.setState({
                        links: linksRes.data,
                        offset: offset,
                        pageNumber: pageNumber
                    })
                }
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            } 
        }
    }


    render() {
        return (
            <div className="admin_product_links">
                {
                    this.state.panelType === "view"
                    ?
                        <AdminViewLinks links={this.state.links} 
                        changeToProducts={this.props.changeToProducts} 
                        changeToEdit={this.changeToEdit} 
                        category={this.props.category} 
                        pageNumber={this.state.pageNumber} 
                        pages={this.state.pages} 
                        changeToAdd={this.changeToAdd} 
                        nextPage={this.nextPage} 
                        previousPage={this.previousPage} 
                        getLinksAgain={this.getLinksAgain} />
                    :
                    this.state.panelType === "add"
                    ?
                        <AdminAddLink product={this.props.product} 
                        changeToView={this.changeToView}  
                        getLinksAgain={this.getLinksAgain} 
                        linksLength={this.state.allLinks.length} />
                    :
                        <AdminEditLink link={this.state.editLink} changeToView={this.changeToView} getLinksAgain={this.getLinksAgain} links={this.state.allLinks} />
                }
            </div>
        )
    }
}

export default AdminProductLinks