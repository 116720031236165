import React from "react"
import axios from "axios"

import "./AdminStoreSection.scss"

let AdminStoreSection = (props) => {
    let editStoreSection = () => {
        props.editStoreSection(props.section)
    }

    let deleteStoreSection = async () => {
        try {
            let deleteBtn = document.getElementById(`admin_store_section_delete_btn_${props.section.id}`)
            deleteBtn.innerHTML = ""
            deleteBtn.classList.add("spinner")

            if(window.confirm("Are you sure you want to delete this section? All categories and products associated with this section will also be deleted.")) {
                let storeSectionRes = await axios.delete(`/api/admin/delete/store-section/${props.section.id}/${props.section.header_path.split("StoreSectionHeaders/")[1]}`)
        
                alert(storeSectionRes.data)
    
                deleteBtn.innerHTML = "Delete"
                deleteBtn.classList.remove("spinner")
                
                props.getStoreSectionsAgain()
            }
            else {
                deleteBtn.innerHTML = "Delete"
                deleteBtn.classList.remove("spinner")
            }
        }
        catch(err) {
            let deleteBtn = document.getElementById(`admin_store_section_delete_btn_${props.section.id}`)

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            deleteBtn.innerHTML = "Delete"
            deleteBtn.classList.remove("spinner")
        }
    }

    let publishSection = async () => {
        try {
            let adminStoreSectionUnpublishBtn = document.getElementById(`admin_store_section_unpublished_btn_${props.section.id}`)
            adminStoreSectionUnpublishBtn.innerHTML = ""
            adminStoreSectionUnpublishBtn.classList.add("spinner")

            let publishRes = await axios.put("/api/admin/update/store-section/publish", { id: props.section.id, published: true })

            alert(publishRes.data)

            adminStoreSectionUnpublishBtn.innerHTML = "unpublished"
            adminStoreSectionUnpublishBtn.classList.remove("spinner")

            props.getStoreSectionsAgain()
        }
        catch(err) {
            let adminStoreSectionUnpublishBtn = document.getElementById(`admin_store_section_unpublished_btn_${props.section.id}`)

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            adminStoreSectionUnpublishBtn.innerHTML = "unpublished"
            adminStoreSectionUnpublishBtn.classList.remove("spinner")
        }
    }

    let unpublishSection = async () => {
        try {
            let adminStoreSectionPublishBtn = document.getElementById(`admin_store_section_published_btn_${props.section.id}`)
            adminStoreSectionPublishBtn.innerHTML = ""
            adminStoreSectionPublishBtn.classList.add("spinner")

            let publishRes = await axios.put("/api/admin/update/store-section/publish", { id: props.section.id, published: false })

            alert(publishRes.data)

            adminStoreSectionPublishBtn.innerHTML = "published"
            adminStoreSectionPublishBtn.classList.remove("spinner")

            props.getStoreSectionsAgain()
        }
        catch(err) {
            let adminStoreSectionPublishBtn = document.getElementById(`admin_store_section_published_btn_${props.section.id}`)

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            adminStoreSectionPublishBtn.innerHTML = "published"
            adminStoreSectionPublishBtn.classList.remove("spinner")
        }
    }

    let changeToCategories = () => {
        props.changeToCategories(props.section)
    }

    return (
        <div className="admin_single_store_section_container admin_store_list_item">
            <p>{props.section.name}</p>
            <div className="admin_store_list_item_btn_container">
                <button className="admin_store_section_categories_btn" onClick={changeToCategories}>Categories</button>
                <button onClick={editStoreSection}>Edit</button>
                <button id={`admin_store_section_delete_btn_${props.section.id}`} className="admin_store_section_delete_btn" onClick={deleteStoreSection}>Delete</button>
                {
                    props.section.published
                    ?
                        <button id={`admin_store_section_published_btn_${props.section.id}`} className="admin_store_section_published_btn" onClick={unpublishSection}>published</button>
                    :
                        <button id={`admin_store_section_unpublished_btn_${props.section.id}`} className="admin_store_section_unpublished_btn" onClick={publishSection}>unpublished</button>
                }
            </div>
        </div>
    )
}

export default AdminStoreSection