import React, { Component } from "react"
import axios from "axios"

import Navbar from "../../ReuseableComponents/Navbar/Navbar"
import "./SingleNews.scss"

class SingleNews extends Component {
    constructor(props) {
        super(props)

        this.state = {
            post: {},
            linkStyle: {"right": "85px"},
            linkInputStyle: {"width": "0", "opacity": "0"},
            link: window.location
        }

        this.linkInput = React.createRef()
    }

    async componentDidMount() {
        try {
            document.title = "News - Terraria"
            
            let { title } = this.props.match.params
    
            let postRes = await axios.get(`/api/get/single-news/${title}`)
    
            if(postRes.data.title) {
                this.setState({
                    post: postRes.data
                }, () => {
                    let body = document.getElementById("single_news_body")
                    body.innerHTML = this.state.post.body
                })
            }
            else {
                alert("Error getting post info please try again later")
                this.props.history.push("/")
            }
        }
        catch(err) {
            if(err.response) {
                if(err.resposne.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    expandCloseLink = () => {
        if (this.state.linkStyle.right === "85px") {
            this.linkInput.current.focus()
            this.setState({
                linkStyle: {"right": "385px"},
                linkInputStyle: {"width": "300px", "opacity": "0.7"}
            })
        }
        else {
            this.setState({
                linkStyle: {"right": "85px"},
                linkInputStyle: {"width": "0", "opacity": "0"}
            })
        }
    }

    inputFocus = () => {
        this.linkInput.current.select()
    }

    inputClick = () =>  {
        this.linkInput.current.select()
    }

    twitterClick = () => {
        let url = encodeURIComponent(`${this.state.post.link}`)
        let windowStyle = "height=300,width=600"
        window.open(`http://twitter.com/share?url=${url}&via=Terraria_Logic`, "", windowStyle)
    }
    
    updateInput = (e) => {
        this.setState({
            link: e.target.value
        })
    }
    
    facebookClick = () => {
        let windowStyle = "height=300,width=600"
        window.open(`http://www.facebook.com/sharer/sharer.php?u=${this.state.post.link}`, "", windowStyle)
    }

    render() {
        let createdDate = new Date(this.state.post.created_at)
        let updatedDate = new Date(this.state.post.updated_at)
        let createdHours = (createdDate.getHours() + 1) > 12 ? (createdDate.getHours() + 1) - 12 : createdDate.getHours() + 1
        let updatedHours = (updatedDate.getHours() + 1) > 12 ? (updatedDate.getHours() + 1) - 12 : updatedDate.getHours() + 1
        let createdSeconds = (createdDate.getSeconds() + 1) < 10 ? `0${createdDate.getSeconds() + 1}` : createdDate.getSeconds() + 1
        let updatedSeconds = (updatedDate.getSeconds() + 1) < 10 ? `0${updatedDate.getSeconds() + 1}` : updatedDate.getSeconds() + 1
        let createdPeriod = createdDate.getHours() + 1 > 11 ? "PM" : "AM"
        let updatedPeriod = updatedDate.getHours() + 1 > 11 ? "PM" : "AM"
        return (
            <div className="main_wrapper">
                <div className="single_news_panel">
                    <a href="/">
                        <div className="logo"></div>
                    </a>
                    <Navbar />
                    <div className="panel_dropshadow" style={{"zIndex": "0"}}>
                        <article className="wall">
                            <div className="wall_grass_top"></div>
                            <header className="wall_header">
                                <h3>
                                    <b>{this.state.post.title}</b>
                                </h3>
                                <b>by {this.state.post.author}</b>
                            </header>
                            <div className="wall_background">
                                <div className="wall_fade_in"></div>
                                <div id="single_news_body" className="wall_content"></div>
                                <div className="wall_fade_out"></div>
                                <div className="wall_footer">
                                    <div className="wall_footer_timestamp">
                                        <p>Last updated on:</p>
                                        {
                                            this.state.post.updated_at
                                            ?
                                                <p className="utc_date_time">
                                                    {updatedDate.getUTCMonth() + 1}
                                                    /{updatedDate.getUTCDate()}
                                                    /{updatedDate.getUTCFullYear()}
                                                    , {updatedHours}
                                                    :{updatedDate.getMinutes() + 1}
                                                    :{updatedSeconds} {updatedPeriod}
                                                </p>
                                            :
                                                <p className="utc_date_time">
                                                    {createdDate.getUTCMonth() + 1}
                                                    /{createdDate.getUTCDate()}
                                                    /{createdDate.getUTCFullYear()}
                                                    , {createdHours}
                                                    :{createdDate.getMinutes() + 1}
                                                    :{createdSeconds} {createdPeriod}
                                                </p>
                                        }
                                    </div>
                                    <div className="post_link social_media" style={this.state.linkStyle} onClick={this.expandCloseLink}></div>
                                    <input type="text" 
                                    className="post_link_text" 
                                    value={this.state.link} 
                                    style={this.state.linkInputStyle} 
                                    ref={this.linkInput} 
                                    onFocus={this.inputFocus} 
                                    onClick={this.inputClick} 
                                    onChange={this.updateInput}/>
                                    <div className="twitter social_media" onClick={this.twitterClick}></div>
                                    <div className="facebook social_media"  onClick={this.facebookClick}></div>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        )
    }
}

export default SingleNews