import React, { Component } from "react"

import "react-quill/dist/quill.snow.css"
import "./NewsPost.scss"

class NewsPost extends Component {
    constructor(props) {
        super(props)

        this.state = {
            linkStyle: {"right": "85px"},
            linkInputStyle: {"width": "0", "opacity": "0"},
            link: ""
        }

        this.linkInput = React.createRef()
    }

    componentDidMount() {
        let post = this.props.post
        let body = document.getElementById(`news_post_${post.id}`)
        body.innerHTML = post.body
        this.setState({
            link: post.link
        })
    }

    componentDidUpdate(prevProps) {
        if(this.props.post.id !== prevProps.post.id) {
            let post = this.props.post
            let body = document.getElementById(`news_post_${post.id}`)
            body.innerHTML = post.body
            this.setState({
                link: post.link
            })
        }
    }

    twitterClick = () => {
        let url = encodeURIComponent(`${this.state.link}`)
        let windowStyle = "height=300,width=600"
        window.open(`http://twitter.com/share?url=${url}&via=Terraria_Logic`, "", windowStyle)
    }

    facebookClick = () => {
        let windowStyle = "height=300,width=600"
        window.open(`http://www.facebook.com/sharer/sharer.php?u=${this.state.link}`, "", windowStyle)
    }

    expandCloseLink = () => {
        if (this.state.linkStyle.right === "85px") {
            this.linkInput.current.focus()
            this.setState({
                linkStyle: {"right": "385px"},
                linkInputStyle: {"width": "300px", "opacity": "0.7"}
            })
        }
        else {
            this.setState({
                linkStyle: {"right": "85px"},
                linkInputStyle: {"width": "0", "opacity": "0"}
            })
        }
    }

    inputFocus = () => {
        this.linkInput.current.select()
    }

    inputClick = () =>  {
        this.linkInput.current.select()
    }

    updateInput = (e) => {
        this.setState({
            link: e.target.value
        })
    }

    render() {
        let post = this.props.post
        let createdDate = new Date(post.created_at)
        let updatedDate = new Date(post.updated_at)
        let createdHours = (createdDate.getHours() + 1) > 12 ? (createdDate.getHours() + 1) - 12 : createdDate.getHours() + 1
        let updatedHours = (updatedDate.getHours() + 1) > 12 ? (updatedDate.getHours() + 1) - 12 : updatedDate.getHours() + 1
        let createdSeconds = (createdDate.getSeconds() + 1) < 10 ? `0${createdDate.getSeconds() + 1}` : createdDate.getSeconds() + 1
        let updatedSeconds = (updatedDate.getSeconds() + 1) < 10 ? `0${updatedDate.getSeconds() + 1}` : updatedDate.getSeconds() + 1
        let createdPeriod = (createdDate.getHours() + 1) > 11 ? "PM" : "AM"
        let updatedPeriod = (updatedDate.getHours() + 1) > 11 ? "PM" : "AM"
        return (
            <div className="panel_dropshadow single_news_post">
                <article className="wall">
                    <div className="wall_grass_top"></div>
                    <header className="wall_header" id={`news_post_header_${post.id}`}>
                        <h3>
                            <b>{post.title}</b>
                        </h3>
                        <b className="post_author">by {post.author}</b>
                        <div className="up_arrow social_media" onClick={this.props.scrollToTop}></div>
                    </header>
                    <div className="wall_background">
                        <div className="wall_fade_in"></div>
                        <div id={`news_post_${post.id}`} className="wall_content ql-editor"></div>
                        <div className="wall_fade_out"></div>
                        <div className="wall_footer">
                            <div className="wall_footer_timestamp">
                                <p>Last updated on:</p>
                                {
                                    post.updated_at
                                    ?
                                        <p className="utc_date_time">
                                            {updatedDate.getUTCMonth() + 1}
                                            /{updatedDate.getUTCDate()}
                                            /{updatedDate.getUTCFullYear()}
                                            , {updatedHours}
                                            :{updatedDate.getMinutes() + 1}
                                            :{updatedSeconds} {updatedPeriod}
                                        </p>
                                    :
                                        <p className="utc_date_time">
                                            {createdDate.getUTCMonth() + 1}
                                            /{createdDate.getUTCDate()}
                                            /{createdDate.getUTCFullYear()}
                                            , {createdHours}
                                            :{createdDate.getMinutes() + 1}
                                            :{createdSeconds} {createdPeriod}
                                        </p>
                                }
                            </div>
                            <div className="post_link social_media" style={this.state.linkStyle} onClick={this.expandCloseLink}></div>
                            <input type="text" 
                            className="post_link_text" 
                            value={this.state.link} 
                            style={this.state.linkInputStyle} 
                            ref={this.linkInput} 
                            onFocus={this.inputFocus} 
                            onClick={this.inputClick} 
                            onChange={this.updateInput}/>
                            <div className="twitter social_media" onClick={this.twitterClick}></div>
                            <div className="facebook social_media"  onClick={this.facebookClick}></div>
                        </div>
                    </div>
                </article>
            </div>
        )

    }
}

export default NewsPost