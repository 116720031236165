import React, { Component } from "react"
import axios from "axios"

import AdminStoreSectionHeaderPreviewModel from "../AdminStoreSectionHeaderPreviewModel/AdminStoreSectionHeaderPreviewModel"
import "./AdminEditStoreSection.scss"

class AdminEditStoreSection extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: "",
            storeSectionHeader: "",
            storeSectionFileName: "No file selected.",
            deleteImgName: "",
            oldImgName: "",
            previewShow: false,
            order: 0,
            changeOrder: false,
            swapSectionId: 0,
            manualOrderReset: false
        }
    }

    componentDidMount() {
        let fileName = this.props.section.header_path.split("/")[2].split(".")[0]
        let fileType = this.props.section.header_path.split("/")[2].split(".")[1]
        if(fileName.includes("-0x")) {
            fileName = `${fileName.split("-0x")[0]}.${fileType}`
        }
        this.setState({
            name: this.props.section.name,
            storeSectionHeader: this.props.section.header_path,
            storeSectionFileName: fileName,
            oldImgName: this.props.section.header_path.split("/")[2],
            order: this.props.section.section_order
        })
    }

    componentDidUpdate(prevProps) {
        if(this.props.section.id !== prevProps.section.id) {
            let fileName = this.props.section.header_path.split("/")[2].split(".")[0]
            if(fileName.includes("-0x")) {
                fileName = fileName.split("-0x")[0]
            }
            this.setState({
                name: this.props.section.name,
                storeSectionHeader: this.props.section.header_path,
                storeSectionFileName: fileName,
                deleteImgName: this.props.section.header_path.split("/")[2],
                order: this.props.section.section_order
            })
        }
    }

    updateInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    updateHeaderImage = async (e) => {
        try {
            const files = e.target.files

            if(files.length > 1) {
                alert("You can only upload one image")
            }
            else {
                if(files[0].type === "image/png" || files[0].type === "image/bmp" || files[0].type === "image/jpeg" || files[0].type === "image/jpg" || files[0].type === "image/gif" || files[0].type === "image/x-icon") {
                    let blob = files[0].slice(0, files[0].size, files[0].type)
                    let newFile = new File([blob], files[0].name.replace(/\s/g, "-"), {type: files[0].type})
                    const formData = new FormData()
                    formData.append("headerImage", newFile)

                    if(this.state.deleteImgName) {
                        await axios.delete(`/api/admin/delete/store-section/header-image/${this.state.deleteImgName}`)
                    }
                    let headerImgRes = await axios.post("/api/admin/add/store-section-header", formData)

                    e.target.value = null

                    this.setState({
                        storeSectionHeader: headerImgRes.data[0],
                        storeSectionFileName: headerImgRes.data[1],
                        deleteImgName: headerImgRes.data[2]
                    })
                }
                else {
                    alert("Image file must be a png, bmp, jpeg or jpg image")
                }
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    updateStoreSection = async () => {
        try {
            let editStoreSectionBtn = document.getElementById("admin_edit_store_section_edit_btn")
            editStoreSectionBtn.innerHTML = ""
            editStoreSectionBtn.classList.add("spinner")

            if(this.state.name.length && this.state.storeSectionHeader) {
                let storeSectionRes = await axios.put("/api/admin/update/store-section", { id: this.props.section.id, name: this.state.name, header: this.state.storeSectionHeader, changeOrder: this.state.changeOrder, swapSectionId: this.state.swapSectionId })

                if(this.state.deleteImgName.length) {
                    if(this.state.oldImgName !== this.state.deleteImgName) {
                        await axios.delete(`/api/admin/delete/store-section/header-image/${this.state.oldImgName}`)
                    }
                }

                alert(storeSectionRes.data)

                editStoreSectionBtn.innerHTML = "Edit"
                editStoreSectionBtn.classList.remove("spinner")

                this.setState({
                    name: "",
                    storeSectionHeader: "",
                    storeSectionFileName: "No file selected.",
                    deleteImgName: "",
                    oldImgName: "",
                    previewShow: false,
                    order: 0,
                    changeOrder: false,
                    swapSectionId: 0
                }, async () => {
                    await this.props.getStoreSectionsAgain()
                    this.props.changeToView()
                })
            }
        }
        catch(err) {
            let editStoreSectionBtn = document.getElementById("admin_edit_store_section_edit_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            editStoreSectionBtn.innerHTML = "Edit"
            editStoreSectionBtn.classList.remove("spinner")
        }
    }

    openClosePreview = () => {
        if(this.state.storeSectionHeader) {
            this.setState({
                previewShow: !this.state.previewShow
            })
        }
        else {
            alert("You must have a header image to preview")
        }
    }

    changeSectionOrder = (e) => {
        let section = this.props.sections.find((section) => section.section_order === Number(e.target.value))

        this.setState({
            order: Number(e.target.value),
            changeOrder: true,
            swapSectionId: section.id
        })
    }

    toggleOrderReset = () => {
        this.setState({
            manualOrderReset: !this.state.manualOrderReset
        })
    }

    manuallyUpdateSectionOrder = async () => {
        try {
            let updateBtn = document.getElementById("manually_update_section_order_btn")
            updateBtn.innerHTML = ""
            updateBtn.classList.add("spinner")

            if( this.state.order > 2147483647 || this.state.order <= 0) {
                alert("Order must be above 0 and less than 2147483647")

                updateBtn.innerHTML = "Update Order"
                updateBtn.classList.remove("spinner")
            }
            else {
                let manualUpdateRes = await axios.put("/api/admin/update/store-section/order", { id: this.props.section.id, order: this.state.order })

                alert(manualUpdateRes.data)

                updateBtn.innerHTML = "Update Order"
                updateBtn.classList.remove("spinner")

                await this.props.getStoreSectionsAgain()
            }
        }
        catch(err) {
            let updateBtn = document.getElementById("manually_update_section_order_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            updateBtn.innerHTML = "Update Order"
            updateBtn.classList.remove("spinner")
        }
    }

    render() {
        let orders = this.props.sections.map((section, i) => {
            return (
                <option key={i} value={section.section_order}>{section.section_order}</option>
            )
        })
        return (
            <div className="admin_edit_store_section">
                <h4>
                    <strong>Edit Store Section:</strong>
                </h4>
                <div className="admin_store_form_container">
                    <div className="admin_store_input_container">
                        <p>Name:</p>
                        <input type="text" 
                        name="name" 
                        placeholder="name" 
                        value={this.state.name} 
                        onChange={this.updateInput}/>
                    </div>
                    <div className="admin_store_input_container">
                        <p>Header Image (970 x 78):</p>
                        <div className="admin_store_file_upload_container">
                            <input type="file" 
                            accept="image/*" 
                            onChange={this.updateHeaderImage}/>
                            <p>{this.state.storeSectionFileName}</p>
                        </div>
                    </div>
                    <div className="admin_store_input_container">
                        <p>Order:</p>
                        <select name="order" value={this.state.order} onChange={this.changeSectionOrder}>
                            {orders}
                        </select>
                    </div>
                    <div className="admin_store_input_container">
                        <p>Section order not working correctly? Click the button below to manually set this section's order</p>
                        {
                            this.state.manualOrderReset
                            ?
                                <div className="admin_store_order_change_container">
                                    <button className="admin_store_order_reset_cancel_btn" onClick={this.toggleOrderReset}>X</button>
                                    <input type="number" 
                                    name="order" 
                                    placeholder="0" 
                                    value={this.state.order} 
                                    onChange={this.updateInput} />
                                    <button id="manually_update_section_order_btn" 
                                    className="admin_store_spinner_container" 
                                    onClick={this.manuallyUpdateSectionOrder}>Update Order</button>
                                </div>
                            :
                                <div className="admin_store_order_change_container">
                                    <button onClick={this.toggleOrderReset}>Set Order</button>
                                </div>
                        }
                    </div>
                </div>
                <div className="admin_edit_store_section_btn_container">
                    <button className="admin_edit_store_section_cancel_btn" onClick={this.props.changeToView}>Cancel</button>
                    <div className="admin_edit_store_section_preview_add_container">
                        <button onClick={this.openClosePreview} disabled={!this.state.storeSectionHeader.length}>Preview</button>
                        <button id="admin_edit_store_section_edit_btn" 
                        disabled={!this.state.name.length || !this.state.storeSectionHeader.length} 
                        onClick={this.updateStoreSection}>Update</button>
                    </div>
                </div>
                <AdminStoreSectionHeaderPreviewModel show={this.state.previewShow} openClosePreview={this.openClosePreview} storeSectionHeader={this.state.storeSectionHeader} />
            </div>
        )
    }
}

export default AdminEditStoreSection