import React, { Component } from "react"
import { Redirect } from "react-router-dom"
import axios from "axios"

import Navbar from "../../ReuseableComponents/Navbar/Navbar"

import "./AdminUnlock.scss"

class AdminUnlock extends Component {
    constructor(props) {
        super(props)

        const {
            REACT_APP_SERVER_HOST
        } = process.env

        this.state = {
            pageStatus: "loading",
            user: {},
            password: "",
            confirmPassword: "",
            passwordIcon: `${REACT_APP_SERVER_HOST}/PasswordVisibility/hide_password.png`,
            confirmPasswordIcon: `${REACT_APP_SERVER_HOST}/PasswordVisibility/hide_password.png`,
            passwordVisibility: "hide",
            confirmPasswordVisibility: "hide" ,
            err: false,
            errMessage: "",
        }
    }

    async componentDidMount() {
        try {
            const urlParams = new URLSearchParams(this.props.location.search)
    
            let email = urlParams.get("email")
            let token = urlParams.get("token")
    
            if(email === null || token === null) {
                this.setState({
                    pageStatus: "redirect"
                })
            }
            else {
                let unlockRes = await axios.put("/auth-serv/admin/check/unlock", { email: email, token: token })
                this.setState({
                    pageStatus: "authorized",
                    user: unlockRes.data
                })
            }
        }
        catch(err) {
            if(err.response.status) {
                if(err.response.status === 401 || err.response.status === 403 || err.response.status === 500) {
                    if(err.response.data) {
                        alert(err.response.data)
                    }
                    this.setState({
                        pageStatus: "redirect"
                    })
                }
            }
            
        }
    }

    updateInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    showHidePassword = () => {
        const {
            REACT_APP_SERVER_HOST
        } = process.env

        let passwordInput = document.getElementById("unlock_user_password")

        if(this.state.passwordVisibility === "hide") {
            passwordInput.type = "text"

            this.setState({
                passwordVisibility: "show",
                passwordIcon: `${REACT_APP_SERVER_HOST}/PasswordVisibility/show_password.png`
            })
        }
        else {
            passwordInput.type = "password"

            this.setState({
                passwordVisibility: "hide",
                passwordIcon: `${REACT_APP_SERVER_HOST}/PasswordVisibility/hide_password.png`
            })
        }
    }

    showHideConfirmPassword = () => {
        const {
            REACT_APP_SERVER_HOST
        } = process.env

        let passwordInput = document.getElementById("unlock_user_confirm_password")

        if(this.state.confirmPasswordVisibility === "hide") {
            passwordInput.type = "text"

            this.setState({
                confirmPasswordVisibility: "show",
                confirmPasswordIcon: `${REACT_APP_SERVER_HOST}/PasswordVisibility/show_password.png`
            })
        }
        else {
            passwordInput.type = "password"

            this.setState({
                confirmPasswordVisibility: "hide",
                confirmPasswordIcon: `${REACT_APP_SERVER_HOST}/PasswordVisibility/hide_password.png`
            })
        }
    }

    UnlockAccount = async () => {
        try {
            if(!this.state.password || !this.state.confirmPassword) {
                alert("You must fill out all fields")
            }
            else {
                if(this.state.password === this.state.confirmPassword) {
                    let unlockRes = await axios.put("/auth-serv/admin/unlock/user", { id: this.state.user.id, 
                        email: this.state.user.email, 
                        password: this.state.password
                    })

                    alert(unlockRes.data)

                    this.props.history.push("/auth/sign_in")
                }
                else {
                    this.setState({
                        err: true,
                        errMessage: "Passwords do not match"
                    })
                }
            }
        }
        catch(err) {
            if(err.response.data) {
                alert(err.response.data)
            }
        }
    }

    render() {
        return (
            this.state.pageStatus === "loading"
            ?
                <div></div>
            :
            this.state.pageStatus === "authorized"
            ?
                <div className="main_wrapper">
                    <div>
                        <a href="/">
                            <div className="logo"></div>
                        </a>
                        <Navbar />
                        <div className="panel_dropshadow">
                            <div className="admin_unlock_panel">
                                <div className="wall_grass_top"></div>
                                <header>
                                    <h3 className="admin_unlock_header" style={{"margin": "0"}}>
                                        <b>Unlock your account</b>
                                    </h3>
                                </header>
                                <div className="admin_unlock_content">
                                    <h4 style={{"margin": "0"}}>
                                        <b>Reset your password In order to unlock your account</b>
                                    </h4>
                                    <div className="admin_unlock_form_container">
                                        {
                                            this.state.err
                                            ?
                                                <div className="admin_unlock_err_container">
                                                    <p>{this.state.errMessage}</p>
                                                </div>
                                            :
                                                null
                                        }
                                        <div>
                                            <p style={{"marginBottom": "0"}}>New Password:</p>
                                            <input type="password" 
                                            name="password" 
                                            id="unlock_user_password" 
                                            placeholder="Password" 
                                            value={this.state.password} 
                                            onChange={this.updateInput}/>
                                            <img src={this.state.passwordIcon} alt="password_icon" onClick={this.showHidePassword}/>
                                        </div>
                                        <div>
                                            <p style={{"marginBottom": "0"}}>Confirm Pasword:</p>
                                            <input type="password" 
                                            name="confirmPassword" 
                                            id="unlock_user_confirm_password" 
                                            placeholder="Confirm Password" 
                                            value={this.state.confirmPassword} 
                                            onChange={this.updateInput}/>
                                            <img src={this.state.confirmPasswordIcon} alt="password_icon" onClick={this.showHideConfirmPassword}/>
                                        </div>
                                        <div className="admin_unlock_btn_container">
                                            <button style={{"width": "120px"}} disabled={!this.state.password || !this.state.confirmPassword} onClick={this.UnlockAccount}>Unlock Account</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="wall_fade_out"></div>
                                <div className="wall_footer_none"></div>
                            </div>
                        </div>
                    </div>
                </div>
            :
                <Redirect to={{pathname: "/"}}/>
        )
    }
}

export default AdminUnlock