import React from "react"
import { Link } from "react-router-dom"

import "./FeaturedPost.scss"

let FeaturedPost = (props) => {
    const { REACT_APP_SERVER_HOST } = process.env
    let link = props.post.fake ? `${props.post.link}` : `/news/${props.post.link.split("news/")[1]}`
    return (
            <div className="panel_dropshadow news_block_shadow">
                <div className="news_block_grass"></div>
                <div className="news_block">
                    <Link className="news_block_image_container" to={link}>
                        <img className="fading_image" src={`${REACT_APP_SERVER_HOST}/Home/yellow_edge.png`} alt="featured_img" style={{"backgroundImage": `url(${REACT_APP_SERVER_HOST}${props.post.featured_image})`}}/>
                    </Link>
                    <h4 id={props.post.featured_title}>
                        <strong>{props.post.featured_title}</strong>
                    </h4>
                    <p>{props.post.featured_description}</p>
                    <Link className="read_more" to={link}>
                        <img src={`${REACT_APP_SERVER_HOST}/Home/read_more.png`} alt="read-more" width="18" height="19"/>
                        <p>Read More</p>
                    </Link>
                    <p className="news_date">
                        {new Date(props.post.created_at).toLocaleString("default", { month: "long" })} {new Date(props.post.created_at).getDate()}, {new Date(props.post.created_at).getFullYear()}
                    </p>
                </div>
            </div>
    )
}

export default FeaturedPost