import React, { useState } from "react"

import AdminContainer from "./Components/AdminContainer/AdminContainer"
import AdminAllTilesPreview from "./Components/AdminAllTilesPreview/AdminAllTilesPreview"
import "./AdminViewContainers.scss"

let AdminViewContainers = (props) => {
    const [show, setShow] = useState(false)

    let openClosePreview = () => {
        setShow(!show)
    }

    let containers = props.containers.map((container, i) => {
        return (
            <AdminContainer key={i} 
            container={container} 
            changeToEdit={props.changeToEdit} 
            getContainersAgain={props.getContainersAgain} 
            changeToTiles={props.changeToTiles} />
        )
    })
    return (
        <div className="admin_view_containers">
            <div className="admin_view_containers_page_info">
                <h4>
                    Containers Page - {props.pageNumber}
                </h4>
                <button onClick={props.changeToAdd}>New</button>
            </div>
            <div className="admin_view_containers_containers_container">
                {containers}
            </div>
            <div className="admin_view_containers_previous_next_container">
                <button disabled={props.pageNumber === 1} onClick={props.previousPage}>Previous</button>
                <button disabled={props.pages === props.pageNumber} onClick={props.nextPage}>Next</button>
            </div>
            <div className="admin_view_containers_preview_all_btn_container">
                <button onClick={openClosePreview}>Preview All Tiles</button>
            </div>
            <AdminAllTilesPreview show={show} openClosePreview={openClosePreview} />
        </div>
    )
}

export default AdminViewContainers