import React, { Component } from "react"
import axios from "axios"
import { Redirect } from "react-router-dom"

import Navbar from "../../ReuseableComponents/Navbar/Navbar"
import AdminHome from "./Components/AdminHome/AdminHome"
import AdminPosts from "./Components/AdminPosts/AdminPosts"
import AdminMedia from "./Components/AdminMedia/AdminMedia"
import AdminStore from "./Components/AdminStore/AdminStore"
import AdminUsers from "./Components/AdminUsers/AdminUsers"
import AdminChangePassword from "./Components/AdminChangePassword/AdminChangePassword"
import "./AdminMain.scss"

class AdminMain extends Component {
    constructor(props) {
        super(props)

        this.state = {
            pageStatus: "loading",
            user: {},
            selectedTab: "home"
        }
    }

    async componentDidMount() {
        try {
            document.title = "Admin - Terraria"
            let loggedInRes = await axios.get("/auth-serv/admin/check")

            this.setState({
                pageStatus: loggedInRes.data.result ? "loggedIn" : "redirect",
                user: loggedInRes.data.user
            })
        }
        catch(err) {
            if(err.response.status === 401) {
                this.setState({
                    pageStatus: "redirect"
                })
            }
        }
    }

    changeView = (e) => {
        this.setState({
            selectedTab: e.target.innerText.toLowerCase()
        })
    }

    pushToSignIn = () => {
        this.props.history.push("/auth/sign_in")
    }

    render() {
        let homeClass = this.state.selectedTab === "home" ? "admin_active admin_nav_btn admin_nav_btn_home" : "admin_nav_btn admin_nav_btn_home"
        let postsClass = this.state.selectedTab === "posts" ? "admin_active admin_nav_btn admin_nav_btn_posts" : "admin_nav_btn admin_nav_btn_ posts"
        let mediaClass = this.state.selectedTab === "media" ? "admin_active admin_nav_btn admin_nav_btn_media" : "admin_nav_btn admin_nav_btn_media"
        let storeClass = this.state.selectedTab === "store" ? "admin_active admin_nav_btn admin_nav_btn_store" : "admin_nav_btn admin_nav_btn_store"
        let usersClass = this.state.selectedTab === "users" ? "admin_active admin_nav_btn admin_nav_btn_users" : "admin_nav_btn admin_nav_btn_users"
        let changePasswordClass = this.state.selectedTab === "change password" ? "admin_active admin_nav_btn admin_nav_btn_change_passowrd": "admin_nav_btn admin_nav_btn_change_passowrd"
        return (
                this.state.pageStatus === "loading" 
                ?
                    <div></div>
                :
                    this.state.pageStatus === "loggedIn"
                ?
                this.state.user.role === "new user"
                ?
                    <div className="admin_new_user_content">
                        <a href="/">
                            <div className="logo"></div>
                        </a>
                        <Navbar />
                        <p>You do not have admin access yet please wait for a super admin to change your role</p>
                        <span className="admin_new_user_sign_out">
                            <a href="/auth-serv/admin/sign_out">Sign out</a>
                        </span>
                    </div>
                :
                    <div className="main_wrapper">
                        <div className="admin_main">
                            <a href="/">
                                <div className="logo"></div>
                            </a>
                            <Navbar />
                            <div className="panel_dropshadow">
                                <div className="admin_panel">
                                    <div className="wall_grass_top"></div>
                                    <header className="admin_header">
                                        <h3 style={{"margin": "0"}}>
                                            <b>Admin Panel ({this.state.user.screenName || "Admin"})</b>
                                        </h3>
                                    </header>
                                    <div className="admin_panel_content">
                                        <nav>
                                            <ul>
                                                <li className={homeClass} onClick={this.changeView}>
                                                    <span id="home">Home</span>
                                                </li>
                                                <li className={postsClass} onClick={this.changeView}>
                                                    <span>Posts</span>
                                                </li>
                                                <li className={mediaClass} onClick={this.changeView}>
                                                    <span>Media</span>
                                                </li>
                                                <li className={storeClass} onClick={this.changeView}>
                                                    <span>Store</span>
                                                </li>
                                                {
                                                    this.state.user.role === "super admin"
                                                    ?
                                                        <li className={usersClass} onClick={this.changeView}>
                                                            <span>Users</span>
                                                        </li>
                                                    :
                                                        null
                                                }
                                                <li className={changePasswordClass} onClick={this.changeView}>
                                                    <span>Change password</span>
                                                </li>
                                                <li className="admin_nav_btn admin_nav_btn_sign_out">
                                                    <span>
                                                        <a href="/auth-serv/admin/sign_out">Sign out</a>
                                                    </span>
                                                </li>
                                            </ul>
                                        </nav>
                                        <div className="admin_main_content">
                                            {
                                                this.state.selectedTab === "home"
                                                ?
                                                    <AdminHome />
                                                :
                                                this.state.selectedTab === "posts"
                                                ?
                                                    <AdminPosts user={this.state.user} />
                                                :
                                                this.state.selectedTab === "media"
                                                ?
                                                    <AdminMedia />
                                                :
                                                this.state.selectedTab === "store"
                                                ?
                                                    <AdminStore />
                                                :
                                                this.state.selectedTab === "users"
                                                ?
                                                    <AdminUsers user={this.state.user}/>
                                                :
                                                    <AdminChangePassword user={this.state.user} push={this.pushToSignIn}/>
                                            }
                                        </div>  
                                    </div>
                                    <div className="wall_fade_out" style={{"marginTop": "-112px"}}></div>
                                    <div className="admin_panel_bottom"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                :
                    <Redirect to={{ pathname: "/auth/sign_in", state: {err: "You need to sign in or sign up before continuing."}}} />
        )
    }
}

export default AdminMain