import React, { Component } from "react"
import { Link, withRouter } from "react-router-dom"

import "./Navbar.scss"

class Navbar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            buyContainer: false,
            style: {"opacity": "0", "marginTop": "-20px", "visibility": "hidden"}
        }
    }

    toggleBuyContainer = () => {
        // Toggle the show of the buy container with the links to where you can buy the game
        if(this.state.buyContainer) {
            this.setState({
                buyContainer: false,
                style: {"opacity": "0", "marginTop": "-20px", "visibility": "hidden"}
            })
        }
        else {
            this.setState({
                buyContainer: true,
                style: {"opacity": "1", "marginTop": "-5px", "visibility": "visible"}
            })
        }
    }
    render() {
        let homeClass = this.props.match.path === "/" ? "active nav_btn nav_btn_home" : "nav_btn nav_btn_home"
        let newsClass = this.props.match.path === "/news" ? "active nav_btn nav_btn_news" : this.props.match.path === "/news/:title" ? "active nav_btn nav_btn_news" : "nav_btn nav_btn_news"
        let aboutClass = this.props.match.path === "/about" ? "active nav_btn nav_btn_about" : "nav_btn nav_btn_about"
        let creditsClass = this.props.match.path === "/credits" ? "active nav_btn nav_btn_credits": "nav_btn nav_btn_credits"
        let buyClass = this.state.buyContainer ? "active nav_btn nav_btn_buy": "nav_btn nav_btn_buy"
        let mediaClass = this.props.match.path === "/media" ? "active nav_btn nav_btn_media" : this.props.match.path === "/media/:gallery" ? "active nav_btn nav_btn_media" : "nav_btn nav_btn_media"
        let storeClass = this.props.match.path === "/store" ? "active nav_btn nav_btn_store": "nav_btn nav_btn_store"
        return (
            <div>
                <div className="buy_container">
                    <a href="https://www.microsoft.com/en-us/p/terraria/btnps60n3114#activetab=pivot:overviewtab" className="buy" id="xbla" style={this.state.style}>
                        <div className="buy_icon">
                            <div className="buy_icon_active"></div>
                        </div>
                        <div className="buy_text">XBLA</div>
                    </a>
                    <a href="https://www.nintendo.com/games/detail/terraria-switch/" className="buy" id="switch" style={this.state.style}>
                        <div className="buy_icon">
                            <div className="buy_icon_active"></div>
                        </div>
                        <div className="buy_text">Switch</div>
                    </a>
                    <a href="https://store.playstation.com/en-us/product/UP4040-CUSA00740_00-TERRARIA00000001" className="buy" id="psn" style={this.state.style}>
                        <div className="buy_icon">
                            <div className="buy_icon_active"></div>
                        </div>
                        <div className="buy_text">PSN</div>
                    </a>
                    <a href="https://www.playstation.com/en-us/games/terraria-psvita/" className="buy" id="psvita" style={this.state.style}>
                        <div className="buy_icon">
                            <div className="buy_icon_active"></div>
                        </div>
                        <div className="buy_text">PSVita</div>
                    </a>
                    <a href="https://store.steampowered.com/app/105600/" className="buy" id="pc" style={this.state.style}>
                        <div className="buy_icon">
                            <div className="buy_icon_active"></div>
                        </div>
                        <div className="buy_text">PC</div>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.and.games505.TerrariaPaid&hl=en_US" className="buy" id="android" style={this.state.style}>
                        <div className="buy_icon">
                            <div className="buy_icon_active"></div>
                        </div>
                        <div className="buy_text">Android</div>
                    </a>
                    <a href="https://apps.apple.com/us/app/terraria/id640364616" className="buy" id="ios" style={this.state.style}>
                        <div className="buy_icon">
                            <div className="buy_icon_active"></div>
                        </div>
                        <div className="buy_text">IOS</div>
                    </a>
                    <a href="https://www.amazon.com/505-Games-Terraria/dp/B00G6ZTM3Y/ref=sr_1_1?keywords=terraria&qid=1582323463&sr=8-1" className="buy" id="amazon" style={this.state.style}>
                        <div className="buy_icon">
                            <div className="buy_icon_active"></div>
                        </div>
                        <div className="buy_text">Amazon</div>
                    </a>
                </div>
                <nav>
                    <ul className="navigation">
                        <li className="nav_edge_left"></li>
                        <Link to="/">
                            <li className={homeClass}>
                                <span>Home</span>
                            </li>
                        </Link>
                        <Link to="/news">
                            <li className={newsClass}>
                                <span>News</span>
                            </li>
                        </Link>
                        <Link to="/about">
                            <li className={aboutClass}>
                                <span>About</span>
                            </li>
                        </Link>
                        <Link to="/credits">
                            <li className={creditsClass}>
                                <span>Credits</span>
                            </li>
                        </Link>
                        <li className={buyClass} onClick={this.toggleBuyContainer}></li>
                        <Link to="/media">
                            <li className={mediaClass}>
                                <span>Media</span>
                            </li>
                        </Link>
                        <li className="nav_btn nav_btn_wiki">
                            <a href="https://terraria.wiki.gg/">
                                <span>Wiki</span>
                            </a>
                        </li>
                        <li className="nav_btn nav_btn_forums">
                            <a href="http://forums.terraria.org">
                                <span>Forums</span>
                            </a>
                        </li>
                        <Link to="/store">
                            <li className={storeClass}>
                                <span>Store</span>
                            </li>
                        </Link>
                        <li className="nav_edge_right"></li>
                    </ul>
                </nav>
            </div>
        )
    }
}

export default withRouter(Navbar)