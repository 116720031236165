import React, { Component } from "react"

import "./AdminStoreSectionHeaderPreviewModel.scss"

class AdminStoreSectionHeaderPreviewModel extends Component {
    constructor(props) {
        super(props)

        this.storePreviewRef = React.createRef()
        this.previewHeaderRef = React.createRef()
    }

    componentDidMount() {
        const { REACT_APP_SERVER_HOST } = process.env

        this.previewHeaderRef.current.style.backgroundImage = `url(${REACT_APP_SERVER_HOST}${this.props.storeSectionHeader})`
    }

    componentDidUpdate(prevProps) {
        const { REACT_APP_SERVER_HOST } = process.env

        if(this.props.storeSectionHeader !== prevProps.storeSectionHeader) {
            this.previewHeaderRef.current.style.backgroundImage = `url(${REACT_APP_SERVER_HOST}${this.props.storeSectionHeader})`
        }
    }

    stopRun = (e) => {
        e.stopPropagation()
    }

    render() {
        let previewModelStyle = this.props.show ? { display: "flex" } : { display: "none" }
        return (
            <div className="admin_store_preview_model" style={previewModelStyle} onClick={this.props.openClosePreview}>
                <div></div>
                <div ref={this.storePreviewRef} className="admin_store_sections_preview_container panel_dropshadow" onClick={this.stopRun}>
                    <div className="wall">
                        <div className="wall_grass_top"></div>
                        <header ref={this.previewHeaderRef} className="admin_store_preview_header"></header>
                        <div className="wall_background">
                            <div className="wall_fade_in"></div>
                            <div className="wall_content">
                                <h2>Content will go here</h2>
                            </div>
                            <div className="wall_fade_out"></div>
                            <div className="wall_footer"></div>
                        </div>
                    </div>
                </div>
                <div>
                    <button className="admin_close_model_btn" onClick={this.props.openClosePreview}>X</button>
                </div>
            </div>
        )
    }
}

export default AdminStoreSectionHeaderPreviewModel