import React, { Component } from "react"
import axios from "axios"

import "./AdminAddStoreSectionCategories.scss"

class AdminAddStoreSectionCategories extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: ""
        }
    }

    updateInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    addStoreSectionCategory = async () => {
        try {
            let addBtn = document.getElementById("admin_add_store_section_categories_add_btn")
            addBtn.innerHTML = ""
            addBtn.classList.add("spinner")

            if(this.state.name.length) {
                let categoryRes = await axios.post("/api/admin/add/store-section-category", { section: this.props.section.id, name: this.state.name, order: this.props.categoriesLength + 1 })

                alert(categoryRes.data)

                addBtn.innerHTML = "Add"
                addBtn.classList.remove("spinner")

                this.setState({
                    name: ""
                }, async () => {
                    await this.props.getCategoriesAgain()
                    this.props.changeToView()
                })
            }
            else {
                alert("You must fill out a name for the category")

                addBtn.innerHTML = "Add"
                addBtn.classList.remove("spinner")
            }
        }
        catch(err) {
            let addBtn = document.getElementById("admin_add_store_section_categories_add_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            addBtn.innerHTML = "Add"
            addBtn.classList.remove("spinner")
        }
    }

    render() {
        return (
            <div className="admin_add_store_section_categories">
                <div className="admin_add_store_section_categories_input_container">
                    <p>Name:</p>
                    <input type="text" 
                    name="name" 
                    placeholder="Name" 
                    value={this.state.name} 
                    onChange={this.updateInput}/>
                </div>
                <div className="admin_add_store_section_categories_btn_container">
                    <button onClick={this.props.changeToView}>Cancel</button>
                    <button id="admin_add_store_section_categories_add_btn" disabled={!this.state.name.length} onClick={this.addStoreSectionCategory}>Add</button>
                </div>
            </div>
        )
    }
}

export default AdminAddStoreSectionCategories