import React, { Component } from "react"
import axios from "axios"

import AdminViewContainers from "./Components/AdminViewContainers/AdminViewContainers"
import AdminAddContainer from "./Components/AdminAddContainer/AdminAddContainer"
import AdminEditContainer from "./Components/AdminEditContainer/AdminEditContainer"
import AdminHomeTiles from "./Components/AdminHomeTiles/AdminHomeTiles"
import "./AdminHomeContainers.scss"

class AdminHomeContainers extends Component {
    constructor(props) {
        super(props)

        this.state = {
            panelType: "view",
            Editcontainer: {},
            containers: [],
            allContainers: [],
            offset: 0,
            pageNumber: 1,
            pages: 1,
            tilesContainer: {}
        }
    }

    async componentDidMount() {
        let containersRes = await axios.get("/api/admin/get/initial/home-containers")
        let allContainersRes = await axios.get("api/admin/get/all/home-containers")

        let pages = containersRes.data[0] === 0 ? 1 : Math.ceil(containersRes.data[0] / 10)

        this.setState({
            pages: pages,
            containers: containersRes.data[1],
            allContainers: allContainersRes.data,
            offset: 0,
            pageNumber: 1
        })
    }

    changeToView = () => {
        this.setState({
            panelType: "view"
        })
    }

    changeToAdd = () => {
        this.setState({
            panelType: "add"
        })
    }

    changeToEdit = (container) => {
        this.setState({
            panelType: "edit",
            Editcontainer: container
        })
    }

    getContainersAgain = async () => {
        let containersRes = await axios.get("/api/admin/get/initial/home-containers")
        let allContainersRes = await axios.get("api/admin/get/all/home-containers")

        let pages = containersRes.data[0] === 0 ? 1 : Math.ceil(containersRes.data[0] / 10)

        this.setState({
            pages: pages,
            containers: containersRes.data[1],
            allContainers: allContainersRes.data,
            offset: 0,
            pageNumber: 1
        })
    }

    previousPage = async () => {
        try {
            let containersRes = await axios.get(`/api/admin/get/home-containers/${this.state.offset - 10}`)

            this.setState( {
                containers: containersRes.data,
                offset: this.state.offset - 10,
                pageNumber: this.state.pageNumber - 1
            })
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    nextPage = async () => {
        try {
            let containersRes = await axios.get(`/api/admin/get/home-containers/${this.state.offset + 10}`)

            this.setState( {
                containers: containersRes.data,
                offset: this.state.offset + 10,
                pageNumber: this.state.pageNumber + 1
            })
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    changeToTiles = (container) => {
        this.setState({
            panelType: "tiles",
            tilesContainer: container
        })
    }

    render() {
        return (
            <div className="admin_store_home_tiles">
                {
                    this.state.panelType === "view"
                    ?
                        <AdminViewContainers changeToAdd={this.changeToAdd} 
                        containers={this.state.containers} 
                        changeToEdit={this.changeToEdit} 
                        pages={this.state.pages} 
                        pageNumber={this.state.pageNumber} 
                        getContainersAgain={this.getContainersAgain} 
                        previousPage={this.previousPage} 
                        nextPage={this.nextPage} 
                        changeToTiles={this.changeToTiles} />
                    :
                    this.state.panelType === "add"
                    ?
                        <AdminAddContainer changeToView={this.changeToView} containersLength={this.state.allContainers.length} getContainersAgain={this.getContainersAgain} />
                    :
                    this.state.panelType === "edit"
                    ?
                        <AdminEditContainer changeToView={this.changeToView} 
                        container={this.state.Editcontainer} 
                        containers={this.state.allContainers} 
                        getContainersAgain={this.getContainersAgain} />
                    :
                        <AdminHomeTiles container={this.state.tilesContainer} changeToContainers={this.changeToView} />
                }
            </div>
        )
    }
}

export default AdminHomeContainers