import React, { Component } from "react"
import axios from "axios"

import "./AdminHome.scss"

class AdminHome extends Component {
    constructor(props) {
        super(props)

        this.state = {
            url: "",
            title: "",
            description: "",
            pcFileName: "No file selected.",
            pcDedicatedServer: {},
            mobileFileName: "No file selected",
            mobileDedicatedServer: {}
        }
    }

    async componentDidMount() {
        try {
            let youtubeRes = await axios.get("/api/admin/get/home/youtube-info")

            this.setState({
                url: youtubeRes.data.url,
                title: youtubeRes.data.title,
                description: youtubeRes.data.description
            })
        }
        catch(err) {
            if(err.response.data) {
                alert(err.response.data)
            }
        }
    }

    updateInput = (e) => {
        try {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
        catch(err) {
            alert("Error updating text field")
        }
    }

    uploadPcDedicatedServer = async (e) => {
        try {
            const files = e.target.files

            if(files.length > 1) {
                alert("Please only upload one file")
            }
            else {    
                if(files[0].type === "application/zip" || files[0].type === "application/x-zip" || files[0].type === "application/x-zip-compressed") {
                    this.setState({
                        pcFileName: files[0].name,
                        pcDedicatedServer: files[0]
                    })
                }
                else {
                    alert("Must upload a zip file")
                    return
                }
            }
        }
        catch(err) {
        }
    }

    changePcDedicatedServer = async () => {
        try {
            let changePcDedicatedServerBtn = document.getElementById("change_pc_dedicated_server_btn")
            changePcDedicatedServerBtn.innerHTML = ""
            changePcDedicatedServerBtn.classList.add("spinner")

            if(!this.state.pcDedicatedServer.size) {
                alert("You must upload a file first")

                changePcDedicatedServerBtn.innerHTML = "Update"
                changePcDedicatedServerBtn.classList.remove("spinner")
            }
            else {
                const formData = new FormData()
                formData.append("pcServer", this.state.pcDedicatedServer)
    
                let changeServerRes = await axios.post("/api/admin/add/pc-dedicated-server", formData)

                alert(changeServerRes.data)

                changePcDedicatedServerBtn.innerHTML = "Update"
                changePcDedicatedServerBtn.classList.remove("spinner")
            }
        }
        catch(err) {
            let changePcDedicatedServerBtn = document.getElementById("change_pc_dedicated_server_btn")
            changePcDedicatedServerBtn.innerHTML = "Update"
            changePcDedicatedServerBtn.classList.remove("spinner")

            if(err.response.data) {
                alert(err.response.data)
            }
        }
    }

    updateYoutubeInfo = async () => {
        try{
            let updateYoutubeInfoBtn = document.getElementById("update_youtube_info_btn")
            updateYoutubeInfoBtn.innerHTML = ""
            updateYoutubeInfoBtn.classList.add("spinner")

            if(!this.state.url || !this.state.title || !this.state.description) {
                alert("Must have url, title and description")

                updateYoutubeInfoBtn.innerHTML = "Update"
                updateYoutubeInfoBtn.classList.remove("spinner")
            }
            else {
                let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
                let match = this.state.url.match(regExp)

                if(match && match[2].length >= 11) {
                    let updateYoutubeRes = await axios.put("/api/admin/update/youtube-info", {
                        url: this.state.url,
                        title: this.state.title,
                        description: this.state.description
                    })
    
                    alert(updateYoutubeRes.data)
    
                    updateYoutubeInfoBtn.innerHTML = "Update"
                    updateYoutubeInfoBtn.classList.remove("spinner")
                }
                else {
                    alert("Not a valid youtube url")

                    updateYoutubeInfoBtn.innerHTML = "Update"
                    updateYoutubeInfoBtn.classList.remove("spinner")
                }
            }
        }
        catch(err) {
            let updateYoutubeInfoBtn = document.getElementById("update_youtube_info_btn")
                updateYoutubeInfoBtn.innerHTML = "Update"
                updateYoutubeInfoBtn.classList.remove("spinner")

            if(err.response.data) {
                alert(err.response.data)
            }
        }
    }

    uploadMobileDedicatedServer = async (e) => {
        try {
            const files = e.target.files
            
            if(files.length > 1) {
                alert("Please only upload one file")
            }
            else {
                if(files[0].type === "application/zip" || files[0].type === "application/x-zip" || files[0].type === "application/x-zip-compressed") {
                    this.setState({
                        mobileFileName: files[0].name,
                        mobileDedicatedServer: files[0]
                    })
                }
                else {
                    alert("You must upload a zip file")
                }
            }
        }
        catch(err) {
        }
    }

    changeMobileDedicatedServer = async () => {
        try {
            let changeMobileDedicatedServerBtn = document.getElementById("change_mobile_dedicated_server_btn")
            changeMobileDedicatedServerBtn.innerHTML = ""
            changeMobileDedicatedServerBtn.classList.add("spinner")

            if(!this.state.mobileDedicatedServer.size) {
                alert("You must upload a file first")

                changeMobileDedicatedServerBtn.innerHTML = "Update"
                changeMobileDedicatedServerBtn.classList.remove("spinner")
            }
            else {
                const formData = new FormData()
                formData.append("mobileServer", this.state.mobileDedicatedServer)

                let changeServerRes = await axios.post("/api/admin/add/mobile-dedicated-server", formData)

                alert(changeServerRes.data)

                changeMobileDedicatedServerBtn.innerHTML = "Update"
                changeMobileDedicatedServerBtn.classList.remove("spinner")
            }
        }
        catch(err) {
            let changeMobileDedicatedServerBtn = document.getElementById("change_mobile_dedicated_server_btn")
            changeMobileDedicatedServerBtn.innerHTML = "Update"
            changeMobileDedicatedServerBtn.classList.remove("spinner")

            if(err.response.data) {
                alert(err.response.data)
            }
        }
    }

    render() {
        return (
            <div className="admin_home_container">
                <div className="frontpage_video_info_container">
                    <h4>
                        <b>Frontpage youtube video:</b>
                    </h4>
                    <div className="frontpage_url_container frontpage_input_container">
                        <h4>
                            <b>URL:</b>
                        </h4>
                        <input type="text" 
                        name="url" 
                        placeholder="URL" 
                        value={this.state.url} 
                        onChange={this.updateInput}/>
                    </div>
                    <div className="frontpage_title_container frontpage_input_container">
                        <h4>
                            <b>Title:</b>
                        </h4>
                        <input type="text" 
                        name="title" 
                        placeholder="Title" 
                        value={this.state.title} 
                        onChange={this.updateInput}/>
                    </div>
                    <div className="frontpage_description_container">
                        <h4>
                            <b>Description:</b>
                        </h4>
                        <textarea name="description" 
                        cols="50" 
                        rows="10" 
                        placeholder="Description" 
                        value={this.state.description} 
                        onChange={this.updateInput}></textarea>
                    </div>
                    <div className="frontpage_btn_container">
                        <button disabled={!this.state.url || !this.state.title || !this.state.description} 
                        id="update_youtube_info_btn" 
                        onClick={this.updateYoutubeInfo}>Update</button>
                    </div>
                </div>
                <div className="admin_home_dedicated_server_info_container">
                    <h4>
                        <b>PC Dedicated server (zip):</b>
                    </h4>
                    <div className="upload_file_container">
                        <input type="file" 
                        className="dedicated_server_upload_btn" 
                        accept="application/zip,application/x-zip,application/x-zip-compressed" 
                        onChange={this.uploadPcDedicatedServer}/>
                        <p>{this.state.pcFileName}</p>
                    </div>
                    <div className="dedicated_server_upload_btn_container">
                        <button disabled={!this.state.pcDedicatedServer.size} 
                        id="change_pc_dedicated_server_btn" 
                        onClick={this.changePcDedicatedServer}>Update</button>
                    </div>
                </div>
                <div className="admin_home_dedicated_server_info_container">
                    <h4>
                        <b>Mobile Dedicated server (zip):</b>
                    </h4>
                    <div className="upload_file_container">
                        <input type="file" 
                        className="dedicated_server_upload_btn" 
                        accept="application/zip,application/x-zip,application/x-zip-compressed" 
                        onChange={this.uploadMobileDedicatedServer}/>
                        <p>{this.state.mobileFileName}</p>
                    </div>
                    <div className="dedicated_server_upload_btn_container">
                        <button disabled={!this.state.mobileDedicatedServer.size} 
                        id="change_mobile_dedicated_server_btn" 
                        onClick={this.changeMobileDedicatedServer}>Update</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminHome