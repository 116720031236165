import React, { Component } from "react"
import axios from "axios"

import Navbar from "../../ReuseableComponents/Navbar/Navbar"
import NewsPost from "./Components/NewsPost/NewsPost"
import "./News.scss"

class News extends Component {
    constructor(props) {
        super(props)

        this.state = {
            posts: [],
            pageNumber: 1,
            offset: 0,
            pages: 0
        }
    }

    async componentDidMount() {
        try {
            let initialPostsRes = await axios.get("/api/get/initial/posts")

            document.body.style.scrollBehavior = "auto"

            document.title = "News - Terraria"
    
            this.setState({
                pages: Math.ceil(initialPostsRes.data[0] / 10),
                posts: initialPostsRes.data[1]
            })
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    componentWillUnmount() {
        document.body.style.scrollBehavior = "smooth"
    }

    nextPage = async () => {
        try {
            let offset = this.state.offset + 10
    
            if(this.state.pageNumber === this.state.pages) {
                return
            }
            else {
                let nextPostsRes = await axios.get(`/api/get/posts/${offset}`)
    
                this.setState({
                    posts: nextPostsRes.data,
                    offset: offset,
                    pageNumber: this.state.pageNumber + 1
                }, () => window.scrollTo({left: 0, top: 0, behavior: "auto"}))
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    previousPage = async () => {
        try {
            let offset = this.state.offset - 10
    
            if(this.state.pageNumber === 1) {
                return
            }
            else {
                let previousPostsRes = await axios.get(`/api/get/posts/${offset}`)
    
                this.setState({
                    posts: previousPostsRes.data,
                    offset: offset,
                    pageNumber: this.state.pageNumber - 1
                }, () => window.scrollTo({left: 0, top: 0, behavior: "auto"}))
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }
    
    firstPage = async () => {
        try {
            let offset = 0
            if(this.state.pageNumber === 1) {
                return
            }
            else {
                let firstPostsRes = await axios.get(`/api/get/posts/${offset}`)

                this.setState({
                    posts: firstPostsRes.data,
                    offset: offset,
                    pageNumber: 1
                }, () => window.scrollTo({left: 0, top: 0, behavior: "auto"}))
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    lastPage = async () => {
        try {
            let offset = (this.state.pages - 1) * 10
            if(this.state.pageNumber === this.state.pages) {
                return
            }
            else {
                let lastPostsRes = await axios.get(`/api/get/posts/${offset}`)

                this.setState({
                    posts: lastPostsRes.data,
                    offset: offset,
                    pageNumber: this.state.pages
                }, () => window.scrollTo({left: 0, top: 0, behavior: "auto"}))
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    render() {
        let posts = this.state.posts.map((post, i) => {
            return (
                <NewsPost key={i} post={post} scrollToTop={this.scrollToTop} />
            )
        })
        let firstClass = this.state.pageNumber > 1 ? "mini_page mini_first_btn enabled" : "mini_page mini_first_btn"
        let previousClass = this.state.pageNumber > 1 ? "mini_page mini_previous_btn enabled" : "mini_page mini_previous_btn"
        let nextClass = this.state.pageNumber < this.state.pages ? "mini_page mini_next_btn enabled" : "mini_page mini_next_btn"
        let lastClass = this.state.pageNumber < this.state.pages ? "mini_page mini_last_btn enabled" : "mini_page mini_last_btn"
        return (
            <div className="main_wrapper">
                <div className="news">
                    <a href="/">
                        <div className="logo"></div>
                    </a>
                    <Navbar />
                    <div className="news_page_contents">
                        <div className="mini_page_wrapper">
                            <div className="mini_page_selector">
                                <div className={firstClass} onClick={this.firstPage}></div>
                                <div className={previousClass} onClick={this.previousPage}></div>
                                <div className="mini_page_content">
                                    <p>{this.state.pageNumber} of {this.state.pages}</p>
                                </div>
                                <div className={nextClass} onClick={this.nextPage}></div>
                                <div className={lastClass} onClick={this.lastPage}></div>
                            </div>
                        </div>
                        <div className="posts_container">
                            {posts}
                        </div>
                        <div className="mini_page_wrapper">
                            <div className="mini_page_selector">
                                <div className={firstClass} onClick={this.firstPage}></div>
                                <div className={previousClass} onClick={this.previousPage}></div>
                                <div className="mini_page_content">
                                    <p>{this.state.pageNumber} of {this.state.pages}</p>
                                </div>
                                <div className={nextClass} onClick={this.nextPage}></div>
                                <div className={lastClass} onClick={this.lastPage}></div>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <br/>
                </div>
            </div>
        )
    }
}

export default News