import React, { Component } from "react"
import axios from "axios"

import AdminViewStoreSections from "./Components/AdminViewStoreSections/AdminViewStoreSections"
import AdminAddStoreSection from "./Components/AdminAddStoreSection/AdminAddStoreSection"
import AdminEditStoreSection from "./Components/AdminEditStoreSection/AdminEditStoreSection"
import AdminStoreSectionCategories from "./Components/AdminStoreSectionCategories/AdminStoreSectionCategories"
import AdminCategoryProducts from "./Components/AdminCategoryProducts/AdminCategoryProducts"
import AdminProductLinks from "./Components/AdminProductLinks/AdminProductLinks"
import AdminEditFeaturedSection from "./Components/AdminEditFeaturedSection/AdminEditFeaturedSection"
import AdminFeaturedStoreSectionCategories from "./Components/AdminFeaturedStoreSectionCategories/AdminFeaturedStoreSectionCategories"
import "./AdminStoreSections.scss"

class AdminStoreSections extends Component {
    constructor(props) {
        super(props)

        this.state = {
            panelType: "view",
            sections: [],
            offset: 0,
            pageNumber: 1,
            pages: 1,
            editSection: {},
            categoriesSection: {},
            productsCategory: {},
            linksProduct: {},
            allSections: [],
            featuredSection: {}
        }
    }

    async componentDidMount() {
        let sectionsRes = await axios.get("/api/admin/get/initial/store-sections")
        let allSectionsRes = await axios.get("/api/admin/get/all/store-sections")
        let featuredSectionRes = await axios.get("/api/admin/get/featured-section")

        let pages = sectionsRes.data[0] === 0 ? 1 : Math.ceil(sectionsRes.data[0] / 10)

        this.setState({
            pages: pages,
            sections: sectionsRes.data[1],
            offset: 0,
            pageNumber: 1,
            allSections: allSectionsRes.data,
            featuredSection: featuredSectionRes.data
        })
    }

    getStoreSectionsAgain = async () => {
        let sectionsRes = await axios.get("/api/admin/get/initial/store-sections")
        let allSectionsRes = await axios.get("/api/admin/get/all/store-sections")
        let featuredSectionRes = await axios.get("/api/admin/get/featured-section")

        let pages = sectionsRes.data[0] === 0 ? 1 : Math.ceil(sectionsRes.data[0] / 10)

        this.setState({
            pages: pages,
            sections: sectionsRes.data[1],
            offset: 0,
            pageNumber: 1,
            allSections: allSectionsRes.data,
            featuredSection: featuredSectionRes.data
        })
    }

    changeToView = () => {
        this.setState({
            panelType: "view"
        })
    }

    editStoreSection = (storeSection) => {
        this.setState({
            panelType: "edit",
            editSection: storeSection
        })
    }

    changeToAdd = () => {
        this.setState({
            panelType: "add"
        })
    }

    changeToCategories = (storeSeciton) => {
        this.setState({
            panelType: "categories",
            categoriesSection: storeSeciton
        })
    }

    changeToProducts = (category) => {
        this.setState({
            panelType: "products",
            productsCategory: category
        })
    }

    changeToLinks = (product) => {
        this.setState({
            panelType: "links",
            linksProduct: product
        })
    }

    previousPage = async () => {
        try {
            if(this.state.pageNumber === 1) {
                return
            }
            else {
                if(this.state.offset === 0) {
                    return
                }
                else {
                    let offset = this.state.offset - 10
        
                    let sectionsRes = await axios.get(`/api/admin/get/store-sections/${offset}`)
        
                    let pageNumber = this.state.pageNumber - 1
        
                    this.setState({
                        sections: sectionsRes.data,
                        offset: offset,
                        pageNumber: pageNumber
                    })
                }
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    nextPage = async () => {
        try {
            if(this.state.pageNumber === this.state.pages) {
            }
            else {
                if(this.state.offset === (this.state.pages - 1) * 10) {
                    return
                }
                else {
                    let offset = this.state.offset + 10
        
                    let sectionsRes = await axios.get(`/api/admin/get/store-sections/${offset}`)
        
                    let pageNumber = this.state.pageNumber + 1
        
        
                    this.setState({
                        sections: sectionsRes.data,
                        offset: offset,
                        pageNumber: pageNumber
                    })
                }
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    changeToEditFeatured = () => {
        this.setState({
            panelType: "edit featured"
        })
    }

    changeToFeaturedCategories = () => {
        this.setState({
            panelType: "featured categories"
        })
    }

    render() {
        return (
            <div className="admin_store_sections">
                {
                    this.state.panelType === "view"
                    ?
                        <AdminViewStoreSections sections={this.state.sections} 
                        changeToAdd={this.changeToAdd} 
                        pageNumber={this.state.pageNumber} 
                        pages={this.state.pages} 
                        editStoreSection={this.editStoreSection} 
                        changeToCategories={this.changeToCategories} 
                        getStoreSectionsAgain={this.getStoreSectionsAgain} 
                        nextPage={this.nextPage} 
                        previousPage={this.previousPage} 
                        featuredSection={this.state.featuredSection}
                        changeToEditFeatured={this.changeToEditFeatured} 
                        changeToFeaturedCategories={this.changeToFeaturedCategories} />
                    :
                    this.state.panelType === "add"
                    ?
                        <AdminAddStoreSection getStoreSectionsAgain={this.getStoreSectionsAgain} 
                        changeToView={this.changeToView} 
                        sectionsLength={this.state.allSections.length} />
                    :
                    this.state.panelType === "edit"
                    ?
                        <AdminEditStoreSection section={this.state.editSection} 
                        changeToView={this.changeToView} 
                        getStoreSectionsAgain={this.getStoreSectionsAgain} 
                        sections={this.state.allSections} />
                    :
                    this.state.panelType === "categories"
                    ?
                        <AdminStoreSectionCategories section={this.state.categoriesSection} changeToView={this.changeToView} changeToProducts={this.changeToProducts} />
                    :
                    this.state.panelType === "products"
                    ?
                        <AdminCategoryProducts section={this.state.categoriesSection} 
                        changeToCategories={this.changeToCategories} 
                        category={this.state.productsCategory} 
                        changeToLinks={this.changeToLinks} />
                    :
                    this.state.panelType === "links"
                    ?
                        <AdminProductLinks section={this.state.categoriesSection} 
                        category={this.state.productsCategory} 
                        changeToProducts={this.changeToProducts} 
                        product={this.state.linksProduct} />
                    :
                    this.state.panelType === "edit featured"
                    ?
                        <AdminEditFeaturedSection changeToView={this.changeToView} featuredSection={this.state.featuredSection} getStoreSectionsAgain={this.getStoreSectionsAgain} />
                    :
                        <AdminFeaturedStoreSectionCategories changeToView={this.changeToView} />
                }
            </div>
        )
    }
}

export default AdminStoreSections