import React from "react"

import AdminFeaturedProduct from "./Components/AdminFeaturedProduct/AdminFeaturedProduct"
import "./AdminViewFeaturedProducts.scss"

let AdminViewFeaturedProducts = (props) => {
    let products = props.products.map((product, i) => {
        return (
            <AdminFeaturedProduct key={i} product={product} getProductsAgain={props.getProductsAgain} changeToEdit={props.changeToEdit} />
        )
    })

    return (
        <div className="admin_view_featured_products">
            <div style={{"padding": "10px"}} className="admin_store_page_info_container">
                <h4>
                    {props.category.name} products - page {props.pageNumber}
                </h4>
            </div>
            <div className="admin_store_list_items_container">
                {products}
            </div>
            <div className="admin_store_previous_next_container">
                <button disabled={props.pageNumber === 1} onClick={props.previousPage}>Previous</button>
                <button disabled={props.pages === props.pageNumber} onClick={props.nextPage} >Next</button>
            </div>
            <button className="admin_store_standard_btn" style={{"width": "150px"}} onClick={props.changeToView}>Featured Categories</button>
        </div>
    )
}

export default AdminViewFeaturedProducts