import React, { Component } from "react"
import axios from "axios"

import AdminViewPosts from "./Components/AdminViewPosts/AdminViewPosts"
import AdminAddPost from "./Components/AdminAddPost/AdminAddPost"
import AdminEditPost from "./Components/AdminEditPost/AdminEditPost"

class AdminPosts extends Component {
    constructor(props) {
        super(props)

        this.state = {
            panelType: "view",
            posts: [],
            pageNumber: 1,
            editPost: {},
            offset: 0,
            pages: 0
        }
    }

    async componentDidMount() {
        try {
            let initialPostsRes = await axios.get("/api/admin/get/initial/posts")

            let pages = initialPostsRes.data[0] === 0 ? 1 : Math.ceil(initialPostsRes.data[0] / 10)

            this.setState({
                pages: pages,
                posts: initialPostsRes.data[1],
                offset: 0,
                pageNumber: 1
            })
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    changeToView = () => {
        this.setState({
            panelType: "view"
        })
    }

    changeToAdd = () => {
        this.setState({
            panelType: "add"
        })
    }

    changeToEdit = (post) => {
        this.setState({
            panelType: "edit",
            editPost: post
        })
    }

    getPostsAgain = async () => {
        try {
            let initialPostsRes = await axios.get("/api/admin/get/initial/posts")

            this.setState({
                pages: Math.ceil(initialPostsRes.data[0] / 10),
                posts: initialPostsRes.data[1],
                panelType: "view"
            })
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    doneEditingPost = async () => {
        let postsRes = await axios.get(`/api/admin/get/posts/${this.state.offset}`)

        this.setState({
            posts: postsRes.data,
            panelType: "view"
        })
    }

    previousPage = async () => {
        try {
            let offset = this.state.offset - 10
    
            if(this.state.pageNumber === 1) {
                return
            }
            else {
                if(this.state.offset === 0) {
                    return
                }
                else {
                    let previousPostsRes = await axios.get(`/api/admin/get/posts/${offset}`)
        
                    this.setState({
                        posts: previousPostsRes.data,
                        offset: offset,
                        pageNumber: this.state.pageNumber - 1
                    })
                }
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    nextPage = async () => {
        try {
            let offset = this.state.offset + 10
    
            if(this.state.pageNumber === this.state.pages) {
                return
            }
            else {
                if(this.state.offset === (this.state.pages - 1) * 10) {
                    return
                }
                else {
                    let nextPostsRes = await axios.get(`/api/admin/get/posts/${offset}`)
        
                    this.setState({
                        posts: nextPostsRes.data,
                        offset: offset,
                        pageNumber: this.state.pageNumber + 1
                    })
                }
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    render() {
        return (
            this.state.panelType === "view"
            ?
                <AdminViewPosts posts={this.state.posts} 
                changeToAdd={this.changeToAdd} 
                changeToEdit={this.changeToEdit} 
                pageNumber={this.state.pageNumber} 
                getPostsAgain={this.getPostsAgain} 
                nextPage={this.nextPage} 
                previousPage={this.previousPage} 
                pages={this.state.pages} 
                doneEditingPost={this.doneEditingPost} 
                user={this.props.user} />
            :
            this.state.panelType === "add"
            ?
                <AdminAddPost changeToView={this.changeToView} getPostsAgain={this.getPostsAgain} user={this.props.user} />
            :
                <AdminEditPost changeToView={this.changeToView} post={this.state.editPost} doneEditingPost={this.doneEditingPost} user={this.props.user} />
        )
    }
}

export default AdminPosts