import React, { useEffect, useState } from "react"
import axios from "axios"

import "./VideosBlock.scss"

let VideosBlock = (props) => {
    const [videos, setVideos] = useState([])
    const [currentVideoNumber, setCurrentVideoNumber] = useState(0)
    useEffect(() => {
        let func = async () => {
            let videosRes = await axios.get("/api/get/youtube-videos")
            let videosArr = videosRes.data[1].items.map((video, i) => {
                return {title: video.snippet.title, id: videosRes.data[0].items[i].snippet.resourceId.videoId}
            })

            console.log(videosRes)

            let terrariaVideos = videosArr.filter((video, i) => video.title.includes("Terraria"))

            setVideos(terrariaVideos)
        }
        func()
    }, [props.playlistId])

    let previousVideo = () => {
        if(currentVideoNumber === 0) {
            return
        }
        else {
            setCurrentVideoNumber(currentVideoNumber - 1)
        }
    }

    let nextVideo = () => {
        if(currentVideoNumber === videos.length - 1) {
            return
        }
        else {
            setCurrentVideoNumber(currentVideoNumber + 1)
        }
    }

    return (
        <div className="videos_block">
            <div className="panel_dropshadow">
                <div className="wall" id="video_gallery">
                    <div className="wall_grass_top"></div>
                    <header className="wall_header"></header>
                    <div className="wall_background">
                        <div className="wall_fade_in"></div>
                        <div className="wall_content">
                            <div className="video_block">
                                {
                                    videos.length < 1
                                    ?
                                        <div className="playlist_error_container">
                                            <h1>Sorry, but the video player is temporarily unavailable.</h1>
                                        </div>
                                    :
                                    <div className="playlist_container">
                                            <div className="video_title">
                                                {
                                                    videos[currentVideoNumber]
                                                    ?
                                                        <h3>
                                                            <b>{videos[currentVideoNumber].title}</b>
                                                        </h3>
                                                    :
                                                        null
                                                }
                                            </div>
                                            <div className="playlist_video_video_controls">
                                                <button className="playlist_btn playlist_previous_btn" 
                                                disabled={currentVideoNumber === 0} 
                                                onClick={previousVideo}></button>
                                                <div className="playlist_video_container">
                                                    <iframe src={`https://www.youtube.com/embed/${videos[currentVideoNumber].id}?feature=player_detailpage&autoplay=0&rel=0`} 
                                                    title="playlist" 
                                                    allowFullScreen 
                                                    frameBorder="0" 
                                                    className="embedded_playlist"></iframe>
                                                </div>
                                                <button className="playlist_btn playlist_next_btn" 
                                                disabled={currentVideoNumber === videos.length - 1} 
                                                onClick={nextVideo}></button>
                                            </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="wall_fade_out"></div>
                        <div className="wall_footer_none"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VideosBlock