import React, { Component } from "react"
import axios from "axios"

import AdminViewProducts from "./Components/AdminViewProducts/AdminViewProducts"
import AdminAddProduct from "./Components/AdminAddProduct/AdminAddProduct"
import AdminEditProduct from "./Components/AdminEditProduct/AdminEditProduct"
import "./AdminCategoryProducts.scss"

class AdminCategoryProducts extends Component {
    constructor(props) {
        super(props)

        this.state = {
            panelType: "view",
            products: [],
            editProduct: {},
            offset: 0,
            pageNumber: 1,
            pages: 1,
            allProducts: [],
            navDisabled: false,
            categories: [],
            featuredCategories: [],
            sortBy: "product_order"
        }
    }

    async componentDidMount() {
        let productsRes = await axios.get(`/api/admin/get/initial/products/${this.props.category.id}/${this.state.sortBy}`)
        let allProductsRes = await axios.get(`/api/admin/get/all/products/${this.props.category.id}`)
        let categoriesRes = await axios.get(`/api/admin/get/all/store-section-categories/${this.props.section.id}`)
        let featuredCatRes = await axios.get("/api/admin/get/all/featured-categories")

        let pages = productsRes.data[0] === 0 ? 1 : Math.ceil(productsRes.data[0] / 10)

        this.setState({
            pages: pages,
            products: productsRes.data[1],
            offset: 0,
            pageNumber: 1,
            allProducts: allProductsRes.data,
            categories: categoriesRes.data,
            featuredCategories: featuredCatRes.data
        })
    }

    changeToView = () => {
        this.setState({
            panelType: "view"
        })
    }
    
    changeToAdd = () => {
        this.setState({
            panelType: "add"
        })
    }

    changeToEdit = (product) => {
        this.setState({
            panelType: "edit",
            editProduct: product
        })
    }

    getProductsAgain = async () => {
        let productsRes = await axios.get(`/api/admin/get/initial/products/${this.props.category.id}/${this.state.sortBy}`)
        let allProductsRes = await axios.get(`/api/admin/get/all/products/${this.props.category.id}`)

        let pages = productsRes.data[0] === 0 ? 1 : Math.ceil(productsRes.data[0] / 10)

        this.setState({
            pages: pages,
            products: productsRes.data[1],
            offset: 0,
            pageNumber: 1,
            allProducts: allProductsRes.data
        })
    }

    previousPage = async () => {
        try {
            if(this.state.pageNumber === 1) {
                return
            }
            else {
                if(this.state.offset === 0) {
                    return
                }
                else {
                    let offset = this.state.offset - 10
            
                    let productsRes = await axios.get(`/api/admin/get/products/${offset}/${this.props.category.id}/${this.state.sortBy}`)
            
                    let pageNumber = this.state.pageNumber - 1
            
                    this.setState({
                        products: productsRes.data,
                        offset: offset,
                        pageNumber: pageNumber,
                        navDisabled: true
                    })
                }
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    nextPage = async () => {
        try {
            if(this.state.pageNumber === this.state.pages) {
                return
            }
            else {
                if(this.state.offset === (this.state.pages - 1) * 10) {
                    return
                }
                else {
                    let offset = this.state.offset + 10
            
                    let productsRes = await axios.get(`/api/admin/get/products/${offset}/${this.props.category.id}/${this.state.sortBy}`)
            
                    let pageNumber = this.state.pageNumber + 1
            
                    this.setState({
                        products: productsRes.data,
                        offset: offset,
                        pageNumber: pageNumber,
                        navDisabled: true
                    })
                }
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }


    moveCategory = async (product, category) => {
        try {
            let categoryRes = await axios.put("/api/admin/update/product/move/category", { id: product, category: category, oldCategory: this.props.category.id})

            alert(categoryRes.data)
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    featureProduct = async (product, category) => {
        try {
            let productRes = await axios.post("/api/admin/add/featured-product", { category: category, product: product})

            alert(productRes.data)
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    changeSort = async (e) => {
        this.setState({
            sortBy: e.target.value
        }, async () => {
            await this.getProductsAgain()
        })
    }

    render() {
        return (
            <div className="admin_category_products">
                {
                    this.state.panelType === "view"
                    ?
                        <AdminViewProducts products={this.state.products} 
                        changeToCategories={this.props.changeToCategories} 
                        section={this.props.section} 
                        pageNumber={this.state.pageNumber} 
                        pages={this.state.pages} 
                        changeToAdd={this.changeToAdd} 
                        changeToEdit={this.changeToEdit} 
                        getProductsAgain={this.getProductsAgain} 
                        changeToLinks={this.props.changeToLinks} 
                        nextPage={this.nextPage} 
                        previousPage={this.previousPage} 
                        categories={this.state.categories} 
                        category={this.props.category} 
                        moveCategory={this.moveCategory} 
                        featuredCategories={this.state.featuredCategories}
                        featureProduct={this.featureProduct} 
                        changeSort={this.changeSort} 
                        sortBy={this.state.sortBy} />
                    :
                    this.state.panelType === "add"
                    ?
                        <AdminAddProduct
                        category={this.props.category}  
                        changeToView={this.changeToView}  
                        getProductsAgain={this.getProductsAgain} 
                        section={this.props.section} 
                        productsLength={this.state.allProducts.length} />
                    :
                        <AdminEditProduct product={this.state.editProduct} 
                        category={this.props.category} 
                        changeToView={this.changeToView} 
                        getProductsAgain={this.getProductsAgain} 
                        products={this.state.allProducts} />
                }
            </div>
        )
    }
}

export default AdminCategoryProducts