import React from "react"

import AdminDropDownMenu from "./Components/AdminDropDownMenu/AdminDropDownMenu"
import "./AdminViewHomeDropDownMenus.scss"

let AdminViewHomeDropDowns = (props) => {
    let dropDownMenus = props.dropDownMenus.map((dropDownMenu, i) => {
        return (
            <AdminDropDownMenu key={i} dropDownMenu={dropDownMenu} getSectionsDropDownMenus={props.getSectionsDropDownMenus} changeToEdit={props.changeToEdit} />
        )
    })
    return (
        <div className="admin_store_view_home_drop_down_menus">
            <div className="admin_store_view_home_drop_down_menus_page_info">
                <h4>
                    Drop Down Menus page - {props.pageNumber}
                </h4>
                <button className="admin_store_view_home_drop_down_menus_new_btn" onClick={props.changeToAdd}>New</button>
            </div>
            <div className="admin_store_view_home_drop_down_menus_drop_down_menus_container">
                {dropDownMenus}
            </div>
            <div className="admin_store_view_home_drop_down_menus_prev_next_container">
                <button disabled={props.pageNumber === 1} onClick={props.previousPage}>Previous</button>
                <button disabled={props.pageNumber === props.pages} onClick={props.nextPage}>Next</button>
            </div>
        </div>
    )
}

export default AdminViewHomeDropDowns