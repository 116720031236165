import React from "react"
import { BrowserRouter as Router} from "react-router-dom"

import routes from "./routes"
import "./App.scss"

function App() {
  return (
    <div className="app">
      <Router>
        {routes}
      </Router>
    </div>
  );
}

export default App;
