import React, { Component } from "react"
import axios from "axios"

import AdminGallery from "../../Components/AdminGallery/AdminGallery"
import "./AdminViewGalleries.scss"

class AdminViewGalleries extends Component {
    constructor(props) {
        super(props)

        this.state = {
            newGallery: "",
            playlistUrl: "",
            imageGalleries: []
        }
    }

    async componentDidMount() {
        try {
            let mediaRes = await axios.get("/api/admin/get/media-info")

            this.setState({
                imageGalleries: mediaRes.data[0],
                playlistUrl: mediaRes.data[1].url
            })
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    updateInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    addGallery = async () => {
        try {
            let addGalleryBtn = document.getElementById("admin_media_add_gallery_btn")
            addGalleryBtn.innerHTML = ""
            addGalleryBtn.classList.add("spinner")

            if(this.state.newGallery.length) {
                let addImageGalleryRes = await axios.post("/api/admin/add/image-gallery", { name: this.state.newGallery, safeName: this.state.newGallery.replace(/[\W_]+/g, "-").toLowerCase()})

                alert(addImageGalleryRes.data)

                addGalleryBtn.innerHTML = "Add gallery"
                addGalleryBtn.classList.remove("spinner")

                this.setState({
                    newGallery: ""
                }, () => this.getGalleriesAgain())
            }
            else {
                alert("Image gallery must have a length")

                addGalleryBtn.innerHTML = "Add gallery"
                addGalleryBtn.classList.remove("spinner")
            }
        }
        catch(err) {
            let addGalleryBtn = document.getElementById("admin_media_add_gallery_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            addGalleryBtn.innerHTML = "Add gallery"
            addGalleryBtn.classList.remove("spinner")
        }
    }

    getGalleriesAgain = async () => {
        try {
            let galleriesRes = await axios.get("/api/admin/get/image-galleries")

            this.setState({
                imageGalleries: galleriesRes.data
            })
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    updatePlaylist = async () => {
        try {
            let updatePlaylistBtn = document.getElementById("admin_media_update_playlist_btn")
            updatePlaylistBtn.innerHTML = ""
            updatePlaylistBtn.classList.add("spinner")

            let regExp =   /^.*(youtu.be\/|list=)([^#&?]*).*/

            if(this.state.playlistUrl.length) {
                let match = this.state.playlistUrl.match(regExp)

                if(match && match[2]) {
                    let playlistRes = await axios.put("/api/admin/update/playlist", { playlistUrl: this.state.playlistUrl})

                    alert(playlistRes.data)
                }
                else {
                    alert("Invalid playlist url")
                }

                updatePlaylistBtn.innerHTML = "Update"
                updatePlaylistBtn.classList.remove("spinner")
            }
            else {
                alert("Playlist field must have a url to update")

                updatePlaylistBtn.innerHTML = "Update"
                updatePlaylistBtn.classList.remove("spinner")
            }
        }
        catch(err) {
            let updatePlaylistBtn = document.getElementById("admin_media_update_playlist_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            updatePlaylistBtn.innerHTML = "Update"
            updatePlaylistBtn.classList.remove("spinner")
        }
    }

    render() {
        let galleries = this.state.imageGalleries.map((gallery, i) => {
            return (
                <AdminGallery key={i} gallery={gallery} changePanelType={this.props.changePanelType} getGalleriesAgain={this.getGalleriesAgain} />
            )
        })
        return (
            <div className="admin_view_galleries_panel">
                <header>
                    <h2>
                        <b>Media Page Contents</b>
                    </h2>
                </header>
                <div className="admin_media_content">
                    <div className="admin_media_new_gallery_container">
                        <h4>
                            <strong>
                                Image Galleries:
                            </strong>
                        </h4>
                        <div className="admin_media_input_container">
                            <input type="text" 
                            name="newGallery" 
                            placeholder="New Image Gallery Name" 
                            value={this.state.newGallery} 
                            onChange={this.updateInput}/>
                            <button id="admin_media_add_gallery_btn" disabled={!this.state.newGallery.length} onClick={this.addGallery}>Add gallery</button>
                        </div>
                    </div>
                    <div className="admin_media_galleries_container">
                        {galleries}
                    </div>
                    <div className="admin_media_video_playlist_container">
                        <h4>
                            <strong>
                                Video Playlist URL:
                            </strong>
                        </h4>
                        <div className="admin_media_input_container">
                            <input type="text" 
                            name="playlistUrl" 
                            placeholder="http://www.youtube.com/playlist?list=dfjpodkljsdJOIDsasdlk" 
                            value={this.state.playlistUrl} 
                            onChange={this.updateInput}/>
                            <button id="admin_media_update_playlist_btn" disabled={!this.state.playlistUrl} onClick={this.updatePlaylist}>Update</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminViewGalleries