import React from "react"
import axios from "axios"

import "./AdminTile.scss"

let AdminTile = (props) => {
    let changeToEdit = () => {
        props.changeToEdit(props.tile)
    }

    let deleteTile = async () => {
        try {
            let deleteBtn = document.getElementById(`admin_tile_delete_btn_${props.tile.id}`)
            deleteBtn.innerHTML = ""
            deleteBtn.classList.add("spinner")

            if(window.confirm("Are you sure you want to delete this tile")) {
                let tileRes = await axios.delete(`/api/admin/delete/tile/${props.tile.id}/${props.tile.img.split("StoreTiles/")[1]}`)

                alert(tileRes.data)

                deleteBtn.innerHTML = "Delete"
                deleteBtn.classList.remove("spinner")

                props.getTilesCategoriesAgain()
            }
            else {
                deleteBtn.innerHTML = "Delete"
                deleteBtn.classList.remove("spinner")
            }
        }
        catch(err) {
            let deleteBtn = document.getElementById(`admin_tile_delete_btn_${props.tile.id}`)

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            deleteBtn.innerHTML = "Delete"
            deleteBtn.classList.remove("spinner")
        }
    }

    return (
        <div className="admin_tile admin_store_list_item">
            <p>{props.tile.name}</p>
            <div className="admin_store_list_item_btn_container">
                <button onClick={changeToEdit}>Edit</button>
                <button id={`admin_tile_delete_btn_${props.tile.id}`} className="admin_spinner_container" onClick={deleteTile}>Delete</button>
            </div>
        </div>
    )
}

export default AdminTile