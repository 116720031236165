import React, { Component } from "react"
import axios from "axios"

import AdminTilePreview from "../AdminTilePreview/AdminTilePreview"
import "./AdminEditTile.scss"

class AdminEditTile extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: "",
            img: "",
            section: 0,
            category: 0,
            categoryName: "0,0",
            imgFileName: "No file selected.",
            imgDeleteName: "",
            previewShow: false,
            oldFileName: ""
        }
    }

    componentDidMount() {
        let oldFileName = this.props.tile.img.split("StoreTiles/")[1]
        this.setState({
            name: this.props.tile.name,
            img: this.props.tile.img,
            section: this.props.tile.section,
            category: this.props.tile.category,
            categoryName: `${this.props.tile.section},${this.props.tile.category}`,
            oldFileName: oldFileName
        })
    }

    componentDidUpdate(prevProps) {
        if(this.props.tile.id !== prevProps.tile.id) {
            let oldFileName = this.props.tile.img.split("StoreTiles/")[1]
            this.setState({
                name: this.props.tile.name,
                img: this.props.tile.img,
                section: this.props.tile.section,
                category: this.props.tile.category,
                categoryName: `${this.props.tile.section},${this.props.tile.category}`,
                oldFileName: oldFileName
            })
        }
    }

    updateInput = (e) => {
        if(e.target.name === "category") {
            let section = Number(e.target.value.split(",")[0])
            let category = Number(e.target.value.split(",")[1])
            
            this.setState({
                section,
                category,
                categoryName: `${section},${category}`
            })
        }
        else {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    uploadTileImg = async (e) => {
        try {
            const files = e.target.files

            if(files.length > 1) {
                alert("You can only upload one image")
            }
            else {
                if(files[0].type === "image/png" || files[0].type === "image/bmp" || files[0].type === "image/jpeg" || files[0].type === "image/jpg" || files[0].type === "image/gif" || files[0].type === "image/x-icon") {
                    let blob = files[0].slice(0, files[0].size, files[0].type)
                    let newFile = new File([blob], files[0].name.replace(/\s/g, "-"), {type: files[0].type})
                    const formData = new FormData()
                    formData.append("tileImage", newFile)

                    if(this.state.imgFileName !== "No file selected.") {
                        let deleteFile = new File(["yes"], "delete", { type: "text/plain" })
                        let imgNameFile = new File([`${this.state.imgDeleteName}`], "imgName", { type: "text/plain" })

                        formData.append("delete", deleteFile)
                        formData.append("imgName", imgNameFile)
                    }

                    let partnerImgRes = await axios.post("/api/admin/add/store-tile-img", formData)

                    e.target.value = null

                    this.setState({
                        img: partnerImgRes.data[0],
                        imgFileName: partnerImgRes.data[1],
                        imgDeleteName: partnerImgRes.data[2]
                    })
                }
                else {
                    alert("Image file must be a png, bmp, jpeg, jpg or ico image")
                }
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    updateTile = async () => {
        try {
            let updateBtn = document.getElementById("admin_edit_tile_update_btn")
            updateBtn.innerHTML = ""
            updateBtn.classList.add("spinner")

            if(!this.state.name || !this.state.category || !this.state.section || !this.state.img) {
                alert("Must have a name, select a category, and upload an image")

                updateBtn.innerHTML = "Update"
                updateBtn.classList.remove("spinner")
            }
            else {
                let tileRes = await axios.put("/api/admin/update/tile", {
                    id: this.props.tile.id,
                    section: this.state.section,
                    category: this.state.category,
                    name: this.state.name,
                    img: this.state.img
                })

                if(this.state.imgFileName !== "No file selected.") {
                    await axios.delete(`/api/admin/delete/old-tile-image/${this.state.oldFileName}`)
                }

                alert(tileRes.data)

                updateBtn.innerHTML = "Update"
                updateBtn.classList.remove("spinner")

                this.setState({
                    name: "",
                    img: "",
                    section: 0,
                    category: 0,
                    categoryName: "0,0",
                    imgFileName: "No file selected.",
                    imgDeleteName: "",
                    previewShow: false,
                    oldFileName: ""
                }, async () => {
                    await this.props.getTilesCategoriesAgain()
                    this.props.changeToView()
                })
            }
        }
        catch(err) {
            let updateBtn = document.getElementById("admin_edit_tile_update_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            updateBtn.innerHTML = "Update"
            updateBtn.classList.remove("spinner")
        }
    }

    openClosePreview = () => {
        this.setState({
            previewShow: !this.state.previewShow
        })
    }

    render() {
        let categories = this.props.categories.map((category, i) => {
            return (
                <option key={i} value={`${category.section},${category.id}`}>{category.name}</option>
            )
        })
        return (
            <div className="admin_edit_tile">
                <div className="admin_store_form_container">
                    <div className="admin_store_input_container">
                        <p>Name:</p>
                        <input type="text" 
                        name="name" 
                        placeholder="Name" 
                        value={this.state.name} 
                        onChange={this.updateInput}/>
                    </div>
                    <div className="admin_store_input_container">
                        {
                            this.props.container.type === "one img one by two"
                            ?
                                <p>Image size depends on container type this container(466 x 516):</p>
                            :
                            this.props.container.type === "two img one by two" || this.props.container.type === "two img two by one"
                            ?
                                <p>Image size depends on container type this container(466 x 251):</p>
                            :
                            this.props.container.type === "one img two by one"
                            ?
                                <p>Image size depends on container type this container(946 x 251):</p>
                            :
                                <p>Image size depends on container type this container(946 x 516):</p>
                            
                        }
                        <div className="admin_store_file_upload_container">
                            <input type="file" 
                            accept="image/*" 
                            onChange={this.uploadTileImg}/>
                            {
                                this.state.imgFileName === "No file selected."
                                ?
                                    <p>{this.state.oldFileName}</p>
                                :
                                    <p>{this.state.imgFileName}</p>
                            }
                        </div>
                    </div>
                    <div className="admin_store_input_container">
                        <p>Category:</p>
                        <select name="category" value={this.state.categoryName} onChange={this.updateInput}>
                            {categories}
                        </select>
                    </div>
                </div>
                <div className="admin_store_btn_container">
                    <button onClick={this.props.changeToView}>Cancel</button>
                    <div className="admin_store_preview_update_container">
                        <button onClick={this.openClosePreview}>Preview</button>
                        <button id="admin_edit_tile_update_btn" 
                        className="admin_spinner_container" 
                        disabled={!this.state.name || !this.state.img || !this.state.section || !this.state.category} 
                        onClick={this.updateTile}>Update</button>
                    </div>
                </div>
                <AdminTilePreview show={this.state.previewShow} openClosePreview={this.openClosePreview} container={this.props.container.type} img={this.state.img} />
            </div>
        )
    }
}

export default AdminEditTile