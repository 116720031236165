import React, { Component } from "react"
import axios from "axios"

import "./AdminAddProduct.scss"

class AdminAddProduct extends Component {
    constructor(props) {
        super(props)

        this.state = {
            img: "/Home/default.png",
            name: "",
            description: "",
            imgFileName: "No file selected.",
            deleteImgName: ""
        }
    }

    async componentDidMount() {
        window.addEventListener("beforeunload", this.cleanup)
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.cleanup)
    }

    cleanup = async () => {
        if(this.state.storeSectionFileName !== "No file selected.") {
            let imgFileName = this.state.deleteImgName

            await axios.delete(`/api/admin/delete/product-image/${imgFileName}`)
        }
    }

    updateInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    uploadProductImg = async (e) => {
        try {
            const files = e.target.files
    
            if(files.length > 1) {
                alert("You can only upload one image")
            }
            else {
                if(files[0].type === "image/png" || files[0].type === "image/bmp" || files[0].type === "image/jpeg" || files[0].type === "image/jpg" || files[0].type === "image/gif" || files[0].type === "image/x-icon") {
                    let blob = files[0].slice(0, files[0].size, files[0].type)
                    let newFile = new File([blob], files[0].name.replace(/\s/g, "-"), {type: files[0].type})
                    const formData = new FormData()
                    formData.append("productImage", newFile)
    
                    if(this.state.imgFileName !== "No file selected.") {
                        let deleteFile = new File(["yes"], "delete", { type: "text/plain" })
                        let imgNameFile = new File([`${this.state.deleteImgName}`], "imgName", { type: "text/plain" })
    
                        formData.append("delete", deleteFile)
                        formData.append("imgName", imgNameFile)
                    }
    
                    let productImgRes = await axios.post("/api/admin/add/product-img", formData)
    
                    e.target.value = null
    
                    this.setState({
                        img: productImgRes.data[0],
                        imgFileName: productImgRes.data[1],
                        deleteImgName: productImgRes.data[2]
                    })
                }
                else {
                    alert("Image file must be a png, bmp, jpeg or jpg image")
                }
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    addProduct = async () => {
        try {
            let addBtn = document.getElementById("admin_add_product_add_btn")
            addBtn.innerHTML = ""
            addBtn.classList.add("spinner")

            if(!this.state.name.length) {
                alert("You must have a name product")

                addBtn.innerHTML = "Add"
                addBtn.classList.remove("spinner") 
            }
            else {
                let productRes = await axios.post("/api/admin/add/product", { 
                    category: this.props.category.id, 
                    name: this.state.name, 
                    img: this.state.img, 
                    description: this.state.description, 
                    section: this.props.section.id,
                    order: this.props.productsLength + 1
                })
                
                alert(productRes.data)
                
                addBtn.innerHTML = "Add"
                addBtn.classList.remove("spinner")

                this.setState({
                    img: "/Home/default.png",
                    name: "",
                    description: "",
                    imgFileName: "No file selected.",
                    deleteImgName: ""
                }, async () => {
                    await this.props.getProductsAgain()
                    this.props.changeToView()
                })
            }
        }
        catch(err) {
            let addBtn = document.getElementById("admin_add_product_add_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            addBtn.innerHTML = "Add"
            addBtn.classList.remove("spinner")
        }
    }

    render() {
        const { REACT_APP_SERVER_HOST } = process.env
        return (
            <div className="admin_add_product_container">
                <h4>
                    <strong>{this.props.category.name} Products:</strong>
                </h4>
                <div className="admin_store_form_preview_container">
                    <div className="admin_store_form_container">
                        <div className="admin_store_input_container">
                            <p>Image (250 x 280):</p>
                            <div className="admin_store_file_upload_container">
                                <input type="file" 
                                className="admin_add_product_upload_input" 
                                accept="image/*" 
                                onChange={this.uploadProductImg}/>
                                <p>{this.state.imgFileName}</p>
                            </div>
                        </div>
                        <div className="admin_store_input_container">
                            <p>Name:</p>
                            <input type="text" 
                            name="name" 
                            placeholder="Name" 
                            value={this.state.name} 
                            onChange={this.updateInput}/>
                        </div>
                    </div>
                    <div className="admin_add_product_preview_container">
                        <div className="admin_add_product_preview">
                            <div className="admin_add_product_preview_content">
                                <div className="admin_add_product_preview_img_container">
                                    <img src={`${REACT_APP_SERVER_HOST}/Home/yellow_edge.png`} alt="" className="fading_img" style={{ backgroundImage: `url("${REACT_APP_SERVER_HOST}${this.state.img}")` }}/>
                                </div>
                                <div className="admin_add_product_preview_info_container">
                                    <h4>
                                        <strong>{this.state.name}</strong>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="admin_add_product_btn_container">
                    <button onClick={this.props.changeToView}>Cancel</button>
                    <button id="admin_add_product_add_btn" disabled={!this.state.name.length} onClick={this.addProduct}>Add</button>
                </div>
            </div>
        )
    }
}

export default AdminAddProduct