import React, { Component } from "react"
import axios from "axios"

import AdminViewFeaturedProducts from "./Components/AdminViewFeaturedProducts/AdminViewFeaturedProducts"
import AdminEditFeaturedProduct from "./Components/AdminEditFeaturedProduct/AdminEditFeaturedProduct"

class AdminFeaturedProducts extends Component {
    constructor(props) {
        super(props)

        this.state = {
            panelType: "view",
            products: [],
            allProducts: [],
            pages: 1,
            pageNumber: 1,
            offset: 0,
            editProduct: {}
        }
    }

    async componentDidMount() {
        let productsRes = await axios.get(`/api/admin/get/initial/featured-products/${this.props.category.id}`)
        let allProductsRes = await axios.get(`/api/admin/get/all/featured-products/${this.props.category.id}`)

        let pages = productsRes.data[0] === 0 ? 1 : Math.ceil(productsRes.data[0] / 10)

        this.setState({
            products: productsRes.data[1],
            allProducts: allProductsRes.data,
            pages: pages,
            pageNumber: 1,
            offset: 0
        })
    }

    changeToView = () => {
        this.setState({
            panelType: "view"
        })
    }

    changeToEdit = (product) => {
        this.setState({
            panelType: "edit",
            editProduct: product
        })
    }

    getProductsAgain = async () => {
        let productsRes = await axios.get(`/api/admin/get/initial/featured-products/${this.props.category.id}`)
        let allProductsRes = await axios.get(`/api/admin/get/all/featured-products/${this.props.category.id}`)

        let pages = productsRes.data[0] === 0 ? 1 : Math.ceil(productsRes.data[0] / 10)

        this.setState({
            products: productsRes.data[1],
            allProducts: allProductsRes.data,
            pages: pages,
            pageNumber: 1,
            offset: 0
        })
    }

    previousPage = async () => {
        try {
            if(this.state.pageNumber === 1) {
                return
            }
            else {
                let productsRes = await axios.get(`/api/admin/get/featured-products/${this.props.category.id}/${this.state.offset - 10}`)

                this.setState({
                    products: productsRes.data,
                    pageNumber: this.state.pageNumber - 1,
                    offset: this.state.offset - 10
                })
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    nextPage = async () => {
        try {
            if(this.state.pageNumber === this.state.pages) {
                return
            }
            else {
                let productsRes = await axios.get(`/api/admin/get/featured-products/${this.props.category.id}/${this.state.offset + 10}`)

                this.setState({
                    products: productsRes.data,
                    pageNumber: this.state.pageNumber + 1,
                    offset: this.state.offset + 10
                })
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    render() {
        return (
            <div className="admin_featured_products">
                {
                    this.state.panelType === "view"
                    ?
                        <AdminViewFeaturedProducts changeToView={this.props.changeToView} 
                        products={this.state.products} 
                        previousPage={this.previousPage} 
                        nextPage={this.nextPage} 
                        getProductsAgain={this.getProductsAgain} 
                        pages={this.state.pages} 
                        pageNumber={this.state.pageNumber} 
                        changeToEdit={this.changeToEdit} 
                        category={this.props.category} />
                    :
                        <AdminEditFeaturedProduct changeToView={this.changeToView} 
                        product={this.state.editProduct} 
                        getProductsAgain={this.getProductsAgain} 
                        products={this.state.allProducts} />
                }
            </div>
        )
    }
}

export default AdminFeaturedProducts