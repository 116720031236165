import React, { Component } from "react"

import AdminHomeContainers from "./Components/AdminHomeContainers/AdminHomeContainers"
import AdminHomeDropDownMenus from "./Components/AdminHomeDropDowns/AdminHomeDropDownMenus"
import AdminStoreSections from "./Components/AdminStoreSections/AdminStoreSections"
import AdminStorePartners from "./Components/AdminStorePartners/StorePartners"
import "./AdminStore.scss"

class AdminStore extends Component {
    constructor(props) {
        super(props)

        this.state = {
            tab: "sections"
        }
    }

    changeToHomeTiles = () => {
        this.setState({
            tab: "home tiles"
        })
    }

    changeToHomeDropDowns = () => {
        this.setState({
            tab: "home drop down menus"
        })
    }

    changeToSections = () => {
        this.setState({
            tab: "sections"
        })
    }

    changeToPartners = () => {
        this.setState({
            tab: "partners"
        })
    }

    render() {
        let tilesClass = this.state.tab === "home tiles" ? "admin_store_nav_btn admin_store_tiles_btn admin_store_nav_active" : "admin_store_nav_btn admin_store_tiles_btn"
        let dropDownsClass = this.state.tab === "home drop down menus" ? "admin_store_nav_btn admin_store_drop_downs_btn admin_store_nav_active": "admin_store_nav_btn admin_store_drop_downs_btn"
        let sectionsClass = this.state.tab === "sections" ? "admin_store_nav_btn admin_store_sections_btn admin_store_nav_active" : "admin_store_nav_btn admin_store_sections_btn"
        let partnersClass = this.state.tab === "partners" ? "admin_store_nav_btn admin_store_partners_btn admin_store_nav_active" : "admin_store_nav_btn admin_store_partners_btn"
        return (
            <div className="admin_store">
                <nav className="admin_store_nav">
                    <li className={sectionsClass} onClick={this.changeToSections}>
                        <span>Store Sections</span>
                    </li>
                    <li className={tilesClass} onClick={this.changeToHomeTiles}>
                        <span>Home Tiles</span>
                    </li>
                    <li className={dropDownsClass} onClick={this.changeToHomeDropDowns}>
                        <span>Home Drop Down Menus</span>
                    </li>
                    <li className={partnersClass} onClick={this.changeToPartners}>
                        <span>Partners</span>
                    </li>
                </nav>
                {
                    this.state.tab === "home tiles"
                    ?
                        <AdminHomeContainers />
                    :
                    this.state.tab === "home drop down menus"
                    ?
                        <AdminHomeDropDownMenus />
                    :
                    this.state.tab === "sections"
                    ?
                        <AdminStoreSections />
                    :
                        <AdminStorePartners />
                }
            </div>
        )
    }
}

export default AdminStore