import React, { Component } from "react"
import axios from "axios"

import Tile from "./Components/Tile/Tile"
import "./TileContainer.scss"

class TileContainer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            tiles: []
        }
    }

    async componentDidMount() {
        let tilesRes = await axios.get(`/api/get/store/home-tiles/${this.props.container.id}`)

        this.setState({
            tiles: tilesRes.data
        })
    }

    render() {
        let style = this.state.tiles.length ? { "display": "block" } : { "display": "none" }
        let tiles = this.state.tiles.map((tile, i) => {
            return (
                <Tile key={i} tile={tile} tileClicked={this.props.tileClicked} scrollToSection={this.props.scrollToSection} />
            )
        })
        return (
            <div className="tile_container" style={style}>
                {
                    this.props.container.type === "one img one by two"
                    ?
                        <div className="one_img_one_by_two">
                            {tiles}
                        </div>
                    :
                    this.props.container.type === "two img one by two"
                    ?
                        <div className="two_img_one_by_two">
                            {tiles}
                        </div>
                    :
                    this.props.container.type === "two img two by one"
                    ?
                        <div className="two_img_two_by_one">
                            {tiles}
                        </div>
                    :
                    this.props.container.type === "one img two by one"
                    ?
                        <div className="one_img_two_by_one">
                            {tiles}
                        </div>
                    :
                        <div className="one_img_two_by_two">
                            {tiles}
                        </div>
                }
            </div>
        )
    }
}

export default TileContainer