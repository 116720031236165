import React, { useState } from "react"

import "./MusicBlock.scss"

let MusicBlock = (props) => {
    const [albumPlayerStyle, setAblumPlayerStyle] = useState({"maxHeight": "0px"})
    const [albumUrl, setalbumUrl] = useState("")
    const [footerName, setFooterName] = useState("")
    const [footerOpacity, setFooterOpacity] = useState({"opacity": "0"})
    const [collapseBtnStyle, setCollapseBtnStyle] = useState({"display": "none"})
    const { REACT_APP_SERVER_HOST } = process.env

    let listenVolume1 = () => {
        let url = "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/27240861&auto_play=false&hide_related=false&visual=true"
        setalbumUrl(url)
        setAblumPlayerStyle({"maxHeight": "500px"})
        setCollapseBtnStyle({"display": "flex"})
    }

    let lisetnVolume2 = () => {
        let url = "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/23229346&auto_play=false&hide_related=false&visual=true"
        setalbumUrl(url)
        setAblumPlayerStyle({"maxHeight": "500px"})
        setCollapseBtnStyle({"display": "flex"})
    }

    let volume1MouseEnter = () => {
        setFooterName("Terraria Soundtrack Volume 1")
        setFooterOpacity({"opacity": "1"})
    }

    let volume1MouseLeave = () => {
        setFooterOpacity({"opacity": "0"})
    }

    let volume2MouseEnter = () => {
        setFooterName("Terraria Soundtrack Volume 2")
        setFooterOpacity({"opacity": "1"})
    }

    let volume2MouseLeave = () => {
        setFooterOpacity({"opacity": "0"})
    }

    let collapsePlayer = () => {
        setalbumUrl("")
        setAblumPlayerStyle({"maxHeight": "0px"})
        setCollapseBtnStyle({"display": "none"})
    }

    return (
        <div className="music_block">
            <div className="panel_dropshadow">
                <div className="wall" id="music_gallery">
                    <div className="wall_grass_top"></div>
                    <header className="wall_header"></header>
                    <div className="wall_background">
                        <div className="wall_fade_in"></div>
                        <div className="wall_content">
                            <div className="album_list">
                                <div className="album_block" onMouseEnter={volume1MouseEnter} onMouseLeave={volume1MouseLeave}>
                                    <img src={`${REACT_APP_SERVER_HOST}/Media/volume_1.jpg`} alt="volume_1"/>
                                    <div className="album_options">
                                        <a className="option_buy" href="https://re-logic.bandcamp.com/album/terraria-soundtrack">buy</a>
                                        <div className="option_listen" onClick={listenVolume1}>listen</div>
                                    </div>
                                </div>
                                <div className="album_block" onMouseEnter={volume2MouseEnter} onMouseLeave={volume2MouseLeave}>
                                    <img src={`${REACT_APP_SERVER_HOST}/Media/volume_2.jpg`} alt="volume_2"/>
                                    <div className="album_options">
                                        <a className="option_buy" href="https://re-logic.bandcamp.com/album/terraria-soundtrack-volume-2">buy</a>
                                        <div className="option_listen" onClick={lisetnVolume2}>listen</div>
                                    </div>
                                </div>
                            </div>
                            <div className="album_player_wrapper" style={albumPlayerStyle}>
                                <iframe width="100%" 
                                height="500" 
                                scrolling="no" 
                                frameBorder="no" 
                                title="music_player"
                                src={albumUrl}></iframe>
                            </div>
                        </div>
                        <div className="wall_footer">
                            <div className="wall_footer_content">
                                <h3 style={footerOpacity}>{footerName}</h3>
                                <div className="collapse_album_player_btn" style={collapseBtnStyle} onClick={collapsePlayer}>
                                    <img width="14" height="14" src={`${REACT_APP_SERVER_HOST}/Media/collapse_icon.png`} alt="collapse"/>
                                    <p>Hide Player</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MusicBlock