import React from "react"

import AdminPartner from "./Component/AdminPartner/AdminPartner"
import "./ViewPartners.scss"

let ViewPartners = (props) => {
    let partners = props.partners.map((partner, i) => {
        return (
            <AdminPartner key={i} partner={partner} getPartnersAgain={props.getPartnersAgain} changeToEdit={props.changeToEdit} />
        )
    })
    return (
        <div className="admin_store_view_store_partners">
            <div className="admin_store_view_partners_page_info_container">
                <h4>
                    Partners page - {props.pageNumber}
                </h4>
                <button onClick={props.changeToAdd}>New</button>
            </div>
            <div className="admin_store_view_partners_partners_container">
                {partners}
            </div>
            <div className="admin_store_view_partners_previous_next_container">
                <button disabled={props.pageNumber === 1} onClick={props.previousPage}>Previous</button>
                <button disabled={props.pageNumber === props.pages} onClick={props.nextPage}>Next</button>
            </div>
        </div>
    )
}

export default ViewPartners