import React from "react"
import axios from "axios"

import "./AdminPartner.scss"

let AdminPartner = (props) => {
    let deletePartner = async () => {
        try {
            let deleteBtn = document.getElementById(`admin_store_partner_delete_btn_${props.partner.id}`)
            deleteBtn.innerHTML = ""
            deleteBtn.classList.add("spinner")

            if(window.confirm("Are you sure you want to delete this partner?")) {
                let img = props.partner.img.split("StorePartners/")[1]
    
                let deleteRes = await axios.delete(`/api/admin/delete/partner/${props.partner.id}/${img}`)
    
                alert(deleteRes.data)
    
                deleteBtn.innerHTML = "Delete"
                deleteBtn.classList.remove("spinner")
    
                props.getPartnersAgain()
            }
            else {
                deleteBtn.innerHTML = "Delete"
                deleteBtn.classList.remove("spinner")
            }
        }
        catch(err) {
            let deleteBtn = document.getElementById(`admin_store_partner_delete_btn_${props.partner.id}`)

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            deleteBtn.innerHTML = "Delete"
            deleteBtn.classList.remove("spinner")
        }
    }

    let changeToEdit = () => {
        props.changeToEdit(props.partner)
    }

    return (
        <div className="admin_store_partner admin_store_list_item">
            <p>{props.partner.name}</p>
            <div className="admin_store_partner_btn_container admin_store_list_item_btn_container">
                <button onClick={changeToEdit}>Edit</button>
                <button id={`admin_store_partner_delete_btn_${props.partner.id}`} className="admin_store_partner_delete_btn" onClick={deletePartner}>Delete</button>
            </div>
        </div>
    )
}

export default AdminPartner