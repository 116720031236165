import React from "react"
import { Link } from "react-router-dom"

import "./GalleryCard.scss"

let GalleryCard = (props) =>  {
    const { REACT_APP_SERVER_HOST } = process.env

    let mouseEnter = () => {
        props.mouseEnter(props.gallery.name)
    }

    let mouseLeave = () => {
        props.mouseLeave()
    }

    return (
        <div className="gallery_card" onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
            <Link to={`/media/${props.gallery.safe_name}`} className="gallery_card_link">
                <img src={`${REACT_APP_SERVER_HOST}${props.gallery.cover_image}`} alt="gallery" width="170" height="120" />
            </Link>
        </div>
    )
}

export default GalleryCard