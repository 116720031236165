import React, { Component } from "react"
import { Redirect } from "react-router-dom"
import axios from "axios"

import Navbar from "../../ReuseableComponents/Navbar/Navbar"
import "./AdminResetPassword.scss"

class AdminResetPassword extends Component {
    constructor(props) {
        super(props)

        const {
            REACT_APP_SERVER_HOST
        } = process.env

        this.state = {
            pageStatus: "loading",
            user : {},
            password: "",
            confirmPassword: "",
            passwordIcon: `${REACT_APP_SERVER_HOST}/PasswordVisibility/hide_password.png`,
            confirmPasswordIcon: `${REACT_APP_SERVER_HOST}/PasswordVisibility/hide_password.png`,
            passwordVisibility: "hide",
            confirmPasswordVisibility: "hide"
        }
    }

    async componentDidMount() {
        try {
            const urlParams = new URLSearchParams(this.props.location.search)
            let email = urlParams.get("email")
            let token = urlParams.get("token")
            if(email === null || token === null) {
                this.setState({
                    pageStatus: "redirect"
                })
            }
            else {
                let resetPasswordRes = await axios.put("/auth-serv/admin/check/reset-password", { email: email, token: token})
                this.setState({
                    pageStatus: "authorized",
                    user: resetPasswordRes.data
                })
            }
        }
        catch(err) {
            if(err.response.status) {
                if(err.response.status === 401 || err.response.status === 403 || err.response.status === 500) {
                    if(err.response.data) {
                        alert(err.response.data)

                        this.setState({
                            pageStatus: "redirect"
                        })
                    }
                }
            }
        }
    }

    showHidePassword = () => {
        const {
            REACT_APP_SERVER_HOST
        } = process.env

        let passwordInput = document.getElementById("reset_password_input")

        if(this.state.passwordVisibility === "hide") {
            passwordInput.type = "text"

            this.setState({
                passwordVisibility: "show",
                passwordIcon: `${REACT_APP_SERVER_HOST}/PasswordVisibility/show_password.png`
            })
        }
        else {
            passwordInput.type = "password"

            this.setState({
                passwordVisibility: "hide",
                passwordIcon: `${REACT_APP_SERVER_HOST}/PasswordVisibility/hide_password.png`
            })
        }
    }

    showHideConfirmPassword = () => {
        const {
            REACT_APP_SERVER_HOST
        } = process.env

        let passwordInput = document.getElementById("confirm_reset_password_input")

        if(this.state.confirmPasswordVisibility === "hide") {
            passwordInput.type = "text"

            this.setState({
                confirmPasswordVisibility: "show",
                confirmPasswordIcon: `${REACT_APP_SERVER_HOST}/PasswordVisibility/show_password.png`
            })
        }
        else {
            passwordInput.type = "password"

            this.setState({
                confirmPasswordVisibility: "hide",
                confirmPasswordIcon: `${REACT_APP_SERVER_HOST}/PasswordVisibility/hide_password.png`
            })
        }
    }

    updateInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    resetPassword = async () => {
        try {
            let resetPasswordBtn = document.getElementById("change_password_btn")
            resetPasswordBtn.innerHTML = ""
            resetPasswordBtn.classList.add("spinner")

            if(this.state.password === "" || this.state.confirmPassword === "") {
                alert("Please fill out both password fields")

                resetPasswordBtn.innerHTML = "Reset Password"
                resetPasswordBtn.classList.remove("spinner")
            }
            else {
                if(this.state.password === this.state.confirmPassword) {
                    let resetPasswordRes = await axios.put("/auth-serv/admin/change/password", {id: this.state.user.id, email: this.state.user.email, password: this.state.password})

                    alert(resetPasswordRes.data)

                    resetPasswordBtn.innerHTML = "Reset Password"
                    resetPasswordBtn.classList.remove("spinner")

                    this.props.history.push("/auth/sign_in")
                }
                else {
                    alert("Passwords do not match")

                    resetPasswordBtn.innerHTML = "Reset Password"
                    resetPasswordBtn.classList.remove("spinner")
                }
            }   
        }
        catch(err) {
            let resetPasswordBtn = document.getElementById("change_password_btn")
            
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
            
            resetPasswordBtn.innerHTML = "Reset Password"
            resetPasswordBtn.classList.remove("spinner")
        }
    }

    render() {
        return (
            this.state.pageStatus === "loading"
            ?
                <div></div>
            :
            this.state.pageStatus === "authorized"
            ?
                <div className="main_wrapper">
                    <div>
                        <a href="/">
                            <div className="logo"></div>
                        </a>
                        <Navbar />
                        <div className="panel_dropshadow">
                            <div className="reset_password_panel">
                                <div className="wall_grass_top"></div>
                                <header className="reset_password_header">
                                    <h3 style={{"margin": "0"}}>
                                        <b>Reset your password</b>
                                    </h3>
                                </header>
                                <div className="reset_password_content">
                                    <div className="reset_password_input_container">
                                        <p>Password:</p>
                                        <input type="password" 
                                        name="password" 
                                        id="reset_password_input" 
                                        placeholder="Password" 
                                        value={this.state.password} 
                                        onChange={this.updateInput}/>
                                        <img src={this.state.passwordIcon} alt="eye" onClick={this.showHidePassword}/>
                                    </div>
                                    <div className="reset_password_input_container">
                                        <p>Confirm Password:</p>
                                        <input type="password" 
                                        name="confirmPassword" 
                                        id="confirm_reset_password_input" 
                                        placeholder="Confirm Password" 
                                        value={this.state.confirmPassword} 
                                        onChange={this.updateInput}/>
                                        <img src={this.state.confirmPasswordIcon} alt="eye" onClick={this.showHideConfirmPassword}/>
                                    </div>
                                    <div className="reset_password_btn_container">
                                        <button onClick={this.resetPassword} 
                                        disabled={!this.state.password || !this.state.confirmPassword} 
                                        id="change_password_btn">Reset Password</button>
                                    </div>
                                </div>
                                <div className="wall_fade_out"></div>
                                <div className="wall_footer_none"></div>
                            </div>
                        </div>
                    </div>
                </div>
            :
                <Redirect to={{pathname: "/"}}/>
        )
    }
}

export default AdminResetPassword