import React, { Component } from "react"
import axios from "axios"

import AdminViewTiles from "./Components/AdminViewTiles/AdminViewTiles"
import AdminAddTile from "./Components/AdminAddTile/AdminAddTile"
import AdminEditTile from "./Components/AdminEditTile/AdminEditTile"
import "./AdminHomeTiles.scss"

class AdminHomeTiles extends Component {
    constructor(props) {
        super(props)

        this.state = {
            panelType: "view",
            tiles: [],
            editTile: {},
            categories: []
        }
    }

    async componentDidMount() {
        let tilesRes = await axios.get(`/api/admin/get/tiles/${this.props.container.id}`)
        let categoriesRes = await axios.get("/api/admin/get/published/store-categories")

        this.setState({
            tiles: tilesRes.data,
            categories: categoriesRes.data
        })
    }

    changeToView = () => {
        this.setState({
            panelType: "view"
        })
    }

    changeToAdd = () => {
        this.setState({
            panelType: "add"
        })
    }

    changeToEdit = (tile) => {
        this.setState({
            panelType: "edit",
            editTile: tile
        })
    }

    getTilesCategoriesAgain = async () => {
        let tilesRes = await axios.get(`/api/admin/get/tiles/${this.props.container.id}`)
        let categoriesRes = await axios.get("/api/admin/get/published/store-categories")

        this.setState({
            tiles: tilesRes.data,
            categories: categoriesRes.data
        })
    }

    render() {
        return (
            <div className="admin_home_tiles">
                {
                    this.state.panelType === "view"
                    ?
                        <AdminViewTiles changeToContainers={this.props.changeToContainers} 
                        container={this.props.container} 
                        tiles={this.state.tiles} 
                        changeToAdd={this.changeToAdd} 
                        getTilesCategoriesAgain={this.getTilesCategoriesAgain} 
                        changeToEdit={this.changeToEdit} />
                    :
                    this.state.panelType === "add"
                    ?
                        <AdminAddTile changeToView={this.changeToView} 
                        container={this.props.container} 
                        getTilesCategoriesAgain={this.getTilesCategoriesAgain} 
                        categories={this.state.categories} />
                    :
                        <AdminEditTile changeToView={this.changeToView} 
                        tile={this.state.editTile} 
                        getTilesCategoriesAgain={this.getTilesCategoriesAgain} 
                        container={this.props.container} 
                        categories={this.state.categories} />
                }
            </div>
        )
    }
}

export default AdminHomeTiles