import React, { Component } from "react"
import axios from "axios"

import AdminStoreSectionHeaderPreviewModel from "../AdminStoreSectionHeaderPreviewModel/AdminStoreSectionHeaderPreviewModel"
import "./AdminAddStoreSection.scss"

class AdminAddStoreSection extends Component {
    constructor(props) {
        super(props)

        this.state = {
            storeSectionName: "",
            storeSectionFileName: "No file selected.",
            storeSectionHeader: "",
            imgDeleteName: "No file selected.",
            previewShow: false
        }

        this.storePreviewRef = React.createRef()
        this.previewHeaderRef = React.createRef()
    }

    async componentDidMount() {
        window.addEventListener("beforeunload", this.cleanup)
    }

    async componentWillUnmount() {
        window.removeEventListener("beforeunload", this.cleanup)
    }

    cleanup = async () => {
        if(this.state.storeSectionFileName !== "No file selected.") {
            let imgFileName = this.state.imgDeleteName

            await axios.delete(`/api/admin/delete/store-section/header-image/${imgFileName}`)
        }
    }

    updateInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    uploadHeaderImg = async (e) => {
        try {
            const files = e.target.files

            if(files.length > 1) {
                alert("You can only upload one image")
            }
            else {
                if(files[0].type === "image/png" || files[0].type === "image/bmp" || files[0].type === "image/jpeg" || files[0].type === "image/jpg" || files[0].type === "image/gif" || files[0].type === "image/x-icon") {
                    let blob = files[0].slice(0, files[0].size, files[0].type)
                    let newFile = new File([blob], files[0].name.replace(/\s/g, "-"), {type: files[0].type})
                    const formData = new FormData()
                    formData.append("headerImage", newFile)

                    if(this.state.storeSectionFileName !== "No file selected.") {
                        let deleteFile = new File(["yes"], "delete", { type: "text/plain" })
                        let imgNameFile = new File([`${this.state.imgDeleteName}`], "imgName", { type: "text/plain" })

                        formData.append("delete", deleteFile)
                        formData.append("imgName", imgNameFile)
                    }

                    let headerImgRes = await axios.post("/api/admin/add/store-section-header", formData)

                    e.target.value = null

                    this.setState({
                        storeSectionHeader: headerImgRes.data[0],
                        storeSectionFileName: headerImgRes.data[1],
                        imgDeleteName: headerImgRes.data[2]
                    })
                }
                else {
                    alert("Image file must be a png, bmp, jpeg or jpg image")
                }
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    addStoreSection = async () => {
        try {
            let addStoreSectionBtn = document.getElementById("admin_store_section_add_btn")
            addStoreSectionBtn.innerHTML = ""
            addStoreSectionBtn.classList.add("spinner")

            if(this.state.storeSectionName.length && this.state.storeSectionHeader) {
                let storeSectionRes = await axios.post("/api/admin/add/store-section", { name: this.state.storeSectionName, header: this.state.storeSectionHeader, order: this.props.sectionsLength + 1})

                alert(storeSectionRes.data)

                addStoreSectionBtn.innerHTML = "Add"
                addStoreSectionBtn.classList.remove("spinner")

                this.setState({
                    storeSectionName: "",
                    storeSectionFileName: "No file selected.",
                    storeSectionHeader: ""
                }, async () => {
                    await this.props.getStoreSectionsAgain()
                    this.props.changeToView()
                })
            }
            else {
                alert("You must have a name for the section and a header image for the section")

                addStoreSectionBtn.innerHTML = "Add"
                addStoreSectionBtn.classList.remove("spinner")
            }
        }
        catch(err) {
            let addStoreSectionBtn = document.getElementById("admin_store_section_add_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            addStoreSectionBtn.innerHTML = "Add"
            addStoreSectionBtn.classList.remove("spinner")
        }
    }

    openClosePreview = () => {
        if(this.state.storeSectionHeader) {
            this.setState({
                previewShow: !this.state.previewShow
            })
        }
        else {
            alert("You must have a header image to preview")
        }
    }

    changeToView = () => {
        this.setState({
            storeSectionName: "",
            storeSectionFileName: "No file selected.",
            storeSectionHeader: "",
            deleteImgName: "No file selected.",
            previewShow: false
        }, () => this.props.changeToView())
    }

    render() {
        return (
            <div className="admin_add_store_sections">
                <div className="admin_add_store_section_container">
                    <h4>
                        <strong>Store Sections:</strong>
                    </h4>
                    <div className="admin_store_form_container">
                        <div className="admin_store_input_container">
                            <p>Name:</p>
                            <input type="text" 
                            placeholder="Name" 
                            name="storeSectionName" 
                            value={this.state.storeSectionName} 
                            onChange={this.updateInput}/>
                        </div>
                        <div className="admin_store_input_container">
                            <p>Header Image (970 x 78):</p>
                            <div className="admin_store_file_upload_container">
                                <input type="file" 
                                accept="image/*" 
                                onChange={this.uploadHeaderImg}/>
                                <p>{this.state.storeSectionFileName}</p>
                            </div>
                        </div>
                    </div>
                    <div className="admin_store_section_btn_container">
                        <button onClick={this.changeToView}>Cancel</button>
                        <div className="admin_store_add_section_preview_add_container">
                            <button onClick={this.openClosePreview} disabled={!this.state.storeSectionHeader.length}>Preview</button>
                            <button id="admin_store_section_add_btn" 
                            onClick={this.addStoreSection} 
                            disabled={!this.state.storeSectionName.length || !this.state.storeSectionHeader.length}>Add</button>
                        </div>
                    </div>
                </div>
                <AdminStoreSectionHeaderPreviewModel show={this.state.previewShow} openClosePreview={this.openClosePreview} storeSectionHeader={this.state.storeSectionHeader} />
            </div>
        )
    }
}

export default AdminAddStoreSection