import React, { Component } from "react"
import axios from "axios"

import Partner from "./Components/Partner/Partner"
import "./StorePartners.scss"

class StorePartners extends Component {
    constructor(props) {
        super(props)

        this.state = {
            partners: []
        }
    }

    async componentDidMount() {
        let partnersRes = await axios.get("/api/get/store/partners")

        this.setState({
            partners: partnersRes.data
        })
    }

    render() {
        let partners = this.state.partners.map((partner, i) => {
            return (
                <Partner key={i} partner={partner} />
            )
        })
        return (
            <div className="store_partners">
                <div className="panel_dropshadow">
                    <div className="wall">
                        <div className="wall_grass_top"></div>
                        <div className="partners_header"></div>
                        <div className="wall_background">
                            <div className="wall_fade_in"></div>
                            <div className="wall_content">
                                <div className="partners_container">
                                    {partners}
                                </div>
                            </div>
                            <div className="wall_fade_out"></div>
                            <div className="wall_footer_none"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default StorePartners