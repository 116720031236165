import React, { Component } from "react"

import "./GalleryImageModel.scss"

class GalleryImageModel extends Component {
    constructor(props) {
        super(props)

        this.imgContainer = React.createRef()
        this.img = React.createRef()
    }

    async componentDidUpdate(prevProps) {
        const { REACT_APP_SERVER_HOST } = process.env
        if(this.props.modelView !== prevProps.modelView) {
            if(prevProps.modelView === false && this.props.modelView === true) {
                this.img.current.style.opacity = "0"
                if(this.img.current.childNodes.length) {
                    let img = document.getElementById("image_view")
                    img.remove()
                }
                if(this.props.modelImg.path) {
                    let img = new Image()
                    img.onload = async () => {
                        let width = img.width
                        let height = img.height
                        if(height > window.innerHeight - 120) {
                            let newHeight = window.innerHeight - 120
                            width = (width / height) * newHeight
                            height = newHeight
                            img.width = width
                            img.height = height
                        }
        
                        if (width > window.innerWidth - 80) {
                            let newWidth = window.innerWidth - 80
                            height = (height / width) * newWidth
                            width = newWidth
                            img.width = width
                            img.height = height
                        }
    
                        img.setAttribute("id", "image_view")
                        img.setAttribute("width", width)
                        img.setAttribute("height", height)
        
                        this.img.current.appendChild(img)
                        this.imgContainer.current.style.marginLeft = `${(width + 40) / -2}px`
                        this.imgContainer.current.style.marginTop = `${(height + 40) / -2}px`
                        this.imgContainer.current.style.width = `${width + 40}px`
                        this.imgContainer.current.style.height = `${height + 40}px`
                        await this.fadeIn()
                        this.imgContainer.current.focus()
                    }
                    img.src = `${REACT_APP_SERVER_HOST}${this.props.modelImg.path}`
                }
            }
        }
        else {
            if(this.props.modelImg !== prevProps.modelImg) {
                if(this.img.current.childNodes.length) {
                    let img = document.getElementById("image_view")
                    await this.fadeOut()
                    img.remove()
                }
                if(this.props.modelImg.path) {
                    let img = new Image()
                    img.onload = async () => {
                        let width = img.width
                        let height = img.height
                        if(height > window.innerHeight - 120) {
                            let newHeight = window.innerHeight - 120
                            width = (width / height) * newHeight
                            height = newHeight
                            img.width = width
                            img.height = height
                        }
        
                        if (width > window.innerWidth - 80) {
                            let newWidth = window.innerWidth - 80
                            height = (height / width) * newWidth
                            width = newWidth
                            img.width = width
                            img.height = height
                        }
    
                        img.setAttribute("id", "image_view")
                        img.setAttribute("width", width)
                        img.setAttribute("height", height)
        
                        this.img.current.appendChild(img)
                        this.imgContainer.current.style.marginLeft = `${(width + 40) / -2}px`
                        this.imgContainer.current.style.marginTop = `${(height + 40) / -2}px`
                        this.imgContainer.current.style.width = `${width + 40}px`
                        this.imgContainer.current.style.height = `${height + 40}px`
                        await this.fadeIn()
                        this.imgContainer.current.focus()
                    }
                    img.src = `${REACT_APP_SERVER_HOST}${this.props.modelImg.path}`
                }
            }
        }
    }

    fadeOut = async () => {
        let timeOut = (ms) => {
            return new Promise(resolve => setTimeout(resolve, ms))
        }

        this.img.current.style.opacity = "0"
        await timeOut(300)
    }

    fadeIn = async () => {
        let timeOut = (ms) => {
            return new Promise(resolve => setTimeout(resolve, ms))
        }

        await timeOut(300)
        this.img.current.style.opacity = "1"
    }

    openCloseModel = () => {
        if(this.img.current.childNodes.length) {
            let img = document.getElementById("image_view")
            img.remove()
        }
        this.imgContainer.current.style = {}
        this.props.openCloseModel()
    }

    render() {
        let className = this.props.modelView ? "gallery_img_model_panel model_active no_select" : "gallery_img_model_panel model_inactive no_select"
        return (
            <div id="gallery_img_model" className={className} onClick={this.openCloseModel}>
                <div ref={this.imgContainer} id="img_view_container" onKeyDown={this.props.keyDown} tabIndex="0">
                    <div id="previous_img_arrow" className="img_arrow" onClick={this.props.previousImg}></div>
                    <div ref={this.img} className="img_container"></div>
                    <div id="next_img_arrow" className="img_arrow" onClick={this.props.nextImg}></div>
                </div>
            </div>
        )
    }
}

export default GalleryImageModel