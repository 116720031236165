import React, { Component } from "react"
import axios from "axios"

import "./AdminEditContainer.scss"

class AdminEditContainer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: "",
            order: 0,
            changeOrder: false,
            swapContainerId: 0,
            manualOrderReset: false
        }
    }

    componentDidMount() {
        this.setState({
            name: this.props.container.name,
            order: this.props.container.container_order
        })
    }

    componentDidUpdate(prevPops) {
        if(this.props.container.id !== prevPops.container.id) {
            this.setState({
                name: this.props.container.name,
                order: this.props.container.container_order
            })
        }
    }

    updateInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    changeContainerOrder = (e) => {
        let container = this.props.containers.find((container) => container.container_order === Number(e.target.value))

        this.setState({
            order: Number(e.target.value),
            changeOrder: true,
            swapContainerId: container.id
        })
    }

    updateContainer = async () => {
        try {
            let updateBtn = document.getElementById("admin_edit_container_update_btn")
            updateBtn.innerHTML = ""
            updateBtn.classList.add("spinner")

            if(!this.state.name) {
                alert("Container must have a name")
            }
            else {
                let updateRes = await axios.put("/api/admin/update/home-container", { id: this.props.container.id, name: this.state.name, changeOrder: this.state.changeOrder, swapContainerId: this.state.swapContainerId})

                alert(updateRes.data)

                this.setState({
                    name: "",
                    order: 0,
                    changeOrder: false,
                    swapContainerId: 0
                }, async () => {
                    await this.props.getContainersAgain()
                    this.props.changeToView()
                })
            }
        }
        catch(err) {
            let updateBtn = document.getElementById("admin_edit_container_update_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            updateBtn.innerHTML = "Update"
            updateBtn.classList.remove("spinner")
        }
    }

    toggleOrderReset = () => {
        this.setState({
            manualOrderReset: !this.state.manualOrderReset
        })
    }

    manuallyUpdateContainerOrder = async () => {
        try {
            let updateBtn = document.getElementById("manually_update_container_order_btn")
            updateBtn.innerHTML = ""
            updateBtn.classList.add("spinner")

            if( this.state.order > 2147483647 || this.state.order <= 0) {
                alert("Order must be above 0 and less than 2147483647")

                updateBtn.innerHTML = "Update Order"
                updateBtn.classList.remove("spinner")
            }
            else {
                let manualUpdateRes = await axios.put("/api/admin/update/container/order", { id: this.props.container.id, order: this.state.order })

                alert(manualUpdateRes.data)

                updateBtn.innerHTML = "Update Order"
                updateBtn.classList.remove("spinner")

                await this.props.getContainersAgain()
            }
        }
        catch(err) {
            let updateBtn = document.getElementById("manually_update_container_order_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            updateBtn.innerHTML = "Update Order"
            updateBtn.classList.remove("spinner")
        }
    }

    render() {
        let orders = this.props.containers.map((container, i) => {
            return (
                <option key={i} value={container.container_order}>{container.container_order}</option>
            )
        })
        return (
            <div className="admin_edit_container">
                <h4>
                    Edit {this.props.container.name}
                </h4>
                <div className="admin_store_form_container">
                    <div className="admin_store_input_container">
                        <p>Name:</p>
                        <input type="text" 
                        name="name" 
                        placeholder="Name" 
                        value={this.state.name} 
                        onChange={this.updateInput}/>
                    </div>
                    <div className="admin_store_input_container">
                        <p>Order:</p>
                        <select name="order" value={this.state.order} onChange={this.changeContainerOrder}>
                            {orders}
                        </select>
                    </div>
                    <div className="admin_store_input_container">
                            <p>Container order not working correctly? Click the button below to manually set this container's order</p>
                            {
                                this.state.manualOrderReset
                                ?
                                    <div className="admin_store_order_change_container">
                                        <button className="admin_store_order_reset_cancel_btn" onClick={this.toggleOrderReset}>X</button>
                                        <input type="number" 
                                        name="order" 
                                        placeholder="0" 
                                        value={this.state.order} 
                                        onChange={this.updateInput} />
                                        <button id="manually_update_container_order_btn" 
                                        className="admin_store_spinner_container" 
                                        onClick={this.manuallyUpdateContainerOrder}>Update Order</button>
                                    </div>
                                :
                                    <div className="admin_store_order_change_container">
                                        <button onClick={this.toggleOrderReset}>Set Order</button>
                                    </div>
                            }
                    </div>
                </div>
                <div className="admin_edit_container_warning_container">
                    <p>Cannot update container type if you want a different container type delete this one and create a new container</p>
                </div>
                <div className="admin_store_btn_container">
                    <button onClick={this.props.changeToView}>Cancel</button>
                    <button id="admin_edit_container_update_btn" 
                    className="admin_spinner_container" 
                    disabled={!this.state.name} 
                    onClick={this.updateContainer}>Update</button>
                </div>
            </div>
        )
    }
}

export default AdminEditContainer