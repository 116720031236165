import React, { Component } from "react"

import Navbar from "../../ReuseableComponents/Navbar/Navbar"
import "./Credits.scss"

class Credits extends Component {
    componentDidMount() {
        document.title = "Credits - Terraria"
    }
    
    render() {
        return (
            <div className="main_wrapper">
                <div className="credits">
                    <a href="/">
                        <div className="logo"></div>
                    </a>
                    <Navbar />
                    <div className="panel_dropshadow">
                        <div className="wall">
                            <div className="wall_grass_top"></div>
                            <header className="wall_header">
                                <h3>
                                    <b>Credits</b>
                                </h3>
                            </header>
                            <div className="wall_background"></div>
                            <header className="wall_mid_header">
                                <h3>
                                    <b>
                                        <center>Chief Executive Officer</center>
                                    </b>
                                </h3>
                            </header>
                            <div className="wall_background">
                                <div className="wall_mid_content_cred">
                                    <h4>
                                        <center style={{"paddingBottom": "6px"}}>Andrew “Redigit“ Spinks</center>
                                    </h4>
                                </div>
                            </div>
                            <header className="wall_mid_header">
                                <h3>
                                    <b>
                                        <center>Chief Operating Officer</center>
                                    </b>
                                </h3>
                            </header>
                            <div className="wall_background">
                                <div className="wall_mid_content_cred">
                                    <h4>
                                        <center style={{"paddingBottom": "6px"}}>Whitney “Cenx“ Spinks</center>
                                    </h4>
                                </div>
                            </div>
                            <header className="wall_mid_header">
                                <h3>
                                    <b>
                                        <center>Chief Strategy Officer</center>
                                    </b>
                                </h3>
                            </header>
                            <div className="wall_background">
                                <div className="wall_mid_content_cred">
                                    <h4>
                                        <center style={{"paddingBottom": "6px"}}>Ted “Loki“ Murphy</center>
                                    </h4>
                                </div>
                            </div>
                            <header className="wall_mid_header">
                                <h3>
                                    <b>
                                        <center>Quality Assurance</center>
                                    </b>
                                </h3>
                            </header>
                            <div className="wall_background">
                                <div className="wall_mid_content_cred">
                                    <h4>
                                        <center style={{"paddingBottom": "6px"}}>Jason "Leinfors" Parker</center>
                                        <center style={{"paddingBottom": "6px"}}>Houston “Food Barbarian“ Lawson</center>
                                        <center style={{"paddingBottom": "6px"}}>Crystal "Ghostar" Ironheart</center>
                                    </h4>
                                </div>
                            </div>
                            <header className="wall_mid_header">
                                <h3>
                                    <b>
                                        <center>Game Designers</center>
                                    </b>
                                </h3>
                            </header>
                            <div className="wall_background">
                                <div className="wall_mid_content_cred">
                                    <h4>
                                        <center style={{"paddingBottom": "6px"}}>Andrew “Redigit“ Spinks</center>
                                        <center style={{"paddingBottom": "6px"}}>Whitney “Cenx“ Spinks</center>
                                    </h4>
                                </div>
                            </div>
                            <header className="wall_mid_header">
                                <h3>
                                    <b>
                                        <center>Game Programmers</center>
                                    </b>
                                </h3>
                            </header>
                            <div className="wall_background">
                                <div className="wall_mid_content_cred">
                                    <h4>
                                        <center style={{"paddingBottom": "6px"}}>Andrew “Redigit“ Spinks</center>
                                        <center style={{"paddingBottom": "6px"}}>Yorai “Yoraiz0r“ Omer</center>
                                        <center style={{"paddingBottom": "6px"}}>Charles "Grox" Hanrahan</center>
                                        <center style={{"paddingBottom": "6px"}}>Jason "Leinfors" Parker</center>
                                    </h4>
                                </div>
                            </div>
                            <header className="wall_mid_header">
                                <h3>
                                    <b>
                                        <center>Game Artists</center>
                                    </b>
                                </h3>
                            </header>
                            <div className="wall_background">
                                <div className="wall_mid_content_cred">
                                    <h4>
                                        <center style={{"paddingBottom": "6px"}}>Andrew “Redigit“ Spinks</center>
                                        <center style={{"paddingBottom": "6px"}}>James "Lazure" Hayes</center>
                                        <center style={{"paddingBottom": "6px"}}>Jim Tommy Myhre "Jimmarn" Kjexrud</center>
                                        <center style={{"paddingBottom": "6px"}}>Victor “Crowno“ Moura</center>
                                    </h4>
                                </div>
                            </div>
                            <header className="wall_mid_header">
                                <h3>
                                    <b>
                                        <center>External Production Support</center>
                                    </b>
                                </h3>
                            </header>
                            <div className="wall_background">
                                <div className="wall_mid_content_cred">
                                    <h4>
                                        <center style={{"paddingBottom": "6px"}}>Houston “Food Barbarian“ Lawson - Lead for Nintendo Switch</center>
                                        <center style={{"paddingBottom": "6px"}}>Ted “Loki“ Murphy - Lead for Mobile</center>
                                    </h4>
                                </div>
                            </div>
                            <header className="wall_mid_header">
                                <h3>
                                    <b>
                                        <center>Community Manager</center>
                                    </b>
                                </h3>
                            </header>
                            <div className="wall_background">
                                <div className="wall_mid_content_cred">
                                    <h4>
                                        <center style={{"paddingBottom": "6px"}}>Amanda “safeman“ Powell</center>
                                    </h4>
                                </div>
                            </div>
                            <header className="wall_mid_header">
                                <h3>
                                    <b>
                                        <center>Former Contributors</center>
                                    </b>
                                </h3>
                            </header>
                            <div className="wall_background">
                                <div className="wall_mid_content_cred">
                                    <h4>
                                        <center style={{"paddingBottom": "6px"}}>Dustin “Splugen“ Gunter</center>
                                        <center style={{"paddingBottom": "6px"}}>Aaron “AaronC“ Craig</center>
                                        <center style={{"paddingBottom": "6px"}}>Will “Phlebas“ Garner</center>
                                        <center style={{"paddingBottom": "6px"}}>Finn “Tiy“ Brice</center>
                                        <center style={{"paddingBottom": "6px"}}>Jeremy “Blue“ Guerrette</center>
                                        <center style={{"paddingBottom": "6px"}}>Gabriel "Red Yoshi" Kiesshau</center>
                                        <center style={{"paddingBottom": "6px"}}>Chris “Skiphs“ Bednarz</center>
                                        <center style={{"paddingBottom": "6px"}}>Olivia “Arkhayla“ Cres</center>
                                        <center style={{"paddingBottom": "6px"}}>Mike “0x33“ Johnson</center>
                                        <center style={{"paddingBottom": "6px"}}>David “D-Town“ Phelps</center>
                                    </h4>
                                </div>
                            </div>
                            <header className="wall_mid_header">
                                <h3>
                                    <b>
                                        <center>Special Thanks</center>
                                    </b>
                                </h3>
                            </header>
                            <div className="wall_background">
                                <div className="wall_mid_content_cred">
                                    <h4>
                                        <center style={{"paddingBottom": "6px"}}>Ben Spaulding</center>
                                    </h4>
                                </div>
                            </div>
                            <header className="wall_mid_header">
                                <h3>
                                    <b>
                                        <center>Beta/QA Testers</center>
                                    </b>
                                </h3>
                            </header>
                            <div className="wall_background">
                                <div className="wall_mid_content_cred">
                                    <h4>
                                        <center style={{"paddingBottom": "6px"}}>Atri "m4sterbr0s" Maharaj</center>
                                        <center style={{"paddingBottom": "6px"}}>Brian "TGS" Gilliford</center>
                                        <center style={{"paddingBottom": "6px"}}>Mike "TrinityFlash" Dunn</center>
                                        <center style={{"paddingBottom": "6px"}}>William "Ignoritus" Coffey</center>
                                        <center style={{"paddingBottom": "6px"}}>Valtteri Nieminen</center>
                                        <center style={{"paddingBottom": "6px"}}>Ryan "Namyrr" Le</center>
                                        <center style={{"paddingBottom": "6px"}}>Gabriel "Red Yoshi" Kiesshau</center>
                                        <center style={{"paddingBottom": "6px"}}>Ragnor "Devalaous" Berntsen</center>
                                        <center style={{"paddingBottom": "6px"}}>Brandon "rbrandon" Kime</center>
                                        <center style={{"paddingBottom": "6px"}}>Clara Kane</center>
                                        <center style={{"paddingBottom": "6px"}}>Brian “blahblahbal“ Hansen</center>
                                        <center style={{"paddingBottom": "6px"}}>Jay “jeckel“ Jeckel</center>
                                        <center style={{"paddingBottom": "6px"}}>Michael “kelp“ McCarthy</center>
                                        <center style={{"paddingBottom": "6px"}}>Carwyn “Aeroblop“ Dalrymple</center>
                                        <center style={{"paddingBottom": "6px"}}>Adrian “Unrealmegashark“ Wheeler</center>
                                        <center style={{"paddingBottom": "6px"}}>Matthew "Omnir" Hunnicutt</center>
                                        <center style={{"paddingBottom": "6px"}}>Tsukina "Tsuki" Nakata</center>
                                        <center style={{"paddingBottom": "6px"}}>Suw "Suweeka" Gutierrez</center>
                                        <center style={{"paddingBottom": "6px"}}>Joshua “scizor300“ Wubker</center>
                                        <center style={{"paddingBottom": "6px"}}>Michael “shockah“ Dolas</center>
                                        <center style={{"paddingBottom": "6px"}}>Tyler "BurnZeZ" Whiteman</center>
                                        <center style={{"paddingBottom": "6px"}}>Peter “Doylee“ Doyle</center>
                                        <center style={{"paddingBottom": "6px"}}>Kyle "Kley" Hodge</center>
                                        <center style={{"paddingBottom": "6px"}}>Steven "Twitchy" French</center>
                                        <center style={{"paddingBottom": "6px"}}>Anodomani</center>
                                        <center style={{"paddingBottom": "6px"}}>Igor "Conker" Magi Marinho</center>
                                        <center style={{"paddingBottom": "6px"}}>Cheah Jun Siang</center>
                                        <center style={{"paddingBottom": "6px"}}>Zach “Olink“ Piispanen</center>
                                        <center style={{"paddingBottom": "6px"}}>Lisa "Lils" Chiu</center>
                                        <center style={{"paddingBottom": "6px"}}>iRandomness</center>
                                        <center style={{"paddingBottom": "6px"}}>Kaleb "JesusLlama" Regalado</center>
                                        <center style={{"paddingBottom": "6px"}}>Elias "Elbow" Naddaf</center>
                                        <center style={{"paddingBottom": "6px"}}>Jonathan "FallingSnow" Poholarz</center>
                                        <center style={{"paddingBottom": "6px"}}>Brandon Thomas</center>
                                        <center style={{"paddingBottom": "6px"}}>Daniel "DMF" Faria</center>
                                        <center style={{"paddingBottom": "6px"}}>Luna Meier</center>
                                        <center style={{"paddingBottom": "6px"}}>Robert “qig“ Buchanan</center>
                                        <center style={{"paddingBottom": "6px"}}>Alexaunder "as303298" Savoy</center>
                                        <center style={{"paddingBottom": "6px"}}>Alexander "Vandarx" Reaves</center>
                                        <center style={{"paddingBottom": "6px"}}>QEM - "Mystery"</center>
                                        <center style={{"paddingBottom": "6px"}}>Balázs "Mamaluigi" Makai</center>
                                        <center style={{"paddingBottom": "6px"}}>Daniel "Garro" Berner</center>
                                        <center style={{"paddingBottom": "6px"}}>Tyler "Quill" Warr</center>
                                        <center style={{"paddingBottom": "6px"}}>Andrew “Andydark“ Windmiller</center>
                                        <center style={{"paddingBottom": "6px"}}>Steven 'ppowersteef' Logghe</center>
                                        <center style={{"paddingBottom": "6px"}}>Chris "Criddle" Rohde</center>
                                        <center style={{"paddingBottom": "6px"}}>Kaz “Kazzymodus“ Tollenaar</center>
                                        <center style={{"paddingBottom": "6px"}}>Sam “darthmorf“ Poirier</center>
                                        <center style={{"paddingBottom": "6px"}}>John "Aurora3500" Witzig</center>
                                        <center style={{"paddingBottom": "6px"}}>Harley “Acamaeda“ White</center>
                                        <center style={{"paddingBottom": "6px"}}>Evan Kirkpatrick (Antithesis)</center>
                                        <center style={{"paddingBottom": "6px"}}>Keven "Waze3174" Chastenay</center>
                                        <center style={{"paddingBottom": "6px"}}>Benjamin "Jenosis" Hook</center>
                                        <center style={{"paddingBottom": "6px"}}>Connor “Darthkitten“ Schneider</center>
                                        <center style={{"paddingBottom": "6px"}}>Zach “Vulpes Inculta“ Yurek</center>
                                        <center style={{"paddingBottom": "6px"}}>James "Khaios" Sterling</center>
                                        <center style={{"paddingBottom": "6px"}}>Xavier “xman101“ Calder</center>
                                        <center style={{"paddingBottom": "6px"}}>Cynthia "Serenity" Germaine</center>
                                        <center style={{"paddingBottom": "6px"}}>W. Black /  Altermaven</center>
                                        <center style={{"paddingBottom": "6px"}}>Midnaait</center>
                                        <center style={{"paddingBottom": "6px"}}>ManaUser</center>
                                        <center style={{"paddingBottom": "6px"}}>Unit One</center>
                                        <center style={{"paddingBottom": "6px"}}>General Milky</center>
                                        <center style={{"paddingBottom": "6px"}}>Tunnel King</center>
                                        <center style={{"paddingBottom": "6px"}}>Chris Ness</center>
                                        <center style={{"paddingBottom": "6px"}}>Yrimir</center>
                                    </h4>
                                    <div className="credits_divider"></div>
                                    <div className="wall_mid_content_cred">
                                        <h4>
                                            <b>
                                                <center style={{"paddingBottom": "6px"}}>Special Thanks to the Terraria Community Forum Staff Team, Discord Team,</center>
                                                <center style={{"paddingBottom": "6px"}}>Wiki Staff, and – most importantly - our entire community of fans!</center>
                                                <center style={{"paddingBottom": "6px"}}>Your passionate support, feedback, comments, art,</center>
                                                <center style={{"paddingBottom": "6px"}}>and beyond are truly the heart and soul of Terraria.</center>
                                                <center style={{"paddingBottom": "6px"}}>We look forward to delighting you for years to come!</center>
                                            </b>
                                        </h4>
                                    </div>
                                    <div className="credits_divider_fancy"></div>
                                    <div className="wall_mid_content_cred">
                                        <h4>
                                            <center style={{"paddingBottom": "10px"}}>
                                                <a href="https://twitter.com/Terraria Logic" style={{"color": "#d6ffe4"}}>@Terraria_Logic</a>
                                            </center>
                                            <center style={{"paddingBottom": "10px"}}>
                                                <a href="https://www.facebook.com/TerrariaOfficial" style={{"color": "#d6ffe4"}}>https://www.facebook.com/TerrariaOfficial</a>
                                            </center>
                                            <center style={{"paddingBottom": "10px"}}>
                                                <a href="http://Discord.gg/Terraria" style={{"color": "#d6ffe4"}}>http://Discord.gg/Terraria</a>
                                            </center>
                                        </h4>
                                    </div>
                                    <div className="credits_divider_fancy"></div>
                                </div>
                                <div className="wall_footer_creds"></div>
                            </div>
                        </div> 
                    </div>
                    <br/>
                    <br/>
                </div>
            </div>
        )
    }
}

export default Credits