import axios from "axios"
import React, { Component } from "react"

import "./AdminAddLink.scss"

class AdminAddLink extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: "",
            url: "",
            img: "",
            imgFileName: "No file selected.",
            imgDeleteName: ""
        }
    }

    updateInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    uploadLinkImage = async (e) => {
        try {
            const files = e.target.files
    
            if(files.length > 1) {
                alert("You can only upload one image")
            }
            else {
                if(files[0].type === "image/png" || files[0].type === "image/bmp" || files[0].type === "image/jpeg" || files[0].type === "image/jpg" || files[0].type === "image/gif" || files[0].type === "image/x-icon") {
                    let blob = files[0].slice(0, files[0].size, files[0].type)
                    let newFile = new File([blob], files[0].name.replace(/\s/g, "-"), {type: files[0].type})
                    const formData = new FormData()
                    formData.append("linkImage", newFile)
    
                    if(this.state.imgFileName !== "No file selected.") {
                        let deleteFile = new File(["yes"], "delete", { type: "text/plain" })
                        let imgNameFile = new File([`${this.state.imgDeleteName}`], "imgName", { type: "text/plain" })
    
                        formData.append("delete", deleteFile)
                        formData.append("imgName", imgNameFile)
                    }
    
                    let linkImgRes = await axios.post("/api/admin/add/link-img", formData)
    
                    e.target.value = null
    
                    this.setState({
                        img: linkImgRes.data[0],
                        imgFileName: linkImgRes.data[1],
                        imgDeleteName: linkImgRes.data[2]
                    })
                }
                else {
                    alert("Image file must be a png, bmp, jpeg or jpg image")
                }
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    addLink = async () => {
        try {
            let addBtn = document.getElementById("admin_add_link_add_btn")
            addBtn.innerHTML = ""
            addBtn.classList.add("spinner")

            let regExp = /^(ftp|http|https):\/\/[^ "]+$/

            if(this.state.name && this.state.url.match(regExp) && this.state.img) {
                let linkRes = await axios.post("/api/admin/add/link", { product: this.props.product.id, name: this.state.name, url: this.state.url, order: this.props.linksLength + 1, img: this.state.img })

                alert(linkRes.data)

                addBtn.innerHTML = "Add"
                addBtn.classList.remove("spinner")

                this.setState({
                    name: "",
                    url: "",
                    img: "",
                    imgFileName: "No file selected.",
                    imgDeleteName: ""
                }, async () => {
                    await this.props.getLinksAgain()
                    this.props.changeToView()
                })
                
            }
            else {
                alert("Link must have a name, valid url, and an image")

                addBtn.innerHTML = "Add"
                addBtn.classList.remove("spinner")
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    render() {
        const { REACT_APP_SERVER_HOST } = process.env
        let img = this.state.img ? this.state.img : "/Home/default.png"
        return (
            <div className="admin_add_link">
                <div className="admin_store_form_preview_container">
                    <div className="admin_store_form_container">
                        <div className="admin_store_input_container">
                            <p>Image (50 x 50):</p>
                            <div className="admin_store_file_upload_container">
                                <input type="file" 
                                accept="image/*" 
                                onChange={this.uploadLinkImage}/>
                                <p>{this.state.imgFileName}</p>
                            </div>
                        </div>
                        <div className="admin_store_input_container">
                            <p>Name:</p>
                            <input type="text" 
                            name="name" 
                            placeholder="Name" 
                            value={this.state.name} 
                            onChange={this.updateInput}/>
                        </div>
                        <div className="admin_store_input_container">
                            <p>URL:</p>
                            <input type="text" 
                            name="url" 
                            placeholder="http://example.com/product" 
                            value={this.state.url} 
                            onChange={this.updateInput}/>
                        </div>
                    </div>
                    <div className="admin_store_preview_container">
                        <img src={`${REACT_APP_SERVER_HOST}${img}`} alt=""/>
                    </div>
                </div>
                <div className="admin_store_btn_container">
                    <button onClick={this.props.changeToView}>Cancel</button>
                    <button id="admin_add_link_add_btn" className="admin_spinner_container" disabled={!this.state.name.length || !this.state.img} onClick={this.addLink}>Add</button>
                </div>
            </div>
        )
    }
}

export default AdminAddLink