import React, { Component } from "react"
import axios from "axios"

import AdminViewHomeDropDownMenus from "./Components/AdminViewHomeDropDownMenus/AdminViewHomeDropDownMenus"
import AdminAddHomeDropDownMenu from "./Components/AdminAddHomeDropDownMenu/AdminAddHomeDropDownMenu"
import AdminEditHomeDropDownMenu from "./Components/AdminEditHomeDropDownMenu/AdminEditHomeDropDownMenu"
import "./AdminHomeDropDowns.scss"

class AdminHomeDropDowns extends Component {
    constructor(props) {
        super(props)

        this.state = {
            panelType: "view",
            dropDownMenus: [],
            secions: [],
            allDropDownMenus: [],
            pages: 0,
            offset: 0,
            pageNumber: 1,
            editDropDownMenu: {}
        }
    }

    async componentDidMount() {
        let dropDownMenusRes = await axios.get("/api/admin/get/initial/home-drop-down-menus")
        let sectionsRes = await axios.get("/api/admin/get/published/store-sections")
        let allDropDownMenusRes = await axios.get("api/admin/get/all/home-drop-down-menus")

        let pages = dropDownMenusRes.data[0] === 0 ? 1 : Math.ceil(dropDownMenusRes.data[0] / 10)

        this.setState({
            pages: pages,
            dropDownMenus: dropDownMenusRes.data[1],
            allDropDownMenus: allDropDownMenusRes.data,
            sections: sectionsRes.data,
            pageNumber: 1,
            offset: 0
        })
    }

    changeToView = () => {
        this.setState({
            panelType: "view"
        })
    }

    changeToAdd = () => {
        this.setState({
            panelType: "add"
        })
    }

    getSectionsDropDownMenus = async () => {
        let dropDownMenusRes = await axios.get("/api/admin/get/initial/home-drop-down-menus")
        let sectionsRes = await axios.get("/api/admin/get/published/store-sections")
        let allDropDownMenusRes = await axios.get("api/admin/get/all/home-drop-down-menus")

        let pages = dropDownMenusRes.data[0] === 0 ? 1 : Math.ceil(dropDownMenusRes.data[0] / 10)

        this.setState({
            pages: pages,
            dropDownMenus: dropDownMenusRes.data[1],
            allDropDownMenus: allDropDownMenusRes.data,
            sections: sectionsRes.data,
            pageNumber: 1,
            offset: 0
        })
    }

    previousPage = async () => {
        let offset = this.state.offset - 10

        let dropDownMenusRes = await axios.get(`/api/admin/get/home-drop-down-menus/${offset}`)

        let pageNumber = this.state.pageNumber - 1

        this.setState({
            dropDownMenus: dropDownMenusRes.data,
            offset: offset,
            pageNumber: pageNumber
        })
    }

    nextPage = async() => {
        let offset = this.state.offset + 10

        let dropDownMenusRes = await axios.get(`/api/admin/get/home-drop-down-menus/${offset}`)

        let pageNumber = this.state.pageNumber + 1

        this.setState({
            dropDownMenus: dropDownMenusRes.data,
            offset: offset,
            pageNumber: pageNumber
        })
    }

    changeToEdit = (dropDownMenu) => {
        this.setState({
            panelType: "edit",
            editDropDownMenu: dropDownMenu
        })
    }

    render() {
        return (
            <div className="admin_home_drop_downs">
                {
                    this.state.panelType === "view"
                    ?
                        <AdminViewHomeDropDownMenus changeToAdd={this.changeToAdd} 
                        dropDownMenus={this.state.dropDownMenus} 
                        getSectionsDropDownMenus={this.getSectionsDropDownMenus} 
                        pages={this.state.pages} 
                        pageNumber={this.state.pageNumber} 
                        previousPage={this.previousPage} 
                        nextPage={this.nextPage} 
                        changeToEdit={this.changeToEdit} />
                    :
                    this.state.panelType === "add"
                    ?
                        <AdminAddHomeDropDownMenu changeToView={this.changeToView} 
                        sections={this.state.sections} 
                        dropDownLength={this.state.allDropDownMenus.length} 
                        getSectionsDropDownMenus={this.getSectionsDropDownMenus} />
                    :
                        <AdminEditHomeDropDownMenu dropDownMenu={this.state.editDropDownMenu} 
                        changeToView={this.changeToView} 
                        getSectionsDropDownMenus={this.getSectionsDropDownMenus} 
                        sections={this.state.sections} 
                        dropDownMenus={this.state.allDropDownMenus} />
                }
            </div>
        )
    }
}
export default AdminHomeDropDowns