import React from "react"

import "./GalleryImage.scss"

let GalleryImage = (props) => {
    const { REACT_APP_SERVER_HOST } = process.env

    let openCloseModel = () => {
        props.openCloseModel(props.index)
    }

    let onError = (e) => {
        e.target.style.display = "none"
    }

    return (
        <div className="gallery_img_container" onClick={openCloseModel}>
            <img width="170" height="120" src={`${REACT_APP_SERVER_HOST}${props.galleryImage.thumbnail_path}`} alt="gallery_img" onError={onError} />
        </div>
    )
}

export default GalleryImage