import React from "react"
import axios from "axios"

import "./AdminDropDownMenu.scss"

let AdminDropDownMenu = (props) => {
    let deleteDropDownMenu = async () => {
        try {
            let deleteBtn = document.getElementById(`admin_store_drop_down_menu_delete_btn_${props.dropDownMenu.id}`)
            deleteBtn.innerHTML = ""
            deleteBtn.classList.add("spinner")

            if(window.confirm("Are you sure you want to delete this drop down menu?")) {
                let deleteRes = await axios.delete(`/api/admin/delete/drop-down-menu/${props.dropDownMenu.id}`)
    
                alert(deleteRes.data)
    
                deleteBtn.innerHTML = "Delete"
                deleteBtn.classList.remove("spinner")
    
                props.getSectionsDropDownMenus()
            }
            else {
                deleteBtn.innerHTML = "Delete"
                deleteBtn.classList.remove("spinner")
            }
        }
        catch(err) {
            let deleteBtn = document.getElementById(`admin_store_drop_down_menu_delete_btn_${props.dropDownMenu.id}`)

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            deleteBtn.innerHTML = "Delete"
            deleteBtn.classList.remove("spinner")
        }
    }

    let editDropDownMenu = () => {
        props.changeToEdit(props.dropDownMenu)
    }

    return (
        <div className="admin_store_drop_down_menu admin_store_list_item">
            <p>{props.dropDownMenu.name}</p>
            <div className="admin_store_drop_menu_btn_container admin_store_list_item_btn_container">
                <button onClick={editDropDownMenu}>Edit</button>
                <button id={`admin_store_drop_down_menu_delete_btn_${props.dropDownMenu.id}`} 
                className="admin_store_drop_down_menu_delete_btn" 
                onClick={deleteDropDownMenu}>Delete</button>
            </div>
        </div>
    )
}

export default AdminDropDownMenu