import React, { Component } from "react"
import axios from "axios"

import AdminStoreSectionHeaderPreviewModel from "../AdminStoreSectionHeaderPreviewModel/AdminStoreSectionHeaderPreviewModel"
import "./AdminEditFeaturedSection.scss"

class AdminEditFeaturedSection extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: "",
            header: "",
            headerName: "No file selected.",
            deleteHeaderName: "",
            oldHeaderName: "",
            previewShow: false
        }
    }

    componentDidMount() {
        let oldHeaderName = this.props.featuredSection.header.split("FeaturedHeader/")[1]
        this.setState({
            name: this.props.featuredSection.name,
            header: this.props.featuredSection.header,
            oldHeaderName: oldHeaderName
        })
    }

    componentDidUpdate(prevProps) {
        let oldHeaderName = this.props.featuredSection.header.split("FeaturedHeader/")[1]
        if(this.props.featuredSection.name !== prevProps.featuredSection.name || this.props.featuredSection.header !== prevProps.featuredSection.header) {
            this.setState({
                name: this.props.featuredSection.name,
                header: this.props.featuredSection.header,
                oldHeaderName: oldHeaderName
            })
        }
    }

    updateInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    uploadFeaturedHeaderImage = async (e) => {
        try {
            let file = e.target.files[0]
    
            if(file.type === "image/png" || file.type === "image/bmp" || file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/gif" || file.type === "image/x-icon") {
                let regex = /\s+|[,/\\]/g
                if(!file.name.match(regex)) {
                    if(((file.size / 1000) / 1000) > 10) {
                        alert("File is too large to store please upload a smaller image")
                    }
                    else {
                        const formData = new FormData()
            
                        formData.append("featuredHeader", file)
            
                        if(this.state.headerName !== "No file selected.") {
                            let deleteFile = new File(["yes"], "delete", { type: "text/plain" })
                            let imgNameFile = new File([`${this.state.deleteImgName}`], "iconName", { type: "text/plain" })
                
                            formData.append("delete", deleteFile)
                            formData.append("imgName", imgNameFile)
                        }
    
                        let imgRes = await axios.post("/api/admin/add/featured-header-image", formData)
    
                        e.target.value = null
    
                        this.setState({
                            header: imgRes.data[0],
                            headerName: imgRes.data[1],
                            deleteHeaderName: imgRes.data[2]
                        })
                    }
                }
                else {
                    alert("There must be no spaces, forward slashes or back slashes in the image name")
                }
            }
            else {
                alert("Image must be of type png, jpg, gif, ico or bmp")
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    updateFeaturedSection = async () => {
        try {
            let updateBtn = document.getElementById("admin_store_featured_section_update_btn")
            updateBtn.innerHTML = ""
            updateBtn.classList.add("spinner")

            let featuredRes = await axios.put("/api/admin/update/featured-section", { name: this.state.name, header: this.state.header })

            if(this.state.headerName !== "No file selected.") {
                await axios.delete(`/api/admin/delete/old-featured-header-image/${this.state.oldHeaderName}`)
            }

            alert(featuredRes.data)

            updateBtn.innerHTML = "Update"
            updateBtn.classList.remove("spinner")


            this.setState({
                name: "",
                header: "",
                headerName: "No file selected.",
                deleteHeaderName: "",
                oldHeaderName: ""
            }, async () => {
                await this.props.getStoreSectionsAgain()
                this.props.changeToView()
            })
        }
        catch(err) {
            let updateBtn = document.getElementById("admin_store_featured_section_update_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            updateBtn.innerHTML = "Update"
            updateBtn.classList.remove("spinner")
        }
    }

    openClosePreview = () => {
        this.setState({
            previewShow: !this.state.previewShow
        })
    }

    render() {
        return (
            <div className="admin_edit_featured_section">
                <div className="admin_store_form_container">
                    <div className="admin_store_input_container">
                        <p>Name:</p>
                        <input type="text" 
                        name="name" 
                        placeholder="Name" 
                        value={this.state.name} 
                        onChange={this.updateInput} />
                    </div>
                    <div className="admin_store_input_container">
                        <p>Header Image (970 x 78):</p>
                        <div className="admin_store_file_upload_container">
                            <input type="file" 
                            accept="image/*" 
                            onChange={this.uploadFeaturedHeaderImage} />
                            <p>{this.state.headerName}</p>
                        </div>
                    </div>
                </div>
                <div className="admin_store_btn_container">
                    <button onClick={this.props.changeToView}>Cancel</button>
                    <div className="admin_store_preview_update_container">
                        <button onClick={this.openClosePreview}>Preview</button>
                        <button id="admin_store_featured_section_update_btn" 
                        className="admin_store_spinner_container" 
                        disabled={!this.state.name || !this.state.header} 
                        onClick={this.updateFeaturedSection}>Update</button>
                    </div>
                </div>
                <AdminStoreSectionHeaderPreviewModel show={this.state.previewShow} openClosePreview={this.openClosePreview} storeSectionHeader={this.state.header} />
            </div>
        )
    }
}

export default AdminEditFeaturedSection