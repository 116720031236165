import React, { useEffect, useState} from "react"
import axios from "axios"

import PcWallpapers from "../PcWallpapers/PcWallpapers"
import MobileWallpapers from "../MobileWallpapers/MobileWallpapers"
import "./WallpapersBlock.scss"

let WallpapersBlock = (props) => {
    const [pcWallpapersArr, setPcWallpapersArr] = useState([])
    const [mobileWallpapersArr, setMobileWallpapersArr] = useState([])
    const [pcThumbWallpapersArr, setPcThumbWallpapersArr] = useState([])
    const [mobileThumbWallpapersArr, setMobileThumbWallpapersArr] = useState([])
    useEffect(() => {
        let func = async () => {
            let wallpapersRes = await axios.get("/api/get/wallpapers")

            let pcWallpapers = wallpapersRes.data.filter((wallpaper, i) => {
                if(wallpaper.includes("PC")) {
                    if(wallpaper.includes("thumb")) {
                        return false
                    }
                    else {
                        return true
                    }
                }
                else {
                    return false
                }
            })
            let mobileWallpapers = wallpapersRes.data.filter((wallpaper, i) => {
                if(wallpaper.includes("Mobile")) {
                    if(wallpaper.includes("thumb")) {
                        return false
                    }
                    else {
                        return true
                    }
                }
                else {
                    return false
                }
            })
            let pcThumbWallpapers = wallpapersRes.data.filter((wallpaper, i) => {
                if(wallpaper.includes("PC")) {
                    if(wallpaper.includes("thumb")) {
                        return true
                    }
                    else {
                        return false
                    }
                }
                else {
                    return false
                }
            })
            let mobileThumbWallpapers = wallpapersRes.data.filter((wallpaper, i) => {
                if(wallpaper.includes("Mobile")) {
                    if(wallpaper.includes("thumb")) {
                        return true
                    }
                    else {
                        return false
                    }
                }
                else {
                    return false
                }
            })

            setPcWallpapersArr(pcWallpapers)
            setMobileWallpapersArr(mobileWallpapers)
            setPcThumbWallpapersArr(pcThumbWallpapers)
            setMobileThumbWallpapersArr(mobileThumbWallpapers)
        }
        func()
    }, [])

    let pcWallpapers = pcWallpapersArr.map((wallpaper, i) => {
        return (
            <PcWallpapers key={i} wallpaper={wallpaper} thumb={pcThumbWallpapersArr[i]} />
        )
    })

    let mobileWallpapers = mobileWallpapersArr.map((wallpaper, i) => {
        return (
            <MobileWallpapers key={i} wallpaper={wallpaper} thumb={mobileThumbWallpapersArr[i]} />
        )
    })

    return (
        <div className="wallpapers_block">
            <div className="panel_dropshadow">
                <div className="wall" id="wallpapers">
                    <div className="wall_grass_top"></div>
                    <header className="wall_header"></header>
                    <div className="wall_background">
                        <div className="wall_content_wallpapers">
                            <div className="wall_separator_d"></div>
                            <div className="wallpaper_list">
                                {pcWallpapers}
                            </div>
                            <div className="wall_separator_m"></div>
                            <div className="wallpaper_list">
                                {mobileWallpapers}
                            </div>
                        </div>
                        <div className="wall_footer_wallpapers"></div>
                    </div>
                </div>
            </div>
            <br/>
            <br/>
        </div>
    )
}

export default WallpapersBlock