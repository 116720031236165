import React, { Component } from "react"
import axios from "axios"

import "./AdminAddFeaturedCategory.scss"

class AdminAddFeaturedCategory extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: ""
        }
    }

    updateInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    addFeaturedCategory = async () => {
        try {
            let addBtn = document.getElementById("admin_add_featured_category_add_btn")
            addBtn.innerHTML = ""
            addBtn.classList.add("spinner")

            let categoryRes = await axios.post("/api/admin/add/featured-category", { name: this.state.name, order: this.props.categoriesLength + 1 })

            alert(categoryRes.data)

            addBtn.innerHTML = "Add"
            addBtn.classList.remove("spinner")

            this.setState({
                name: ""
            }, async () => {
                await this.props.getFeaturedCategoriesAgain()
                this.props.changeToView()
            })
        }
        catch(err) {
            let addBtn = document.getElementById("admin_add_featured_category_add_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            addBtn.innerHTML = "Add"
            addBtn.classList.remove("spinner")
        }
    }

    render() {
        return (
            <div className="admin_add_featured_category">
                <div className="admin_store_form_container">
                    <div className="admin_store_input_container">
                        <p>Name:</p>
                        <input type="text" 
                        name="name" 
                        placeholder="Name" 
                        value={this.state.name} 
                        onChange={this.updateInput} />
                    </div>
                </div>
                <div className="admin_store_btn_container">
                    <button onClick={this.props.changeToView}>Cancel</button>
                    <button className="admin_spinner_container" 
                    id="admin_add_featured_category_add_btn" 
                    disabled={!this.state.name} 
                    onClick={this.addFeaturedCategory}>Add</button>
                </div>
            </div>
        )
    }
}

export default AdminAddFeaturedCategory