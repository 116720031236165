import React, { Component } from "react"
import axios from "axios"

import "./AdminAddContainer.scss"

class AdminAddContainer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: "",
            type: ""
        }
    }

    updateInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    setOneImgOneByTwo = () => {
        this.setState({
            type: "one img one by two"
        })
    }

    setTwoImgOneByTwo = () => {
        this.setState({
            type: "two img one by two"
        })
    }

    setTwoImgTwoByOne = () => {
        this.setState({
            type: "two img two by one"
        })
    }

    setOneImgTwoByOne = () => {
        this.setState({
            type: "one img two by one"
        })
    }

    setOneImgTwoByTwo = () => {
        this.setState({
            type: "one img two by two"
        })
    }

    addContainer = async () => {
        try {
            let addBtn = document.getElementById("admin_add_container_add_btn")
            addBtn.innerHTML = ""
            addBtn.classList.add("spinner")

            if(!this.state.name || !this.state.type) {
                alert("Must have give container a name and select a container type")

                addBtn.innerHTML = "Add"
                addBtn.classList.remove("spinner")
            }
            else {
                let containerRes = await axios.post("/api/admin/add/home-container", { name: this.state.name, type: this.state.type, order: this.props.containersLength + 1 })

                alert(containerRes.data)

                addBtn.innerHTML = "Add"
                addBtn.classList.remove("spinner")

                this.setState({
                    name: "",
                    type: ""
                }, async () => {
                    await this.props.getContainersAgain()
                    this.props.changeToView()
                })
            }
        }
        catch(err) {
            let addBtn = document.getElementById("admin_add_container_add_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            addBtn.innerHTML = "Add"
            addBtn.classList.remove("spinner")
        }
    }

    render() {
        let containerOneId = this.state.type === "one img one by two" ? "admin_add_container_one_img_one_by_two_active" : "admin_add_container_one_img_one_by_two"
        let containerTwoId = this.state.type === "two img one by two" ? "admin_add_container_two_img_one_by_two_active" : "admin_add_container_two_img_one_by_two"
        let containerThreeId = this.state.type === "two img two by one" ? "admin_add_container_two_img_two_by_one_active" : "admin_add_container_two_img_two_by_one"
        let containerFourId = this.state.type === "one img two by one" ? "admin_add_container_one_img_two_by_one_active" : "admin_add_container_one_img_two_by_one"
        let containerFiveId = this.state.type === "one img two by two" ? "admin_add_container_one_img_two_by_two_active" : "admin_add_container_one_img_two_by_two"
        return (
            <div className="admin_add_container">
                <div className="admin_store_form_container">
                    <div className="admin_store_input_container">
                        <p>Name:</p>
                        <input type="text" 
                        name="name" 
                        placeholder="Name" 
                        value={this.state.name} 
                        onChange={this.updateInput}/>
                    </div>
                    <div className="admin_store_input_container">
                        <p>Container Type:</p>
                        <div className="admin_add_container_container_type_selector_container">
                            <div className="admin_add_container_btn_container">
                                <div id={containerOneId} className="admin_add_container_one_img_one_by_two admin_add_container_container_btn" onClick={this.setOneImgOneByTwo}>
                                    <div className="admin_add_container_img_div">
                                        <span>Img</span>
                                    </div>
                                </div>
                                <div id={containerTwoId} className="admin_add_container_two_img_one_by_two admin_add_container_container_btn" onClick={this.setTwoImgOneByTwo}>
                                    <div className="admin_add_container_img_div">
                                        <span>Img</span>
                                    </div>
                                    <div className="admin_add_container_img_div">
                                        <span>Img</span>
                                    </div>
                                </div>
                                <div id={containerThreeId} className="admin_add_container_two_img_two_by_one admin_add_container_container_btn" onClick={this.setTwoImgTwoByOne}>
                                    <div className="admin_add_container_img_div">
                                        <span>Img</span>
                                    </div>
                                    <div className="admin_add_container_img_div">
                                        <span>Img</span>
                                    </div>
                                </div>
                                <div id={containerFourId} className="admin_add_container_one_img_two_by_one admin_add_container_container_btn" onClick={this.setOneImgTwoByOne}>
                                    <div className="admin_add_container_img_div">
                                        <span>Img</span>
                                    </div>
                                </div>
                                <div id={containerFiveId} className="admin_add_container_one_img_two_by_two admin_add_container_container_btn" onClick={this.setOneImgTwoByTwo}>
                                    <div className="admin_add_container_img_div">
                                        <span>Img</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="admin_store_btn_container">
                    <button onClick={this.props.changeToView}>Cancel</button>
                    <button id="admin_add_container_add_btn" 
                    className="admin_spinner_container" 
                    disabled={!this.state.name.length || !this.state.type.length} 
                    onClick={this.addContainer}>Add</button>
                </div>
            </div>
        )
    }
}

export default AdminAddContainer