import React, { Component } from "react"
import axios from "axios"
import { Helmet } from "react-helmet"

import Navbar from "../../ReuseableComponents/Navbar/Navbar"
import StoreHomeTiles from "./Components/StoreHomeTiles/StoreHomeTiles"
import StorePartners from "./Components/StorePartners/StorePartners"
import StoreSection from "./Components/StoreSection/StoreSection"
import FeaturedSection from "./Components/FeaturedSection/FeaturedSection"
import "./Store.scss"

class Store extends Component {
    constructor(props) {
        super(props)

        this.state = {
            sections: [],
            tileClick: "0,0",
            clicked: false,
            featuredSection: {}
        }
    }

    async componentDidMount() {
        document.title = "Store - Terraria"

        let sectionsRes = await axios.get("/api/get/store/sections")
        let featuredRes = await axios.get("/api/get/store/featured-section")

        this.setState({
            sections: sectionsRes.data,
            featuredSection: featuredRes.data
        })
    }

    tileClicked = (section, category) => {
        this.setState({
            tileClick: `${section},${category}`,
            clicked: true
        })
    }

    resetClicked = () => {
        this.setState({
            tileClick: `0,0`,
            clicked: false
        })
    }

    scrollToSection = (section) => {
        let el = document.getElementById(`store_section_${section}`)

        el.scrollIntoView({ behavior: "smooth" })
    }

    backToTop = () => {
        let tilesHeader = document.getElementById("store_logo_container")

        tilesHeader.scrollIntoView({ behavior: "smooth" })
    }

    render() {
        let storeSections = this.state.sections.map((section, i) => {
            return (
                <StoreSection key={i} section={section} tileClick={this.state.tileClick} backToTop={this.backToTop} clicked={this.state.clicked} resetClicked={this.resetClicked} />
            )
        })
        return (
            <div className="main_wrapper">
                <Helmet>
                    <meta name="description" content="Official Terraria Merchandise Hub! From Terraria T-shirts and apparel, to art/collectibles, toys, and much more - this is where you can find all of the coolest Terraria merch for Terraria fans!" />
                </Helmet>
                <div id="store_logo_container" className="store">
                    <a href="/">
                        <div className="logo"></div>
                    </a>
                    <Navbar />
                    <div className="store_components_container">
                        <StoreHomeTiles tileClicked={this.tileClicked} scrollToSection={this.scrollToSection} />
                        <FeaturedSection featuredSection={this.state.featuredSection} backToTop={this.backToTop} />
                        {storeSections}
                        <StorePartners />
                    </div>
                </div>
            </div>
        )
    }
}

export default Store