import React, { Component } from "react"
import axios from "axios"

import DropDownMenu from "./Components/DropDownMenu/DropDownMenu"
import TileContainer from "./Components/TileContainer/TileContainer"
import "./StoreHomeTiles.scss"

class StoreHomeTiles extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dropDownMenus: [],
            containers: [],
            activeDropDown: 0
        }
    }

    async componentDidMount() {
        let dropDownMenusRes = await axios.get("/api/get/store/home-drop_down_menus")
        let containersRes = await axios.get("/api/get/store/home-containers")

        this.setState({
            dropDownMenus: dropDownMenusRes.data,
            containers: containersRes.data
        })
    }

    changeActiveDropDown = (dropDown) => {
        if(this.state.activeDropDown === dropDown) {
            this.setState({
                activeDropDown: 0
            })
        }
        else {
            this.setState({
                activeDropDown: dropDown
            })
        }
    }

    render() {
        let dropDownMenus = this.state.dropDownMenus.map((dropDownMenu, i) => {
            return (
                <DropDownMenu key={i} 
                dropDownMenu={dropDownMenu} 
                tileClicked={this.props.tileClicked} 
                scrollToSection={this.props.scrollToSection} 
                activeDropDown={this.state.activeDropDown} 
                changeActiveDropDown={this.changeActiveDropDown} />
            )
        })
        let containers = this.state.containers.map((container, i) => {
            return (
                <TileContainer key={i} container={container} tileClicked={this.props.tileClicked} scrollToSection={this.props.scrollToSection} />
            )
        })
        return (
            <div className="store_home_tiles" onClick={() => this.changeActiveDropDown(0)}>
                <div className="panel_dropshadow">
                    <div className="wall">
                        <div className="wall_grass_top"></div>
                        <div className="home_tiles_header"></div>
                        <div className="wall_background">
                            <div className="wall_fade_in"></div>
                            <div className="wall_content">
                                <div className="drop_down_menus_container">
                                    {dropDownMenus}
                                </div>
                                <div className="tile_containers_container">
                                    {containers}
                                </div>
                            </div>
                            <div className="wall_fade_out"></div>
                            <div className="wall_footer_none"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default StoreHomeTiles