import React from "react"
import axios from "axios"

import "./AdminLink.scss"

let AdminLink = (props) => {
    let deleteLink = async () => {
        try {
            let deleteBtn = document.getElementById(`admin_link_delete_btn_${props.link.id}`)
            deleteBtn.innerHTML = ""
            deleteBtn.classList.add("spinner")

            if(window.confirm("Are you sure you want to delete this link?")) {
                let linkRes = await axios.delete(`/api/admin/delete/link/${props.link.id}`)

                alert(linkRes.data)

                deleteBtn.innerHTML = "Delete"
                deleteBtn.classList.remove("spinner")

                props.getLinksAgain()
            }
            else {
                deleteBtn.innerHTML = "Delete"
                deleteBtn.classList.remove("spinner")
            }
        }
        catch(err) {
            let deleteBtn = document.getElementById(`admin_link_delete_btn_${props.link.id}`)

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            deleteBtn.innerHTML = "Delete"
            deleteBtn.classList.remove("spinner")
        }
    }

    let changeToEdit = () => {
        props.changeToEdit(props.link)
    }

    return (
        <div className="admin_link admin_store_list_item">
            <p>{props.link.name}</p>
            <div className="admin_link_btn_container admin_store_list_item_container">
                <button onClick={changeToEdit}>Edit</button>
                <button id={`admin_link_delete_btn_${props.link.id}`} className="admin_link_delete_btn" onClick={deleteLink}>Delete</button>
            </div>
        </div>
    )
}

export default AdminLink