import React from "react"
import axios from "axios"

import "./AdminFeaturedProduct.scss"

let AdminFeaturedProduct = (props) => {

    let publishProduct = async () => {
        try {
            let unpublishedBtn = document.getElementById(`admin_featured_product_unpublished_btn_${props.product.id}`)
            unpublishedBtn.innerHTML = ""
            unpublishedBtn.classList.add("spinner")

            if(window.confirm("Are you sure you want to publish this product? This will publish the product in the featured section as well as the products original store section")) {
                let publishRes = await axios.put("/api/admin/update/product/published", { id: props.product.product_id, published: true })

                alert(publishRes.data)

                unpublishedBtn.innerHTML = "unpublished"
                unpublishedBtn.classList.remove("spinner")

                await props.getProductsAgain()
            }
            else {
                unpublishedBtn.innerHTML = "unpublished"
                unpublishedBtn.classList.remove("spinner")
            }
        }
        catch(err) {
            let unpublishedBtn = document.getElementById(`admin_featured_product_unpublished_btn_${props.product.id}`)

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            unpublishedBtn.innerHTML = "unpublished"
            unpublishedBtn.classList.remove("spinner")
        }
    }

    let unpublishProduct = async () => {
        try {
            let publishedBtn = document.getElementById(`admin_featured_product_published_btn_${props.product.id}`)
            publishedBtn.innerHTML = ""
            publishedBtn.classList.add("spinner")

            if(window.confirm("Are you sure you want to publish this product? This will publish the product in the featured section as well as the products original store section")) {
                let publishRes = await axios.put("/api/admin/update/product/published", { id: props.product.product_id, published: false })

                alert(publishRes.data)

                publishedBtn.innerHTML = "published"
                publishedBtn.classList.remove("spinner")

                await props.getProductsAgain()
            }
            else {
                publishedBtn.innerHTML = "published"
                publishedBtn.classList.remove("spinner")
            }
        }
        catch(err) {
            let publishedBtn = document.getElementById(`admin_featured_product_published_btn_${props.product.id}`)

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            publishedBtn.innerHTML = "published"
            publishedBtn.classList.remove("spinner")
        }
    }

    let unfeatureProduct = async () => {
        try {
            let unfeatureBtn = document.getElementById(`admin_featured_product_unfeature_btn_${props.product.id}`)
            unfeatureBtn.innerHTML = ""
            unfeatureBtn.classList.add("spinner")

            if(window.confirm("Are you sure you want to unfeature this product?")) {
                let unfeatureRes = await axios.delete(`/api/admin/delete/featured_products/${props.product.id}`)

                alert(unfeatureRes.data)

                unfeatureBtn.innerHTML = "Unfeature"
                unfeatureBtn.classList.remove("spinner")

                await props.getProductsAgain()
            }
            else {
                unfeatureBtn.innerHTML = "Unfeature"
                unfeatureBtn.classList.remove("spinner")
            }
        }
        catch(err) {
            let unfeatureBtn = document.getElementById(`admin_featured_product_unfeature_btn_${props.product.id}`)

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            unfeatureBtn.innerHTML = "Unfeature"
            unfeatureBtn.classList.remove("spinner")
        }
    }

        return (
            <div className="admin_store_list_item">
                <p>{props.product.name}</p>
                <div className="admin_store_list_item_btn_container">
                    <button id={`admin_featured_product_unfeature_btn_${props.product.id}`} 
                    className="admin_store_spinner_container admin_featured_product_unfeature_btn" 
                    onClick={unfeatureProduct}>Unfeature</button>
                    <button onClick={() => props.changeToEdit(props.product)}>Edit</button>
                    {
                        props.product.published
                        ?
                            <button id={`admin_featured_product_published_btn_${props.product.id}`} 
                            className="admin_featured_product_published_btn" 
                            onClick={unpublishProduct}>published</button>
                        :
                            <button id={`admin_featured_product_unpublished_btn_${props.product.id}`} 
                            className="admin_featured_product_unpublished_btn" 
                            onClick={publishProduct}>unpublished</button>
                    }
                </div>
            </div>
        )
}

export default AdminFeaturedProduct