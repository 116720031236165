import React from "react"

import AdminLink from "./Components/AdminLink/AdminLink"
import "./AdminViewLinks.scss"

let AdminViewLinks = (props) => {
    let links = props.links.map((link, i) => {
        return (
            <AdminLink key={i} link={link} getLinksAgain={props.getLinksAgain} changeToEdit={props.changeToEdit} />
        )
    })

    let changeToProducts = () => {
        props.changeToProducts(props.category)
    }

    return (
        <div className="admin_view_links">
            <div className="admin_view_links_page_info_container">
                <h4>
                    Links - page {props.pageNumber}
                </h4>
                <button className="admin_view_links_new_btn" onClick={props.changeToAdd}>New</button>
            </div>
            <div className="admin_view_links_links_container">
                {links}
            </div>
            <div className="admin_view_links_btn_container">
                <div className="admin_view_links_prev_next_container">
                    <button disabled={props.pageNumber === 1} onClick={props.previousPage}>Previous</button>
                    <button disabled={props.pageNumber === props.pages} onClick={props.nextPage}>Next</button>
                </div>
                <button className="admin_view_links_products_btn" onClick={changeToProducts}>Products</button>
            </div>
        </div>
    )
}

export default AdminViewLinks