import React, { Component } from "react"
import axios from "axios"
import copy from "copy-to-clipboard"

import "./AdminPost.scss"


class AdminPost extends Component {
    constructor(props) {
        super(props)

        this.state = {
            titleOverflow: false,
            contianerClass: "admin_post_title admin_post_short"
        }

        this.adminPostTitleContainer = React.createRef()
        this.adminPostTitle = React.createRef()
        this.linkInput = React.createRef()
    }

    componentDidMount() {
        let container = this.adminPostTitleContainer.current
        let title = this.adminPostTitle.current
    
        if(this.adminPostTitle.current) {
            if(title.clientHeight > container.clientHeight) {
                this.setState({
                    titleOverflow: true,
                    contianerClass: "admin_post_title admin_post_long"
                })
            }
            else {
                this.setState({
                    titleOverflow: false,
                    contianerClass: "admin_post_title admin_post_short"
                })
            }
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.post !== prevProps.post) {
            let container = this.adminPostTitleContainer.current
            let title = this.adminPostTitle.current
    
        if(this.adminPostTitle.current) {
            if(title.clientHeight > container.clientHeight) {
                this.setState({
                    titleOverflow: true,
                    contianerClass: "admin_post_title admin_post_long"
                })
            }
            else {
                this.setState({
                    titleOverflow: false,
                    contianerClass: "admin_post_title admin_post_short"
                })
            }
        }
        }
    }

    editPost = () => {
        this.props.changeToEdit(this.props.post)
    }

    deletePost = async () => {
        try {
            let deletePostBtn = document.getElementById(`admin_delete_post_btn_${this.props.post.id}`)
            deletePostBtn.innerHTML = ""
            deletePostBtn.classList.add("spinner")

            if(this.props.user.role === "super admin") {
                if(this.props.user.screenName !== this.props.post.author) {
                    if(window.confirm("Are you sure you want to delete someone elses post?")) {
                        let deletePostRes = await axios.delete(`/api/admin/delete/post/${this.props.post.id}`)
        
                        alert(deletePostRes.data)
        
                        deletePostBtn.innerHTML = "Delete"
                        deletePostBtn.classList.remove("spinner")
        
                        this.props.getPostsAgain()
                    }
                    else {
                        deletePostBtn.innerHTML = "Delete"
                        deletePostBtn.classList.remove("spinner")
                    }
                }
                else {
                    if(window.confirm("Are you sure you want to delete this post?")) {
                        let deletePostRes = await axios.delete(`/api/admin/delete/post/${this.props.post.id}`)
        
                        alert(deletePostRes.data)
        
                        deletePostBtn.innerHTML = "Delete"
                        deletePostBtn.classList.remove("spinner")
        
                        this.props.getPostsAgain()
                    }
                    else {
                        deletePostBtn.innerHTML = "Delete"
                        deletePostBtn.classList.remove("spinner")
                    }
                }
            }
            else {
                if(this.props.user.screenName !== this.props.post.author) {
                    alert("Only the author can delete this post")
    
                    deletePostBtn.innerHTML = "Delete"
                    deletePostBtn.classList.remove("spinner")
                }
                else {
                    if(window.confirm("Are you sure you want to delete this post?")) {
                        let deletePostRes = await axios.delete(`/api/admin/delete/post/${this.props.post.id}`)
        
                        alert(deletePostRes.data)
        
                        deletePostBtn.innerHTML = "Delete"
                        deletePostBtn.classList.remove("spinner")
        
                        this.props.getPostsAgain()
                    }
                    else {
                        deletePostBtn.innerHTML = "Delete"
                        deletePostBtn.classList.remove("spinner")
                    }
                }
            }
        }
        catch(err) {
            let deletePostBtn = document.getElementById(`admin_delete_post_btn_${this.props.post.id}`)
            deletePostBtn.innerHTML = "Delete"
            deletePostBtn.classList.remove("spinner")

            if(err.response.data === undefined) {
            }
            else {
                alert(err.response.data)
            }
        }
    }

    publishPost = async () => {
        try{
            let notPublishPostBtn = document.getElementById(`admin_post_not_published_btn_${this.props.post.id}`)
            notPublishPostBtn.innerHTML = ""
            notPublishPostBtn.classList.add("spinner")

            if(this.props.user.role === "super admin") {
                if(window.confirm("Are you sure you want to publish someone elses post?")) {
                    await axios.put("/api/admin/update/post/published", { published: true, id: this.props.post.id })
    
                    alert("Successfully published post")
    
                    notPublishPostBtn.innerHTML = "not published"
                    notPublishPostBtn.classList.remove("spinner")
    
                    this.props.doneEditingPost()
                }
                else {
                    notPublishPostBtn.innerHTML = "not published"
                    notPublishPostBtn.classList.remove("spinner")
                }
            }
            else {
                if(this.props.user.screenName !== this.props.post.author) {
                    alert("Only the author can publish this post")
    
                    notPublishPostBtn.innerHTML = "not published"
                    notPublishPostBtn.classList.remove("spinner")
                }
                else {
                    await axios.put("/api/admin/update/post/published", { published: true, id: this.props.post.id })
        
                    alert("Successfully published post")
        
                    notPublishPostBtn.innerHTML = "not published"
                    notPublishPostBtn.classList.remove("spinner")
        
                    this.props.doneEditingPost()
                }
            }
        }
        catch(err) {
            let notPublishPostBtn = document.getElementById(`admin_post_not_published_btn_${this.props.post.id}`)
            notPublishPostBtn.innerHTML = "not published"
            notPublishPostBtn.classList.remove("spinner")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    unPublishPost = async () => {
        try {
            let publishedPostBtn = document.getElementById(`admin_post_published_btn_${this.props.post.id}`)
            publishedPostBtn.innerHTML = ""
            publishedPostBtn.classList.add("spinner")

            if(this.props.user.role === "super admin") {
                if(window.confirm("Are you sure you want to unpublish someone elses post")) {
                    await axios.put("/api/admin/update/post/published", { published: false, id: this.props.post.id })

                    alert("Successfully unpublished post")

                    publishedPostBtn.innerHTML = "published"
                    publishedPostBtn.classList.remove("spinner")

                    this.props.doneEditingPost()
                }
                else {
                    publishedPostBtn.innerHTML = "published"
                    publishedPostBtn.classList.remove("spinner")
                }
            }
            else {
                if(this.props.user.screenName !== this.props.post.author) {
                    alert("Only the author can unpublish this post")
    
                    publishedPostBtn.innerHTML = "published"
                    publishedPostBtn.classList.remove("spinner")
                }
                else {
                    await axios.put("/api/admin/update/post/published", { published: false, id: this.props.post.id })
        
                    alert("Successfully unpublished post")
        
                    publishedPostBtn.innerHTML = "published"
                    publishedPostBtn.classList.remove("spinner")
        
                    this.props.doneEditingPost()
                }
            }
        }
        catch(err) {
            let publishedPostBtn = document.getElementById(`admin_post_published_btn_${this.props.post.id}`)
            publishedPostBtn.innerHTML = "published"
            publishedPostBtn.classList.remove("spinner")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        } 
    }

    copyLink = () => {
        copy(this.props.post.link)
        alert("link copied")
    }

    updateLinkInput = () => {
        return
    }
    
    render() {
        let postDate = new Date(this.props.post.created_at).toLocaleString()
        return (
            <div className="admin_post_panel">
                <div className="admin_post_content">
                    <div className="admin_post_title_author">
                        <div ref={this.adminPostTitleContainer} className={this.state.contianerClass}>
                            <p ref={this.adminPostTitle}>{this.props.post.title}</p>
                        </div>
                        {
                            this.state.titleOverflow
                            ?
                                <p>...</p>
                            :
                                null
                        }
                        <p className="admin_post_author">by {this.props.post.author}</p>
                    </div>
                    <div className="admin_post_info_container">
                        <p>{postDate}</p>
                        <button onClick={this.copyLink}>Link</button>
                        <input type="text" 
                        style={{"display": "none"}} 
                        value={this.props.post.link} 
                        ref={this.linkInput} 
                        onChange={this.updateLinkInput}/>
                        <button onClick={this.editPost}>Edit</button>
                        <button id={`admin_delete_post_btn_${this.props.post.id}`} 
                        className="admin_delete_post_btn"
                        onClick={this.deletePost}>Delete</button>
                        {
                            this.props.post.published
                            ?
                                <button  id={`admin_post_published_btn_${this.props.post.id}`} 
                                className="admin_post_published_btn" 
                                onClick={this.unPublishPost}>published</button>
                            :
                                <button id={`admin_post_not_published_btn_${this.props.post.id}`} 
                                className="admin_post_not_published_btn" 
                                onClick={this.publishPost}>not published</button>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminPost