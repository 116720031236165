import React from "react"
import axios from "axios"

import AdminStoreSection from "./Components/AdminStoreSection/AdminStoreSection"
import "./AdminViewStoreSections.scss"

let AdminViewStoreSections = (props) => {
    let publishFeaturedSection = async () => {
        try {
            let unpublishedBtn = document.getElementById("admin_store_featured_section_unpublished_btn")
            unpublishedBtn.innerHTML = ""
            unpublishedBtn.classList.add("spinner")

            if(window.confirm("Are you sure you want to publish the featured store section?")) {
                let publishedRes = await axios.put("/api/admin/update/featured-section/published", { published: true })
    
                alert(publishedRes.data)
    
                unpublishedBtn.innerHTML = "unpublished"
                unpublishedBtn.classList.remove("spinner")
    
                await props.getStoreSectionsAgain()
            }
            else {
                unpublishedBtn.innerHTML = "unpublished"
                unpublishedBtn.classList.remove("spinner")

            }
        }
        catch(err) {
            let unpublishedBtn = document.getElementById("admin_store_featured_section_unpublished_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            unpublishedBtn.innerHTML = "unpublished"
            unpublishedBtn.classList.remove("spinner")
        }
    }

    let unpublishFeaturedSection = async () => {
        try {
            let publishedBtn = document.getElementById("admin_store_featured_section_published_btn")
            publishedBtn.innerHTML = ""
            publishedBtn.classList.add("spinner")

            if(window.confirm("Are you sure you want to unpublish the featured store section?")) {
                let publishedRes = await axios.put("/api/admin/update/featured-section/published", { published: false })
    
                alert(publishedRes.data)
    
                publishedBtn.innerHTML = "published"
                publishedBtn.classList.remove("spinner")
    
                await props.getStoreSectionsAgain()
            }
            else {
                publishedBtn.innerHTML = "published"
                publishedBtn.classList.remove("spinner")
            }
        }
        catch(err) {
            let publishedBtn = document.getElementById("admin_store_featured_section_published_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            publishedBtn.innerHTML = "published"
            publishedBtn.classList.remove("spinner")
        }
    }

    let storeSections = props.sections.map((section, i) => {
        return (
            <AdminStoreSection key={i} 
            section={section} 
            editStoreSection={props.editStoreSection} 
            getStoreSectionsAgain={props.getStoreSectionsAgain} 
            changeToCategories={props.changeToCategories} />
        )
    })

    return (
        <div className="admin_store_view_store_sections">
            <div className="admin_store_sections_pages_info_container">
                <h4>
                    Store Sections - page {props.pageNumber}
                </h4>
                <button className="admin_store_view_store_sections_new_btn" onClick={props.changeToAdd}>New</button>
            </div>
            <div className="admin_store_view_store_sections_sections_container">
                <div className="admin_store_list_item">
                    <p>{props.featuredSection.name}</p>
                    <div className="admin_store_list_item_btn_container">
                        <button style={{"width": "100px"}} onClick={props.changeToFeaturedCategories}>Categories</button>
                        <button onClick={props.changeToEditFeatured}>Edit</button>
                        {
                            props.featuredSection.published
                            ?
                                <button id="admin_store_featured_section_published_btn" onClick={unpublishFeaturedSection}>published</button>
                            :
                                <button id="admin_store_featured_section_unpublished_btn" onClick={publishFeaturedSection}>unpublished</button>
                        }
                    </div>
                </div>
                {storeSections}
            </div>
            <div className="admin_store_view_store_sections_btn_container">
                <button disabled={props.pageNumber === 1} onClick={props.previousPage}>Previous</button>
                <button disabled={props.pages === props.pageNumber} onClick={props.nextPage} >Next</button>
            </div>
            
        </div>
    )
}

export default AdminViewStoreSections