import React from "react"
import { Route, Switch, Redirect } from "react-router-dom"

import Home from "./Views/Home/Home"
import News from "./Views/News/News"
import About from "./Views/About/About"
import Credits from "./Views/Credits/Credits"
import Media from "./Views/Media/Media"
import Store from "./Views/Store/Store"
import AdminSignIn from "./Views/AdminSignIn/AdminSignIn"
import AdminNew from "./Views/AdminNew/AdminNew"
import AdminMain from "./Views/AdminMain/AdminMain"
import Terms from "./Views/Terms/Terms"
import AdminUnlock from "./Views/AdminUnlock/AdminUnlock"
import AdminResetPassword from "./Views/AdminResetPassword/AdminResetPassword"
import SingleNews from "./Views/SingleNews/SingleNews"
import Gallery from "./Views/Gallery/Gallery"

export default <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/news" component={News} />
    <Route path="/about" component={About} />
    <Route path="/credits" component={Credits} />
    <Route exact path="/media" component={Media} />
    <Route path="/store" component={Store} />
    <Route path="/auth/sign_in" component={AdminSignIn} />
    <Route path="/auth/admin/new" component={AdminNew} />
    <Route exact path="/admin" component={AdminMain} />
    <Route path="/terms" component={Terms} />
    <Route path="/auth/admin/unlock" component={AdminUnlock} />
    <Route path="/auth/admin/reset-password" component={AdminResetPassword} />
    <Route path="/news/:title" component={SingleNews} />
    <Route path="/media/:gallery" component={Gallery} />
    <Route render={() => <Redirect to={{pathname: "/"}} />} />
</Switch>