import React from "react"

import "./Partner.scss"

let Partner = (props) => {
    const { REACT_APP_SERVER_HOST } = process.env
    let imgBackgroundClass = props.partner.img_background === "light" ? "partner_img_container partner_light" : "partner_img_container partner_dark"
    return (
        <a rel="noreferrer" target="_blank" href={props.partner.link} className="partner">
            <div className={imgBackgroundClass}>
                <img src={`${REACT_APP_SERVER_HOST}${props.partner.img}`} alt=""/>
            </div>
            <h3>
                <strong>{props.partner.name}</strong>
            </h3>
        </a>
    )
}

export default Partner