import React from "react"

import "./PcWallpapers.scss"

let PcWallpapers = (props) => {
    const { REACT_APP_SERVER_HOST } = process.env
    return (
        <div className="pc_wallpaper_block">
            <a href={`${REACT_APP_SERVER_HOST}/Wallpapers/${props.wallpaper}`}>
                <img src={`${REACT_APP_SERVER_HOST}/Wallpapers/${props.thumb}`} alt={props.thumb}/>
            </a>
        </div>
    )
}

export default PcWallpapers