import React, { Component } from "react"
import ReactQuill, { Quill } from "react-quill"
import axios from "axios"
import ImageUploader from "quill-image-uploader"

import CustomToolbar from "../../../../../../ReuseableComponents/CustomToolbar/CustomToolbar"
import "react-quill/dist/quill.snow.css"
import "./AdminAddPost.scss"

Quill.register("modules/imageUploader", ImageUploader)

const Size = Quill.import("attributors/style/size")
Size.whitelist = ["10px", "12px", "14px", "16px", "18px", "24px", "32px"]
Quill.register(Size, true)

const Font = Quill.import("formats/font")
Font.whitelist = [
    "open sans",
    "arial",
    "sans-serif",
    "comic-sans",
    "georgia",
    "helvetica",
    "lucida"
]
Quill.register(Font, true)

class AdminAddPost extends Component {
    constructor(props) {
        super(props)

        this.state = {
            title: "",
            html: "",
            twitterShareText: "",
            featured: true,
            featuredImg: `/Home/default.png`,
            featuredImgName: "No file selected.",
            featuredTitle: "",
            featuredDescription: "",
            previewStyle: {"display": "none"},
            author: "",
            deleteImgName: "No file selected."
        }

        this.previewContainer = React.createRef()
    }

    
    static modules = {
        toolbar: {
            container: "#toolbar"
        },
        imageUploader: {
            upload: async file => {
                return new Promise(async (resolve, reject) => {
                    try {
                        const formData = new FormData()
                        formData.append("image", file)
    
                        let imgUrl = await axios.post("/api/admin/add/post-img", formData)
                        resolve(imgUrl.data)
                    }
                    catch(err) {
                        reject("Upload failed")
                    }
                })
            } 
        }
    }
    
    static formats = [
        'code-block',
        'bold',
        'italic',
        'underline',
        'strike',
        'script',
        'list',
        'bullet',
        'indent',
        'blockquote',
        'align',
        'link',
        'image',
        'video',
        'color',
        'background',
        'size',
        'header',
        'font'
    ]

    componentDidMount() {
        window.addEventListener("beforeunload", this.cleanup)

        if(this.props.user) {
            if(this.props.user.screenName) {
                this.setState({
                    author: this.props.user.screenName
                })
            }
        }
    }

    async componentWillUnmount () {
        window.removeEventListener("beforeunload", this.cleanup)
    }

    cleanup = async () => {
        if(this.state.featuredImgName !== "No file selected.") {
            let imgFileName = this.state.deleteImgName

            await axios.delete(`/api/admin/delete/post/featured-image/${imgFileName}`)
        }
    }

    cancelPost = async () => {
        try {
            const { REACT_APP_SERVER_HOST } = process.env
    
            if(this.state.featuredImgName !== "No file selected.") {
                let imgFileName = this.state.featuredImgName
    
                await axios.delete(`/api/admin/delete/post/featured-image/${imgFileName}`)
            }
    
            this.setState({
                title: "",
                html: "",
                twitterShareText: "",
                featuredImg: `${REACT_APP_SERVER_HOST}/Home/default.png`,
                featuredImgName: "No file selected.",
                shortTitle: "",
                shortDescription: ""
            }, () => this.props.changeToView())
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    onQuillChange = (content, delta, source, editor) => {
        this.setState({
            html: content
        })
    }

    updateInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    uploadFeaturedImage = async (e) => {
        try {
            const files = e.target.files

            if(files.length > 1) {
                alert("You can only upload one file")
            }
            else {
                if(files[0].type === "image/png" || files[0].type === "image/bmp" || files[0].type === "image/jpeg" || files[0].type === "image/jpg" || files[0].type === "image/gif" || files[0].type === "image/x-icon") {
                    let blob = files[0].slice(0, files[0].size, files[0].type)
                    let newFile = new File([blob], files[0].name.replace(/\s/g, "-"), {type: files[0].type})
                    const formData = new FormData()
                    formData.append("featuredImage", newFile)

                    if(this.state.featuredImgName !== "No file selected.") {
                        let deleteFile = new File(["yes"], "delete", { type: "text/plain" })
                        let imgNameFile = new File([`${this.state.featuredImgName}`], "imgName", { type: "text/plain" })

                        formData.append("delete", deleteFile)
                        formData.append("imgName", imgNameFile)
                    }

                    let featuredImgRes = await axios.post("/api/admin/add/featured-image", formData)

                    this.setState({
                        featuredImg: featuredImgRes.data[0],
                        featuredImgName: featuredImgRes.data[1],
                        deleteImgName: featuredImgRes.data[2]
                    })
                }
                else {
                    alert("Image file must be a png, bmp, jpeg or jpg image")
                }
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    savePost = async () => {
        try {
            let savePostBtn = document.getElementById("admin_save_post_btn")
            savePostBtn.innerHTML = ""
            savePostBtn.classList.add("spinner")

            if(this.state.title === "") {
                alert("In order to save this post to finish it later you must give it a title")

                savePostBtn.innerHTML = "Submit"
                savePostBtn.classList.remove("spinner")
            }
            else if(this.state.title.length > 75) {
                alert("Title is too long please keep it under 70 characters")

                savePostBtn.innerHTML = "Submit"
                savePostBtn.classList.remove("spinner")
            }
            else {
                if(this.state.featured) {
                    if(this.state.featuredTitle === "" || this.state.featuredDescription === "") {
                        alert("Since this post will be featured you must add a featured title and a featured description")

                        savePostBtn.innerHTML = "Submit"
                        savePostBtn.classList.remove("spinner")

                        return
                    }
                    else {
                        if( this.state.featuredTitle.length > 255 || this.state.featuredDescription.length > 255) {
                            alert("Featured Title and Featured Description must be less than 255 characters")

                            savePostBtn.innerHTML = "Submit"
                            savePostBtn.classList.remove("spinner")

                            return
                        }
                    }
                }
                const { REACT_APP_SITE_HOST } = process.env

                let savePostRes = await axios.post("/api/admin/add/post", {
                    title: this.state.title,
                    html: this.state.html,
                    link: `${REACT_APP_SITE_HOST}/news/${this.state.title.replace(/[\W_]+/g, "-").toLowerCase()}`,
                    published: false,
                    featured: this.state.featured,
                    featuredImg: this.state.featuredImg,
                    featuredTitle: this.state.featuredTitle,
                    featuredDescription: this.state.featuredDescription,
                    author: this.state.author
                })

                alert(savePostRes.data)

                if(this.state.twitterShareText.length) {
                    if((`${REACT_APP_SITE_HOST}/news/`.length + `${this.state.title.replace(/[\W_]+/g, "-").toLowerCase()}`.length + this.state.twitterShareText.length) > 140) {
                        alert("Twitter Share text exceeds limit please keep it under 140 characters")
                    }
                    else {
                        this.shareOnTwitter()
                    }
                }

                savePostBtn.innerHTML = "Submit"
                savePostBtn.classList.remove("spinner")

                this.props.getPostsAgain()
            }
        }
        catch(err) {
            let savePostBtn = document.getElementById("admin_save_post_btn")
            savePostBtn.innerHTML = "Submit"
            savePostBtn.classList.remove("spinner")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    toggleFeatured = () => {
        this.setState({
            featured: !this.state.featured
        })
    }

    openPreview = () => {
        let previewBody = document.getElementById("admin_add_post_body")
        previewBody.innerHTML = this.state.html

        this.setState({
            previewStyle: {"dispaly": "block"}
        }, () => this.previewContainer.current.scrollIntoView())
    }

    shareOnTwitter = () => {
        const { REACT_APP_SITE_HOST } = process.env
        let url = encodeURIComponent(`${REACT_APP_SITE_HOST}/news/${this.state.title.replace(/[\W_]+/g, "-").toLowerCase()}`)
        let windowStyle = "height=300,width=600"
        window.open(`http://twitter.com/share?url=${url}&text=${this.state.twitterShareText}`, "", windowStyle)
    }

    render() {
        const { REACT_APP_SERVER_HOST, REACT_APP_SITE_HOST } = process.env
        let featuredMsgClass = this.state.featured ? "admin_add_post_featured_true admin_add_post_featured_message_container" : "admin_add_post_featured_false admin_add_post_featured_message_container"
        let date = new Date(Date.now())
        let dateHours = (date.getHours() + 1) > 12 ? (date.getHours() + 1) - 12 : date.getHours() + 1
        let dateSeconds = (date.getSeconds() + 1) < 10 ? `0${date.getSeconds() + 1}` : date.getSeconds() + 1
        let datePeriod = (date.getHours() + 1) > 11 ? "PM" : "AM"
        return (
            <div className="admin_add_post_panel">
                <div className="admin_add_post_header">
                    <h4>
                        <b>New Post</b>
                    </h4>
                </div>
                <div className="admin_add_post_form">
                    <div className="admin_add_post_input_container">
                        <p className="title_characters">{this.state.title.length}/70</p>
                        <input type="text" 
                        name="title" 
                        placeholder="Title" 
                        value={this.state.title} 
                        onChange={this.updateInput}/>
                    </div>
                    <div className="admin_add_post_input_container">
                        <CustomToolbar />
                        <ReactQuill theme="snow" 
                                    className="admin_quill_editor" 
                                    modules={AdminAddPost.modules} 
                                    formats={AdminAddPost.formats} 
                                    value={this.state.html} 
                                    onChange={this.onQuillChange}>
                        </ReactQuill>
                    </div>
                    <div className="admin_add_post_input_container">
                        <b>Author:</b>
                        <input type="text" 
                        name="author"
                        placeholder="author" 
                        value={this.state.author} 
                        onChange={this.updateInput}/>
                    </div>
                    <div className="admin_add_post_social_media_container">
                        <h4>
                            <b>Social Media</b>
                        </h4>
                        <div className="admin_add_post_input_container">
                            <h6>
                                <b>Twitter share text:</b>
                            </h6>
                            <input type="text" 
                            name="twitterShareText" 
                            value={this.state.twitterShareText} 
                            onChange={this.updateInput}/>
                            <div className="admin_add_post_social_character_limit">
                                <p>{`${REACT_APP_SITE_HOST}/news/`.length + `${this.state.title.replace(/[\W_]+/g, "-").toLowerCase()}`.length + this.state.twitterShareText.length}/140</p>
                            </div>
                        </div>
                        <div className="admin_add_post_link_container">
                            {
                                this.state.title === ""
                                ?
                                    <p>No title {`${REACT_APP_SITE_HOST}/news/???`}</p>
                                :
                                    <p>{`${REACT_APP_SITE_HOST}/news/${this.state.title.replace(/[\W_]+/g, "-").toLowerCase()}`}</p>
                            }
                        </div>
                    </div>
                    <div className="admin_add_post_featured_container">
                        <div className={featuredMsgClass} onClick={this.toggleFeatured}>
                            <h6>
                                {
                                    this.state.featured
                                    ?
                                        <b>This post will be shown on the home page.</b>
                                    :
                                        <b>This post will be hidden from the home page.</b>

                                }
                            </h6>
                        </div>
                        {
                            this.state.featured
                            ?
                                <div className="admin_add_post_featured_input_container">
                                    <div>
                                        <div className="admin_add_post_input_container">
                                            <h6>
                                                <b>Preview Image (256x183):</b>
                                            </h6>
                                            <div className="featured_img_input_container">
                                                <input type="file"
                                                className="upload_featured_img_btn" 
                                                accept="image/*" 
                                                onChange={this.uploadFeaturedImage}/>
                                                <p>{this.state.featuredImgName}</p>
                                            </div>
                                        </div>
                                        <div className="admin_add_post_input_container">
                                            <h6>
                                                <b>Featured Title:</b>
                                            </h6>
                                            <input type="text" 
                                            name="featuredTitle" 
                                            value={this.state.featuredTitle} 
                                            onChange={this.updateInput}/>
                                        </div>
                                        <div className="admin_add_post_input_container">
                                            <h6>
                                                <b>Featured Description:</b>
                                            </h6>
                                            <textarea name="featuredDescription" 
                                            cols="30" 
                                            rows="10" 
                                            value={this.state.featuredDescription} 
                                            onChange={this.updateInput}></textarea>
                                        </div>
                                    </div>
                                    <div className="admin_add_post_preview_container">
                                        <div className="panel_dropshadow news_block_shadow">
                                            <div className="news_block_grass"></div>
                                            <div className="news_block">
                                                <div className="news_block_image_container">
                                                    <img className="fading_img" src={`${REACT_APP_SERVER_HOST}/Home/yellow_edge.png`} alt="preview" style={{"backgroundImage": `url(${REACT_APP_SERVER_HOST}${this.state.featuredImg})`}}/>
                                                </div>
                                                <h4>
                                                    {
                                                        this.state.featuredTitle === ""
                                                        ?
                                                            <strong>No Title</strong>
                                                        :
                                                            <strong>{this.state.featuredTitle}</strong>
                                                    }
                                                </h4>
                                                {
                                                    this.state.featuredDescription === ""
                                                    ?
                                                        <p>No Description</p>
                                                    :
                                                        <p>{this.state.featuredDescription}</p>
                                                }
                                                <div className="read_more">
                                                    <img src={`${REACT_APP_SERVER_HOST}/Home/read_more.png`} alt="read_more"/>
                                                    <p>Read More</p>
                                                </div>
                                                <p className="news_date">
                                                    {new Date(Date.now()).toLocaleString("default", { month: "long" })} {new Date(Date.now()).getDate()}, {new Date(Date.now()).getFullYear()}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            :
                                null
                        }
                    </div>
                    <div className="admin_add_post_btn_container">
                        <button className="admin_cancel_post_btn" onClick={this.cancelPost}>Cancel</button>
                        <div className="admin_add_post_preview_submit_container">
                            <button onClick={this.openPreview}>Preview</button>
                            <button id="admin_save_post_btn" disabled={!this.state.title} onClick={this.savePost}>Submit</button>
                        </div>
                    </div>
                </div>
                <div ref={this.previewContainer} className="admin_add_post_preview_post" style={this.state.previewStyle}>
                    <div className="panel_dropshadow">
                        <article className="wall">
                            <div className="wall_grass_top"></div>
                            <header className="wall_header">
                                <h3>{this.state.title}</h3>
                            </header>
                            <div className="wall_background">
                                <div className="wall_fade_in"></div>
                                <div id="admin_add_post_body" className="wall_content ql-editor"></div>
                                <div className="wall_fade_out"></div>
                                <div className="wall_footer">
                                    <div className="wall_footer_timestamp">
                                        <p>Last updated on:</p>
                                        <p>
                                            {date.getUTCMonth() + 1}
                                            /{date.getUTCDate()}
                                            /{date.getUTCFullYear()}
                                            , {dateHours}
                                            :{date.getMinutes() + 1}
                                            :{dateSeconds} {datePeriod}
                                        </p>
                                    </div>
                                    <div className="post_link social_media"></div>
                                    <div className="twitter social_media"></div>
                                    <div className="facebook social_media"></div>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminAddPost