import axios from "axios"
import React, { Component } from "react"
import ReactDOM from "react-dom"

import Navbar from "../../ReuseableComponents/Navbar/Navbar"
import "./About.scss"

class About extends Component {
    constructor(props) {
        super(props)

        this.state = {
            aboutState: "new",
            digStyle: {"maxHeight": "120px", "top": "-253px", "visibility": "visible", "opacity": "1"},
            fightStyle: {"maxHeight": "120px", "top": "-53px", "visibility": "visible", "opacity": "1"},
            buildStyle: {"maxHeight": "120px", "top": "147px", "visibility": "visible", "opacity": "1"},
            overworldStyle: {"visibility": "visible", "opacity": "1"},
            caveStyle: {"visibility": "hidden", "opacity": "0", "transform": "translate3d(0px, 0px, 0px) !important"},
            mushroomStyle: {"visibility": "hidden", "opacity": "0"},
            expanded: false,
            isLandscape: true,
            domReady: false
        }

        this.overworldRef = React.createRef()
        this.caveRef = React.createRef()
        this.mushroomRef = React.createRef()
    }

    async componentDidMount() {
        document.title = "About - Terraria"

        document.addEventListener("wheel", this.preventDefault, {passive: false})

        let footer = document.getElementById("site_footer")

        footer.classList.add("page_footer_about")

        footer.addEventListener("mousemove", this.onMouseMove)

        window.addEventListener("resize", this.reportWindowSize)

        if(window.innerHeight > window.innerWidth) {
            this.setState({
                isLandscape: false
            })
        }
        else {
            let aboutVersionRes = await axios.get("/api/get/about/version")
    
            if(aboutVersionRes.data === "new") {
                this.setState({
                    aboutState: "new",
                    domReady: true
                })
            }
            else {
                this.setState({
                    aboutState: "old",
                    overworldStyle: {"visibility": "visible", "opacity": "1"},
                    caveStyle: {"visibility": "hidden", "opacity": "0"},
                    mushroomStyle: {"visibility": "hidden", "opacity": "0"},
                    domReady: true
                })
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("wheel", this.preventDefault, false)

        window.removeEventListener("resize", this.reportWindowSize)

        let footer = document.getElementById("site_footer")

        footer.classList.remove("page_footer_about")

        footer.removeEventListener('mousemove', this.onMouseMove)
    }

    reportWindowSize = () => {
        if(window.innerHeight > window.innerWidth) {
            this.setState({
                isLandscape: false
            })
        }
        else {
            this.setState({
                isLandscape: true
            })
        }
    }

    preventDefault = (e) => {
        e = e || window.event
        if(e.preventDefault) {
            e.preventDefault()
        }
        e.returnValue = false
    }

    changeAboutVersion = async () => {
        if(this.state.aboutState === "new") {
            await axios.post("/api/add/about/version", { aboutVersion: "old" })

            this.setState({
                aboutState: "old",
                overworldStyle: {"visibility": "visible", "opacity": "1"},
                caveStyle: {"visibility": "hidden", "opacity": "0"},
                mushroomStyle: {"visibility": "hidden", "opacity": "0"}
            })
        }
        else {
            await axios.post("/api/add/about/version", { aboutVersion: "new" })

            this.setState({
                aboutState: "new"
            })
        }
    }

    onMouseMove = (e) => {
        if(this.state.aboutState === "new") {
            let layers = document.querySelectorAll(".move_layer")
            let xPercent = e.pageX / window.innerWidth
            let yPercent = e.pageY / window.innerHeight
            let targetX = (xPercent - 0.4) * 30
            let targetY = (yPercent - 0.4) * 30
            let request
    
            let moveLayer = () => {
                for (let i = 0; i < layers.length; i++) {
                    layers[i].style.transform = `scale(0.99999, 0.99999) translate3d(${targetX}px, ${targetY}px, 1px`
                }
            }
    
            requestAnimationFrame(moveLayer)
    
            cancelAnimationFrame(request)
        }
        else {
            return
        }
    }

    toggleDigInfo = () => {
        if(this.state.expanded) {
            this.setState({
                digStyle: {"maxHeight": "120px", "top": "-256px", "visibility": "visible", "opacity": "1"},
                fightStyle: {"maxHeight": "120px", "top": "-53px", "visibility": "visible", "opacity": "1"},
                buildStyle: {"maxHeight": "120px", "top": "147px", "visibility": "visible", "opacity": "1"},
                overworldStyle: {"visibility": "visible", "opacity": "1"},
                caveStyle: {"visibility": "hidden", "opacity": "0"},
                mushroomStyle: {"visibility": "hidden", "opacity": "0"},
                expanded: false
            })
        }
        else {
            if(this.state.aboutState === "new") {
                this.overworldRef.current.scrollIntoView({behavior: "smooth"})
            }

            this.setState({
                digStyle: {"maxHeight": "600px", "top": "-256px", "visibility": "visible", "opacity": "1"},
                fightStyle: {"maxHeight": "120px", "top": "-53px", "visibility": "hidden", "opacity": "0"},
                buildStyle: {"maxHeight": "120px", "top": "147px", "visibility": "hidden", "opacity": "0"},
                overworldStyle: {"visibility": "visible", "opacity": "1"},
                caveStyle: {"visibility": "hidden", "opacity": "0"},
                mushroomStyle: {"visibility": "hidden", "opacity": "0"},
                expanded: true
            })
        }
    }

    toggleFightInfo = () => {
        if(this.state.expanded) {
            this.setState({
                digStyle: {"maxHeight": "120px", "top": "-256px", "visibility": "visible", "opacity": "1"},
                fightStyle: {"maxHeight": "120px", "top": "-53px", "visibility": "visible", "opacity": "1"},
                buildStyle: {"maxHeight": "120px", "top": "147px", "visibility": "visible", "opacity": "1"},
                overworldStyle: {"visibility": "hidden", "opacity": "0"},
                caveStyle: {"visibility": "visible", "opacity": "1"},
                mushroomStyle: {"visibility": "hidden", "opacity": "0"},
                expanded: false
            })
        }
        else {
            if(this.state.aboutState === "new") {
                this.caveRef.current.scrollIntoView({behavior: "smooth"})
            }

            this.setState({
                digStyle: {"maxHeight": "120px", "top": "-256px", "visibility": "hidden", "opacity": "0"},
                fightStyle: {"maxHeight": "600px", "top": "-256px", "visibility": "visible", "opacity": "1"},
                buildStyle: {"maxHeight": "120px", "top": "147px", "visibility": "hidden", "opacity": "0"},
                overworldStyle: {"visibility": "hidden", "opacity": "0"},
                caveStyle: {"visibility": "visible", "opacity": "1"},
                mushroomStyle: {"visibility": "hidden", "opacity": "0"},
                expanded: true
            })
        }
    }

    toggleBuildInfo = () => {
        if(this.state.expanded) {
            this.setState({
                digStyle: {"maxHeight": "120px", "top": "-256px", "visibility": "visible", "opacity": "1"},
                fightStyle: {"maxHeight": "120px", "top": "-53px", "visibility": "visible", "opacity": "1"},
                buildStyle: {"maxHeight": "120px", "top": "147px", "visibility": "visible", "opacity": "1"},
                overworldStyle: {"visibility": "hidden", "opacity": "0"},
                caveStyle: {"visibility": "hidden", "opacity": "0"},
                mushroomStyle: {"visibility": "visible", "opacity": "1"},
                expanded: false
            })
        }
        else {
            if(this.state.aboutState === "new") {
                this.mushroomRef.current.scrollIntoView({behavior: "smooth"})
            }

            this.setState({
                digStyle: {"maxHeight": "120px", "top": "-256px", "visibility": "hidden", "opacity": "0"},
                fightStyle: {"maxHeight": "120px", "top": "-53px", "visibility": "hidden", "opacity": "0"},
                buildStyle: {"maxHeight": "600px", "top": "-256px", "visibility": "visible", "opacity": "1"},
                overworldStyle: {"visibility": "hidden", "opacity": "0"},
                caveStyle: {"visibility": "hidden", "opacity": "0"},
                mushroomStyle: {"visibility": "visible", "opacity": "1"},
                expanded: true
            })
        }
    }

    render() {
        console.log(document.getElementById("site_footer"))
        const {
            REACT_APP_SERVER_HOST
        } = process.env

        let footerAboutVersion = React.createElement(
            "div",
            {
                id: "footer_about_version_selector",
            },
            React.createElement(
                "p",
                {
                    id: "footer_about_version_selector_text"
                },
                "Use this to change between the fancy and old about pages"
            ),
            React.createElement(
                "button",
                {
                   onClick: this.changeAboutVersion 
                },
                "Click here"
            )
        )

        return (
            this.state.isLandscape
            ?
            <div className="about">
                {
                    this.state.aboutState === "new"
                    ?
                        <div onMouseMove={this.onMouseMove}>
                            <a href="/">
                                <div className="logo"></div>
                            </a>
                            <Navbar />
                            <div className="css3_wrapper move_layer">
                                <div className="parallax_container accelerate" ref={this.overworldRef}>
                                    <img src={`${REACT_APP_SERVER_HOST}/Background/Overworld/layer_4.jpg`} alt="overworld4" className="parallax_layer move_layer"/>
                                    <img src={`${REACT_APP_SERVER_HOST}/Background/Overworld/layer_3.png`} alt="overworld3" className="parallax_layer move_layer"/>
                                    <img src={`${REACT_APP_SERVER_HOST}/Background/Overworld/layer_2.png`} alt="overworld2" className="parallax_layer move_layer"/>
                                    <img src={`${REACT_APP_SERVER_HOST}/Background/Overworld/layer_1.png`} alt="overworld1" className="parallax_layer" style={{"position": "relative", "transform": "scale(0.99999, 0.99999) translate3d(0px, -1px, 1px)"}}/>
                                </div>
                                <img src={`${REACT_APP_SERVER_HOST}/Divider/overworld-cave.jpg`} alt="overworld-cave" className="parallax_divider" style={{"transform": "scale(0.99999, 0.99999) translate3d(0px, -2px, 1px)", "marginTop": "-6px"}}/>
                                <div className="parallax_container accelerate" ref={this.caveRef} style={{"marginTop": "-8px", "minHeight": "100%"}}>
                                    <img src={`${REACT_APP_SERVER_HOST}/Background/Cave/layer_4.jpg`} alt="cave4" className="parallax_layer move_layer"/>
                                    <img src={`${REACT_APP_SERVER_HOST}/Background/Cave/layer_3.png`} alt="cave3" className="parallax_layer move_layer"/>
                                    <img src={`${REACT_APP_SERVER_HOST}/Background/Cave/layer_2.png`} alt="cave2" className="parallax_layer move_layer"/>
                                    <img src={`${REACT_APP_SERVER_HOST}/Background/Cave/layer_1.png`} alt="cave1" className="parallax_layer" style={{"transform": "scale(0.99999, 0.99999) translate3d(0px, -1px, 1px)"}}/>
                                </div>
                                <img src={`${REACT_APP_SERVER_HOST}/Divider/cave-mushroom.jpg`} alt="" className="parallax_divider" style={{"transform": "scale(0.99999, 0.99999) translate3d(0px, -5px, 1px)", "marginTop": "3px"}}/>
                                <div className="parallax_container accelerate" ref={this.mushroomRef} style={{"marginTop": "-11px"}}>
                                    <img src={`${REACT_APP_SERVER_HOST}/Background/Mushroom/layer_4.jpg`} alt="mushroom4" className="parallax_layer move_layer"/>
                                    <img src={`${REACT_APP_SERVER_HOST}/Background/Mushroom/layer_3.png`} alt="mushroom3" className="parallax_layer move_layer"/>
                                    <img src={`${REACT_APP_SERVER_HOST}/Background/Mushroom/layer_2.png`} alt="mushroom2" className="parallax_layer move_layer"/>
                                    <img src={`${REACT_APP_SERVER_HOST}/Background/Mushroom/layer_1.png`} alt="mushroom1" className="parallax_layer" style={{"transform": "scale(0.99999, 0.99999) translate3d(0px, -1px, 1px)"}}/>
                                </div>
                            </div>
                        </div>
                    :
                        <div>
                            <a href="/">
                                <div className="logo"></div>
                            </a>
                            <Navbar />
                            <div className="flat_wrapper">
                                <img src={`${REACT_APP_SERVER_HOST}/Background/Overworld/flat.jpg`} className="flat_layer" alt="overworld" style={this.state.overworldStyle}/>
                                <img src={`${REACT_APP_SERVER_HOST}/Background/Cave/flat.jpg`} className="flat_layer" alt="cave" style={this.state.caveStyle}/>
                                <img src={`${REACT_APP_SERVER_HOST}/Background/Mushroom/flat.jpg`} className="flat_layer" alt="mushroom" style={this.state.mushroomStyle}/>
                            </div>
                        </div>
                }
                <div className="info_category_wrapper" style={{"display": "block"}} id="category_container" onMouseMove={this.onMouseMove}>
                    <div className="info_category" id="dig" style={this.state.digStyle} onClick={this.toggleDigInfo}>
                        <div className="info_category_top">
                            <div className="info_name"></div>
                            <span className="info_small_description">
                                Gather, transform, and utilize raw materials as you explore an
                                assortment of distinct biomes and structures. Each world is unique, so
                                you never know what you will find!
                            </span>
                        </div>
                        <div className="info_gallary">
                            <video className="accelerate" loop preload="auto" width="970" autoPlay="autoplay">
                                <source src={`${REACT_APP_SERVER_HOST}/videos/about/dig.mp4`} type="video/mp4"/>
                                <source src={`${REACT_APP_SERVER_HOST}/videos/about/dig.webm`} type="video/webm"/>
                                Your browser does not support the video tag
                            </video>
                            <div className="info_overlay_bg accelerate"></div>
                            <div className="info_overlay accelerate">
                                DIG!
                                <br/>
                                Each Terrarian world is filled to the brim with raw materials
                                waiting to be gathered, transformed, and utilized! Coupled with a
                                robust crafting system, the possibilities - and creations - are as limitless as your imagination.
                                <br/>
                                <br/>
                                EXPLORE!
                                <br/>
                                With an assortment of distinct biomes and structures scattered throughout
                                the landscape, each excursion abounds with the potential for legendary discoveries.
                                <br/>
                                From the serene grasslands to the cruel Dungeon to the ominous Corruption to the very pit of Hell itself,
                                hours will tick by like seconds as you delve ever deeper into the wonders and mysteries of your particular world!
                            </div>
                        </div>
                    </div>
                    <div className="info_category category_right" id="fight" style={this.state.fightStyle} onClick={this.toggleFightInfo}>
                        <div className="info_category_top">
                            <div className="info_name"></div>
                            <span className="info_small_description">
                                Wield a vast arsenal of weaponry and armor - from swords to magic spells to firearms
                                and beyond - as you triumph over the enemy hordes that are intent on your destruction!
                            </span>
                        </div>
                        <div className="info_gallery">
                            <video className="accelerate" loop preload="auto" width="970" autoPlay="autoplay">
                                <source src={`${REACT_APP_SERVER_HOST}/videos/about/fight.mp4`} type="video/mp4"/>
                                <source src={`${REACT_APP_SERVER_HOST}/videos/about/fight.webm`} type="video/webm"/>
                            </video>
                            <div className="info_overlay_bg accelerate"></div>
                            <div className="info_overlay accerlate">
                                Terraria is teeming with life - both friend and foe - that you will
                                encounter on your expiditions. Nefarious armies, warring factions,
                                and epic bosses lurk in the shadows as well...seeking to tear you
                                limb from limb. If that wasn't enough, it seems as if the very moon
                                has set itself against you!
                                <br/>
                                <br/>
                                <br/>
                                Fortunately, you are blessed with a vast and diverse arsenal of
                                ways to defend yourself! From swords to sorcery to firearms to
                                cleverly-designed traps and beyond: Terraria embraces the notion of
                                combat on your terms, no matter the playstyle! Take up arms and
                                show the enemy hordes who's boss!
                            </div>
                        </div>
                    </div>
                    <div className="info_category" id="build" style={this.state.buildStyle} onClick={this.toggleBuildInfo}>
                        <div className="info_category_top">
                            <div className="info_name"></div>
                            <span className="info_small_description">
                                Construct the world you desire! From simple dwellings to imense
                                fortresses to intricate machinery, the choice is yours! Become the
                                architect of your very own world!
                            </span>
                        </div>
                        <div className="info_gallery">
                            <video className="accelerate" loop preload="auto" width="970" autoPlay="autoplay">
                                <source src={`${REACT_APP_SERVER_HOST}/videos/about/build.mp4`} type="video/mp4"/>
                                <source src={`${REACT_APP_SERVER_HOST}/videos/about/build.webm`} type="video/webm"/>
                            </video>
                            <div className="info_overlay_bg accelerate"></div>
                            <div className="info_overlay accelerate">
                                What good is all that exploration, gathering, and fighting without
                                a place to call home?
                                <br/>
                                <br/>
                                <br/>
                                With an immense toolbox of materials and equipment at your
                                disposal, the only limit to building in terraria is your
                                creativity.
                                <br/>
                                <br/>
                                <br/>
                                Construct a simple home, an imposing fortress, a complex mechanical
                                system, or maybe just a statue of yourself - as is true across all
                                of Terraria, the choice is yours!
                                <br/>
                                <br/>
                                <br/>
                                <b>Become the architect of your world!</b>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.domReady
                    ?
                        ReactDOM.createPortal(footerAboutVersion, document.getElementById("site_footer"))
                    :
                        null
                }
            </div>
            :
            <div className="main_wrapper">
                <div className="about">
                    <a href="/">
                        <div className="logo"></div> 
                    </a>
                    <Navbar />
                    <div className="about_warning_message_container">
                        Please view this page in landscape mode
                    </div>
                </div>
            </div>
        )
    }
}

export default About