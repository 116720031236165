import React, { useState } from "react"
import axios from "axios"

import "./AdminProduct.scss"

let AdminProduct = (props) => {
    const [editingCategory, setEditingCategory] = useState(false)

    const [category, setCategory] = useState(props.category.id)

    const [editingFeatured, setEditingFeatured] = useState(false)

    const [featuredCategory, setFeaturedCategory] = useState(0)

    let toggleEditing = () => {
        setEditingCategory(!editingCategory)
    }

    let toggleFeatureEditing = () => {
        setEditingFeatured(!editingFeatured)
    }

    let moveCategory = async (e) => {
        if(window.confirm("Are you sure you want to move this product to a different category")) {
            await props.moveCategory(props.product.id, Number(e.target.value))
    
            setCategory(Number(e.target.value))
    
            setEditingCategory(!editingCategory)
    
            await props.getProductsAgain()
        }
    }

    let featureProduct = async (e) => {
        if(window.confirm("Are you sure you want to feature this product?")) {
            if(Number(e.target.value) > 0) {
                await props.featureProduct(props.product.id, Number(e.target.value))
    
                setFeaturedCategory(Number(e.target.value))
    
                setEditingFeatured(!editingFeatured)
    
                setFeaturedCategory(0)
    
                e.target.value = null
            }
        }
    }

    let changeToEdit = () => {
        props.changeToEdit(props.product)
    }

    let deleteProduct = async () => {
        try {
            let deleteBtn = document.getElementById(`admin_product_delete_btn_${props.product.id}`)
            deleteBtn.innerHTML = ""
            deleteBtn.classList.add("spinner")

            if(window.confirm("Are you sure you want to delete this product?")) {
                let productRes = await axios.delete(`/api/admin/delete/product/${props.product.id}`)

                await axios.delete(`/api/admin/delete/product-image/${props.product.img.split("ProductImages/")[1]}`)

                alert(productRes.data)

                deleteBtn.innerHTML = "Delete"
                deleteBtn.classList.remove("spinner")

                await props.getProductsAgain()
            }
            else {
                deleteBtn.innerHTML = "Delete"
                deleteBtn.classList.remove("spinner")
            }
        }
        catch(err) {
            let deleteBtn = document.getElementById(`admin_product_delete_btn_${props.product.id}`)

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            deleteBtn.innerHTML = "Delete"
            deleteBtn.classList.remove("spinner")
        }
    }

    let publishProduct = async () => {
        try {
            let unPublishBtn = document.getElementById(`admin_product_unpublished_btn_${props.product.id}`)
            unPublishBtn.innerHTML = ""
            unPublishBtn.classList.add("spinner")

            let publishRes = await axios.put("/api/admin/update/product/published", { id: props.product.id, published: true })

            alert(publishRes.data)

            unPublishBtn.innerHTML = "unpublished"
            unPublishBtn.classList.remove("spinner")

            props.getProductsAgain()
        }
        catch(err) {
            let unPublishBtn = document.getElementById(`admin_product_unpublished_btn_${props.product.id}`)

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            unPublishBtn.innerHTML = "unpublished"
            unPublishBtn.classList.remove("spinner")
        }
    }

    let unPublishProduct = async () => {
        try {
            let publishBtn = document.getElementById(`admin_product_published_btn_${props.product.id}`)
            publishBtn.innerHTML = ""
            publishBtn.classList.add("spinner")

            let unPublishRes = await axios.put("/api/admin/update/product/published", { id: props.product.id, published: false })

            alert(unPublishRes.data)

            publishBtn.innerHTML = "published"
            publishBtn.classList.remove("spinner")

            props.getProductsAgain()
        }
        catch(err) {
            let publishBtn = document.getElementById(`admin_product_published_btn_${props.product.id}`)

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            publishBtn.innerHTML = "published"
            publishBtn.classList.remove("spinner")
        }
    }

    let changeToLinks = () => {
        props.changeToLinks(props.product)
    }

    let categories = props.categories.map((singleCategory, i) => {
        return (
            <option key={i} value={singleCategory.id}>{singleCategory.name}</option>
        )
    })

    let featuredCategories = props.featuredCategories.map((featuredCategory, i) => {
        return (
            <option key={i} value={featuredCategory.id}>{featuredCategory.name}</option>
        )
    })

    return (
        <div className="admin_product admin_store_list_item">
            <p>{props.product.name}</p>
            <div className="admin_product_btn_container admin_store_list_item_btn_container">
                {
                    editingFeatured
                    ?
                        <div className="admin_product_editing_featured_container">
                            <button className="editing_featured_x_btn" onClick={toggleFeatureEditing}>X</button>
                            <select name="featuredCategories" value={featuredCategory} onChange={featureProduct}>
                                <option value={0}>Select category to copy to</option>
                                {featuredCategories}
                            </select>
                        </div>
                    :
                        <button style={{"width": "75px"}} onClick={toggleFeatureEditing}>Feature</button>

                }
                {
                    editingCategory
                    ?
                        <div className="admin_product_editing_category_container">
                            <button className="editing_category_x_btn" onClick={toggleEditing}>X</button>
                            <select name="categories" value={category} onChange={moveCategory}>
                                {categories}
                            </select>
                        </div>
                    :
                        <button onClick={toggleEditing}>Move</button>
                }
                <button onClick={changeToLinks}>Links</button>
                <button onClick={changeToEdit}>Edit</button>
                <button id={`admin_product_delete_btn_${props.product.id}`} className="admin_product_delete_btn" onClick={deleteProduct}>Delete</button>
                {
                    props.product.published
                    ?
                        <button id={`admin_product_published_btn_${props.product.id}`} className="admin_product_published_btn" onClick={unPublishProduct}>published</button>  
                    :
                        <button id={`admin_product_unpublished_btn_${props.product.id}`} className="admin_product_unpublished_btn" onClick={publishProduct}>unpublished</button>
                }
            </div>
        </div>
    )
}

export default AdminProduct