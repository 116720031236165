import React from "react"
import axios from "axios"

import "./AdminContainer.scss"

let AdminContainer = (props) => {
    let changeToEdit = () => {
        props.changeToEdit(props.container)
    }

    let deleteContainer = async () => {
        try {
            let deleteBtn = document.getElementById(`admin_container_delete_btn_${props.container.id}`)
            deleteBtn.innerHTML = ""
            deleteBtn.classList.add("spinner")

            if(window.confirm("Are you sure you want to delete this container all tiles in this container will be deleted too")) {
                let deleteRes = await axios.delete(`/api/admin/delete/home-container/${props.container.id}`)

                alert(deleteRes.data)

                deleteBtn.innerHTML = "Delete"
                deleteBtn.classList.remove("spinner")

                props.getContainersAgain()
            }
            else {
                deleteBtn.innerHTML = "Delete"
                deleteBtn.classList.remove("spinner")
            }
        }
        catch(err) {
            let deleteBtn = document.getElementById(`admin_container_delete_btn_${props.container.id}`)

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            deleteBtn.innerHTML = "Delete"
            deleteBtn.classList.remove("spinner")
        }
    }

    let chnageToTiles = () => {
        props.changeToTiles(props.container)
    }

    return (
        <div className="admin_container admin_store_list_item">
            <p>{props.container.name}</p>
            <div className="admin_container_btn_container admin_store_list_item_btn_container">
                <button onClick={chnageToTiles}>Tiles</button>
                <button onClick={changeToEdit}>Edit</button>
                <button id={`admin_container_delete_btn_${props.container.id}`} className="admin_spinner_container" onClick={deleteContainer}>Delete</button>
            </div>
        </div>
    )
}

export default AdminContainer