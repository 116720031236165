import React, { Component } from "react"
import axios from "axios"

import AdminViewFeaturedCategories from "./Components/AdminViewFeaturedCategories/AdminViewFeaturedCategories"
import AdminAddFeaturedCategory from "./Components/AdminAddFeaturedCategory/AdminAddFeaturedCategory"
import AdminEditFeaturedCategory from "./Components/AdminEditFeaturedCategory/AdminEditFeaturedCategory"
import AdminFeaturedProducts from "./Components/AdminFeaturedProducts/AdminFeaturedProducts"
import "./AdminFeaturedStoreSectionCategories.scss"

class AdminFeaturedStoreSectionCategories extends Component {
    constructor(props) {
        super(props)

        this.state = {
            categories: [],
            allCategories: [],
            panelType: "view",
            pages: 1,
            pageNumber: 1,
            offset: 0,
            editCategory: {}
        }
    }

    async componentDidMount() {
        let categoriesRes = await axios.get("/api/admin/get/initial/featured-categories")
        let allCategoriesRes = await axios.get("/api/admin/get/all/featured-categories")

        let pages = categoriesRes.data[0] === 0 ? 1 : Math.ceil(categoriesRes.data[0] / 10)

        this.setState({
            categories: categoriesRes.data[1],
            allCategories: allCategoriesRes.data,
            pages: pages,
            pageNumber: 1,
            offset: 0
        })
    }

    getFeaturedCategoriesAgain = async () => {
        let categoriesRes = await axios.get("/api/admin/get/initial/featured-categories")
        let allCategoriesRes = await axios.get("/api/admin/get/all/featured-categories")

        let pages = categoriesRes.data[0] === 0 ? 1 : Math.ceil(categoriesRes.data[0] / 10)

        this.setState({
            categories: categoriesRes.data[1],
            allCategories: allCategoriesRes.data,
            pages: pages,
            pageNumber: 1,
            offset: 0
        })
    }

    changeToView = () => {
        this.setState({
            panelType: "view"
        })
    }

    changeToAdd = () => {
        this.setState({
            panelType: "add"
        })
    }

    changeToEdit = (category) => {
        this.setState({
            panelType: "edit",
            editCategory: category
        })
    }

    changeToProducts = (category) => {
        this.setState({
            panelType: "products",
            editCategory: category
        })
    }

    previousPage = async () => {
        try {
            if(this.state.pageNumber === 1) {
                return
            }
            else {
                let categoryRes = await axios.get(`/api/admin/get/featured-categories/${this.state.offset - 10}`)

                this.setState({
                    categories: categoryRes.data,
                    pageNumber: this.state.pageNumber - 1,
                    offset: this.state.offset - 10
                })
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    nextPage = async () => {
        try {
            if(this.state.pageNumber === this.state.pages) {
                return
            }
            else {
                let categoriesRes = await axios.get(`/api/admin/get/featured-categories/${this.state.offset + 10}`)

                this.setState({
                    categories: categoriesRes.data,
                    pageNumber: this.state.pageNumber + 1,
                    offset: this.state.offset + 10
                })
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    render() {
        return (
            <div className="admin_featured_store_section">
                {
                    this.state.panelType === "view"
                    ?
                        <AdminViewFeaturedCategories 
                        changeToView={this.props.changeToView} 
                        changeToAdd={this.changeToAdd} 
                        changeToEdit={this.changeToEdit} 
                        categories={this.state.categories}
                        getFeaturedCategoriesAgain={this.getFeaturedCategoriesAgain}
                        pages={this.state.pages} 
                        pageNumber={this.state.pageNumber} 
                        previousPage={this.previousPage} 
                        nextPage={this.nextPage} 
                        changeToProducts={this.changeToProducts} />
                    :
                    this.state.panelType === "add"
                    ?
                        <AdminAddFeaturedCategory changeToView={this.changeToView} 
                        getFeaturedCategoriesAgain={this.getFeaturedCategoriesAgain} 
                        categoriesLength={this.state.allCategories.length} />
                    :
                    this.state.panelType === "edit"
                    ?
                        <AdminEditFeaturedCategory changeToView={this.changeToView} 
                        category={this.state.editCategory} 
                        categories={this.state.allCategories} 
                        getFeaturedCategoriesAgain={this.getFeaturedCategoriesAgain} />
                    :
                        <AdminFeaturedProducts changeToView={this.changeToView} category={this.state.editCategory} />
                }
            </div>
        )
    }
}

export default AdminFeaturedStoreSectionCategories