import React, { Component } from "react"
import axios from "axios"

import StoreCategory from "../StoreSection/Components/StoreCategory/StoreCategory"
import StoreProduct from "../StoreSection/Components/StoreProduct/StoreProduct"

class FeaturedSection extends Component {
    constructor(props) {
        super(props)

        this.state = {
            categories: [],
            products: [],
            currentCategory: 0,
            pages: 1,
            pageNumber: 1,
            offset: 0,
            search: "",
            failedSearch: false,
            inputPageNumber: 1
        }
    }

    async componentDidMount() {
        const { REACT_APP_SERVER_HOST } = process.env
        if(this.props.featuredSection.published) {
            let input = document.getElementById("featured_store_section_search_input")

            let navInput = document.getElementById("mini_page_input_featured_store_section")

            let navInputTwo = document.getElementById("mini_page_input_featured_store_section_2")

            let enterKey = (e) => {
                if(e.keyCode === 13) {
                    e.preventDefault()

                    document.getElementById("featured_store_section_search_btn").click()
                }
            }

            input.addEventListener("keyup", enterKey)

            navInput.addEventListener("keyup", this.PageNumberInputEnter)

            navInputTwo.addEventListener("keyup", this.PageNumberInputEnter)

            let header = document.getElementById("featured_store_section_header")
            header.style.backgroundImage = `url("${REACT_APP_SERVER_HOST}${this.props.featuredSection.header}")`

            let categoriesRes = await axios.get("/api/get/store/featured-categories")

            let productsRes = { data: [0, []] }
            let pages = 1
            let currentCategory = 0

            if(categoriesRes.data.length) {
                productsRes = await axios.get(`/api/get/store/initial/featured-products/${categoriesRes.data[0].id}`)

                pages = productsRes.data[0] === 0 ? 1 : Math.ceil(productsRes.data[0] / 6)

                currentCategory = categoriesRes.data[0].id
            }

            this.setState({
                categories: categoriesRes.data,
                products: productsRes.data[1],
                pages: pages,
                currentCategory: currentCategory,
                failedSearch: false,
                pageNumber: 1,
                offset: 0
            })
        }
    }

    async componentDidUpdate(prevProps) {
        const { REACT_APP_SERVER_HOST } = process.env
        if(this.props.featuredSection.published !== prevProps.featuredSection.published) {
            if(this.props.featuredSection.published) {
                let input = document.getElementById("featured_store_section_search_input")

                let navInput = document.getElementById("mini_page_input_featured_store_section")

                let navInputTwo = document.getElementById("mini_page_input_featured_store_section_2")

                let enterKey = (e) => {
                    if(e.keyCode === 13) {
                        e.preventDefault()

                        document.getElementById("featured_store_section_search_btn").click()
                    }
                }

                input.addEventListener("keyup", enterKey)

                navInput.addEventListener("keyup", this.PageNumberInputEnter)

                navInputTwo.addEventListener("keyup", this.PageNumberInputEnter)

                let header = document.getElementById("featured_store_section_header")
                header.style.backgroundImage = `url("${REACT_APP_SERVER_HOST}${this.props.featuredSection.header}")`

                let categoriesRes = await axios.get("/api/get/store/featured-categories")

                let productsRes = { data: [0, []] }
                let pages = 1
                let currentCategory = 0

                if(categoriesRes.data.length) {
                    productsRes = await axios.get(`/api/get/store/initial/featured-products/${categoriesRes.data[0].id}`)

                    pages = productsRes.data[0] === 0 ? 1 : Math.ceil(productsRes.data[0] / 6)

                    currentCategory = categoriesRes.data[0].id
                }

                this.setState({
                    categories: categoriesRes.data,
                    products: productsRes.data[1],
                    pages: pages,
                    currentCategory: currentCategory,
                    failedSearch: false,
                    pageNumber: 1,
                    offset: 0
                })
            }
        }
    }

    changeCategory = async (category) => {
        let productsRes = await axios.get(`/api/get/store/initial/featured-products/${category}`)

        let pages = productsRes.data[0] === 0 ? 1 : Math.ceil(productsRes.data[0] / 6)

        this.setState({
            pages: pages,
            products: productsRes.data[1],
            currentCategory: category,
            failedSearch: false,
            pageNumber: 1,
            offset: 0,
            inputPageNumber: 1
        })
    }

    firstPage = async () => {
        try {
            let productsRes = await axios.get(`/api/get/store/featured-product/${this.state.currentCategory}/${0}`)

            this.setState({
                offset: 0,
                pageNumber: 1,
                products: productsRes.data,
                inputPageNumber: 1
            })

        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }
    
    previousPage = async () => {
        try {
            if(this.state.pageNumber === 1) {
                return
            }
            else {
                let productsRes = await axios.get(`/api/get/store/featured-product/${this.state.currentCategory}/${this.state.offset - 6}`)
    
                this.setState({
                    offset: this.state.offset - 6,
                    pageNumber: this.state.pageNumber - 1,
                    products: productsRes.data,
                    inputPageNumber: this.state.pageNumber - 1
                })
            }

        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    nextPage = async () => {
        try {
            if(this.state.pageNumber === this.state.pages) {
                return
            }
            else {
                let productsRes = await axios.get(`/api/get/store/featured-product/${this.state.currentCategory}/${this.state.offset + 6}`)
    
                this.setState({
                    offset: this.state.offset + 6,
                    pageNumber: this.state.pageNumber + 1,
                    products: productsRes.data,
                    inputPageNumber: this.state.pageNumber + 1
                })
            }

        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    lastPage = async () => {
        try {
            let productsRes = await axios.get(`/api/get/store/featured-product/${this.state.currentCategory}/${(this.state.pages - 1) * 6}`)

            this.setState({
                offset: (this.state.pages - 1) * 6,
                pageNumber: this.state.pages,
                products: productsRes.data,
                inputPageNumber: this.state.pages
            })

        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    updateInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    searchForProduct = async () => {
        try {
            if (this.state.search) {
                let productsRes = await axios.get(`api/get/store/search/featured-product/${this.state.search.replace(/[\W_]+/g, "-")}`)

                this.setState({
                    products: productsRes.data,
                    pages: 1,
                    pageNumber: 1,
                    offset: 0
                })
            }
        }
        catch(err) {
            this.setState({
                products: [],
                failedSearch: true,
                pages: 1,
                pageNumber: 1,
                offset: 0
            })
        }
    }

    PageNumberInputEnter = async (e) => {
        e.preventDefault()

        if(e.keyCode === 13) {
            if(e.target.value < 1) {
                this.firstPage()
            }
            else if(e.target.value > this.state.pages) {
                this.lastPage()
            }
            else {
                let productsRes = await axios.get(`/api/get/store/products/${this.state.currentCategory}/${(e.target.value - 1) * 6}`)
    
                this.setState({
                    offset: (e.target.value - 1) * 6,
                    pageNumber: e.target.value,
                    products: productsRes.data,
                    inputPageNumber: e.target.value
                })
            }
        }
    }

    render() {
        let categories = this.state.categories.map((category, i) => {
            return (
                <StoreCategory key={i} category={category} changeCategory={this.changeCategory} currentCategory={this.state.currentCategory} />
            )
        })
        let products = this.state.products.map((product, i) => {
            return (
                <StoreProduct key={i} product={product} />
            )
        })
        let firstClass = this.state.pageNumber > 1 ? "mini_page mini_first_btn enabled" : "mini_page mini_first_btn"
        let previousClass = this.state.pageNumber > 1 ? "mini_page mini_previous_btn enabled" : "mini_page mini_previous_btn"
        let nextClass = this.state.pageNumber < this.state.pages ? "mini_page mini_next_btn enabled" : "mini_page mini_next_btn"
        let lastClass = this.state.pageNumber < this.state.pages ? "mini_page mini_last_btn enabled" : "mini_page mini_last_btn"
        return (
            this.props.featuredSection.published
            ?
                <div id="featured_store_section" className="featured_section store_section">
                    <div className="panel_dropshadow">
                        <div className="wall">
                            <div className="wall_grass_top"></div>
                            <div id="featured_store_section_header" className="store_section_header">
                                <div className="back_to_top_arrow" onClick={this.props.backToTop}></div>
                            </div>
                            <div className="wall_background">
                                <div className="wall_fade_in"></div>
                                <div className="wall_content">
                                    <div className="section_content">
                                        <div className="store_categories_search_container">
                                            <div className="store_search_container">
                                                <input type="text" 
                                                id="featured_store_section_search_input" 
                                                name="search" 
                                                value={this.state.search} 
                                                onChange={this.updateInput} />
                                                <div id="featured_store_section_search_btn" className="search_box" onClick={this.searchForProduct}>
                                                    <div className="search"></div>
                                                    <div className="cabe"></div>
                                                </div>
                                            </div>
                                            <div className="store_categories_container">
                                                {categories}
                                            </div>
                                        </div>
                                        <div className="store_products_area">
                                            <div className="mini_page_wrapper">
                                                <div className="mini_page_selector">
                                                    <div className={firstClass} onClick={this.firstPage}></div>
                                                    <div className={previousClass} onClick={this.previousPage}></div>
                                                    <div className="mini_page_content">
                                                        <div className="mini_page_input_container">
                                                            <input type="number" 
                                                            id="mini_page_input_featured_store_section"
                                                            className="store_section_page_number_input" 
                                                            name="inputPageNumber" 
                                                            min="1" 
                                                            max={this.state.pages} 
                                                            maxLength="3" 
                                                            value={this.state.inputPageNumber}
                                                            onChange={this.updateInput} />
                                                            <p className="store_section_pages_slash">/</p>
                                                            <p className="store_section_pages_number">{this.state.pages}</p>
                                                        </div>
                                                    </div>
                                                    <div className={nextClass} onClick={this.nextPage}></div>
                                                    <div className={lastClass} onClick={this.lastPage}></div>
                                                </div>
                                            </div>
                                            <div className="store_products_container">
                                                {products}
                                            </div>
                                            <div className="mini_page_wrapper">
                                                <div className="mini_page_selector">
                                                    <div className={firstClass} onClick={this.firstPage}></div>
                                                    <div className={previousClass} onClick={this.previousPage}></div>
                                                    <div className="mini_page_content">
                                                        <div className="mini_page_input_container">
                                                            <input type="number" 
                                                            id="mini_page_input_featured_store_section_2"
                                                            className="store_section_page_number_input" 
                                                            name="inputPageNumber" 
                                                            min="1" 
                                                            max={this.state.pages} 
                                                            maxLength="3" 
                                                            value={this.state.inputPageNumber}
                                                            onChange={this.updateInput} />
                                                            <p className="store_section_pages_slash">/</p>
                                                            <p className="store_section_pages_number">{this.state.pages}</p>
                                                        </div>
                                                    </div>
                                                    <div className={nextClass} onClick={this.nextPage}></div>
                                                    <div className={lastClass} onClick={this.lastPage}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="wall_fade_out"></div>
                                <div className="wall_footer_none"></div>
                            </div>
                        </div>
                    </div>
                </div>
            :
                null
        )
    }
}

export default FeaturedSection