import React from "react"

import AdminProduct from "./Componenets/AdminProduct/AdminProduct"
import "./AdminViewProducts.scss"

let AdminViewProducts = (props) => {
    let products = props.products.map((product, i) => {
        return (
            <AdminProduct key={i} 
            product={product} 
            changeToEdit={props.changeToEdit} 
            getProductsAgain={props.getProductsAgain} 
            changeToLinks={props.changeToLinks} 
            categories={props.categories} 
            category={props.category} 
            moveCategory={props.moveCategory} 
            featuredCategories={props.featuredCategories} 
            featureProduct={props.featureProduct} />
        )
    })
    
    let changeToCategories = () => {
        props.changeToCategories(props.section)
    }

    return (
        <div className="admin_view_products">
            <div className="admin_view_products_page_info_container">
                <h4>
                    Products - page {props.pageNumber}
                </h4>
                <button className="admin_view_products_new_btn" onClick={props.changeToAdd}>New</button>
            </div>
            <div className="admin_store_sort_by_container">
                <p>Sort By:</p>
                <select name="sortBy" value={props.sortBy} onChange={props.changeSort}>
                    <option value="product_order">Product Order</option>
                    <option value="id">Newest</option>
                </select>
            </div>
            <div className="admin_view_products_products_container">
                {products}
            </div>
            <div className="admin_view_products_btn_container">
                <div className="admin_view_products_prev_next_container">
                    <button disabled={props.pageNumber === 1} onClick={props.previousPage}>Previous</button>
                    <button disabled={props.pageNumber === props.pages} onClick={props.nextPage}>Next</button>
                </div>
                <button className="admin_view_products_categories_btn" onClick={changeToCategories}>Categories</button>
            </div>
        </div>
    )
}

export default AdminViewProducts