import React from "react"

import AdminFeaturedCategory from "./Components/AdminFeaturedCategory/AdminFeaturedCategory"
import "./AdminViewFeaturedCategories.scss"

let AdminViewFeaturedCategories = (props) => {
    let categories = props.categories.map((category, i) => {
        return (
            <AdminFeaturedCategory key={i} 
            category={category} 
            getFeaturedCategoriesAgain={props.getFeaturedCategoriesAgain} 
            changeToEdit={props.changeToEdit} 
            changeToProducts={props.changeToProducts} />
        )
    })
    
    return (
        <div className="admin_view_featured_categories">
            <div className="admin_store_page_info_container">
                <h4>
                    Featured Categories - page {props.pageNumber}
                </h4>
                <button onClick={props.changeToAdd}>New</button>
            </div>
            <div className="admin_store_list_items_container">
                {categories}
            </div>
            <div className="admin_store_previous_next_container">
                <button disabled={props.pageNumber === 1} onClick={props.previousPage}>Previous</button>
                <button disabled={props.pages === props.pageNumber} onClick={props.nextPage} >Next</button>
            </div>
            <button className="admin_store_standard_btn" onClick={props.changeToView}>Sections</button>
        </div>
    )
}

export default AdminViewFeaturedCategories