import React from "react"

let Tile = (props) => {
    const { REACT_APP_SERVER_HOST } = process.env

    let tileClick = async () => {
        await props.tileClicked(props.tile.section, props.tile.category)
        props.scrollToSection(props.tile.section)
    }

    return (
        <img src={`${REACT_APP_SERVER_HOST}${props.tile.img}`} alt="" onClick={tileClick}/>
    )
}

export default Tile